import React, {useEffect, useState} from "react";
import style from "./style..module.css";
import FromToPage from "../../../components/FromToPage";
import lang from "../../../lang/lang";
import {useSelector} from "react-redux";
import UserMenu from "../../../components/Header/components/UserMenu";
import BackRoute from "../components/BackRoute";


const OrderHistory = () => {
    const {currentLanguage} = useSelector(({main}) => main);


    return <div className={style.user_page}>
        <div className="wrapper">
            <FromToPage pages={[{
                label: lang[currentLanguage].order_history,
                value: "/order/history",
                active: true
            }]}/>

            <div className={style.user_page_body}>
                <UserMenu containerStyle={true}/>

                <div className={style.null_history}>
                    <div className={style.null_history_title}>
                        <BackRoute/>
                        <span>{lang[currentLanguage].order_history}</span>

                    </div>


                    <div className={style.novu_favs}>
                        <span className={style.novu_favs_text}>{lang[currentLanguage].no_order_history}</span>
                    </div>
                </div>

            </div>

        </div>

    </div>
}
export default OrderHistory
