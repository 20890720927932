import React from "react";

export default function CompareSvg({ active }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: "pointer" }}
    >
      <rect
        x="1"
        y="8.4209"
        width="2.10524"
        height="11.5788"
        rx="1.05262"
        fill={active ? "rgb(227, 19, 53)" : "#282828"}
      ></rect>
      <rect
        x="6.10522"
        width="2.10524"
        height="19.9998"
        rx="1.05262"
        fill={active ? "rgb(227, 19, 53)" : "#282828"}
      ></rect>
      <rect
        x="11.2104"
        y="6.31543"
        width="2.10524"
        height="13.6841"
        rx="1.05262"
        fill={active ? "rgb(227, 19, 53)" : "#282828"}
      ></rect>
      <rect
        x="16.3157"
        y="4.20996"
        width="2.10524"
        height="15.7893"
        rx="1.05262"
        fill={active ? "rgb(227, 19, 53)" : "#282828"}
      ></rect>
    </svg>
  );
}
