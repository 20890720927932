import React from 'react';
import style from "./style.module.css"
import { Link } from "react-router-dom";
const SmallCategories = ({item,currentLanguage}) => {
   return <Link  to={`${
        currentLanguage !== "hy" ? "/" + currentLanguage : ""
      }/category/filter/${item.slug}`}
      className={style.small_categores_item}
    >
            <span >{item['name_' + currentLanguage]}</span>
            <div className={style.mini_ban_img}>
                <img
                     alt="img"
                     src={
                         process.env.REACT_APP_STORAGE_URL +
                         item.category_image.image
                     }
                />
            </div>
            </Link>


};

export default SmallCategories;