import React, {useRef} from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Pagination} from "swiper/modules";
import style from "./style.module.css"
import "swiper/css";
import "swiper/css/pagination";

const SmallSlider = ({slider,currentLanguage}) => {
    const swiperRef = useRef();

    return  <div className={style.brand_small_slider}>
        <Swiper
            loop
            autoplay
            onBeforeInit={(swiper) => {
                swiperRef.current = swiper;
            }}
            modules={[Pagination, Autoplay]}
            pagination={{ clickable: true }}
            grabCursor={true}
            centeredSlides={true}

        >
            {slider.map((item, index) => (
                <SwiperSlide key={index}>
                    <a href={item?.href}>
                        <picture>
                            <img
                                src={
                                    process.env.REACT_APP_STORAGE_URL +
                                    item["image_" + currentLanguage]

                                }
                                className={style.slider_image}
                                type="image/webp"
                                alt="VLV"
                            />
                        </picture>
                    </a>
                </SwiperSlide>
            ))}
        </Swiper>
    </div>
};

export default SmallSlider;