import React, { useEffect, useState } from "react";
import style from "./style..module.css";
import FromToPage from "../../../components/FromToPage";
import lang from "../../../lang/lang";
import { useDispatch, useSelector } from "react-redux";
import UserMenu from "../../../components/Header/components/UserMenu";
import BackRoute from "../components/BackRoute";
import { getFavoritesPageproducts } from "../../../store/MainSlice";
import ProductCard from "../../../components/ProductCard";

const Favorites = () => {
  const { currentLanguage, favorites } = useSelector(({ main }) => main);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFavoritesPageproducts());
  }, []);

  let BOOLEAN = false;

  return (
    <div className={style.user_page}>
      <div className="wrapper">
        <FromToPage
          pages={[
            {
              label: lang[currentLanguage].order_history,
              value: "/favorites",
              active: true,
            },
          ]}
        />

        <div className={style.user_page_body}>
          <UserMenu containerStyle={true} />

          {!BOOLEAN ? (
            <div className={style.null_history}>
              <div className={style.null_history_title}>
                <BackRoute />
                <span>{lang[currentLanguage].Favorites}</span>
              </div>

              <div className={style.novu_favs}>
                <div className="novu_img">
                  <img src="https://vlv.am/public/img/novu.svg" alt="img" />
                </div>
                <span className={style.novu_favs_text}>
                  {lang[currentLanguage].not_favs}
                </span>
              </div>
            </div>
          ) : (
            <div>
              <ProductCard />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Favorites;
