import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../request";

export const postHeaderCategories = createAsyncThunk(
  "categories/fetchCategories",
  async (data, { rejectWithValue }) => {
    try {
      const _token = document.querySelector('meta[name="_token"]')?.content;
      const response = await axiosInstance.post(`catalog/${data}`, {
        login: "VlvShopA",
        password: "oL0rG6vQ6k",
        token: "quHRhfW9R7SbNFUCEBEBmzCRbIa5Z3",
        _token,
      });
      return response.data;
    } catch (error) {
      console.warn(error);
      return rejectWithValue(error.message, "error postHeaderCategories");
    }
  }
);
