import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from "../../request";

export const brandThunk = createAsyncThunk('brand', 
    async (params, thunkAPI) => {
	try {
        const response = await axiosInstance.post(`brand/categories/${params}`, {
            login: process.env.REACT_APP_LOGIN,
            password: process.env.REACT_APP_PASSWORD,
            token: process.env.REACT_APP_TOKEN,
        });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});


export const brandCategoryThunk = createAsyncThunk('brandCategory', 
    async (params, thunkAPI) => {
	try {
        const response = await axiosInstance.post(`sub-categories/${params}`, {
            login: process.env.REACT_APP_LOGIN,
            password: process.env.REACT_APP_PASSWORD,
            token: process.env.REACT_APP_TOKEN,
        });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});