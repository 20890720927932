import React from "react";
import style from "./style.module.css";
import { Link } from "react-router-dom";
import lang from "../../../../lang/lang";
import { useSelector } from "react-redux";

const CartNull = () => {
  const currentLanguage = useSelector(({ main }) => main.currentLanguage);

  return (
    <div className={style.cart_content_null_block}>
      <img
        className="ls-is-cached lazyloaded"
        data-src="https://vlv.am/public/img/empty.svg"
        alt=""
        src="https://vlv.am/public/img/empty.svg"
      />
      <h3>
        {
          lang[currentLanguage]
            .your_cart_is_empty_please_select_item_s_before_checkout
        }
      </h3>
      <Link to={`${currentLanguage !== "hy" ? "/" + currentLanguage : "/"}`}>
        {lang[currentLanguage].Back_to_shopping}
      </Link>
    </div>
  );
};

export default CartNull;
