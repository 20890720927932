import React, {useState} from 'react';
import style from "./style.module.css"
import {useSelector} from "react-redux";
import lang from "../../../../lang/lang";

const CheckedItem = ({checkedItem}) => {
    const {currentLanguage} = useSelector(({main}) => main);

    const [active,setActive] = useState(false)
    const openSubAnswer = () => {
        setActive(!active)
    }
    return <>
        <div className={style.questions_checked_item}>
            <span className={style.checked_item_title}>{lang[currentLanguage].knows_questions}</span>
            {checkedItem?
                     <div className={style.filter_drop_down_block}>
                        <div onClick={openSubAnswer} className={style.filter_drop_down_title}>
                            <p dangerouslySetInnerHTML={{__html: checkedItem?.["question_" + currentLanguage]}} />
                            <span  className={active ? style.rotate_svg_open : ''}>
                                <svg width="14" height="8" viewBox="0 0 14 8" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13 0.5L7 6.5L0.999999 0.500001" stroke="#282828"></path>
                                </svg>
                            </span>
                        </div>
                        <div className={!active ? style.sub_answer_block : style.sub_answer_block_active}>
                            <p dangerouslySetInnerHTML={{__html: checkedItem?.["answer_" + currentLanguage]}} />
                        </div>
                    </div>

                : null}
        </div>


    </>
};

export default CheckedItem;