import style from "./style.module.css";

const Counter = ({ count, decrement, increment }) => {
  return (
    <div className={style.cart_counter_block}>
      <button onClick={decrement} className={style.counterBtn}>
        <svg
          width="14"
          height="2"
          viewBox="0 0 14 2"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0.71875 1.07764H13.4372" stroke="#282828"></path>
        </svg>
      </button>
      <span>{count}</span>
      <button onClick={increment} className={style.counterBtn}>
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.563477 7.07773H13.2819M6.9227 0.718506L6.9227 13.437"
            stroke="#282828"
          ></path>
        </svg>
      </button>
    </div>
  );
};

export default Counter;
