import { createSlice } from '@reduxjs/toolkit';
import { getUserThunk,getUserUpdateThunk } from './thunk';
import { initialState } from './initialState';

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getUserThunk.pending, state => {
			state.loading = true;
		});
		builder.addCase(getUserThunk.fulfilled, (state, action) => {
			state.user = action.payload;
			state.loading = false;
		});
		builder.addCase(getUserThunk.rejected, state => {
			state.loading = false;
		});
		builder.addCase(getUserUpdateThunk.pending, state => {
			state.loading = true;
		});
		builder.addCase(getUserUpdateThunk.fulfilled, (state, action) => {
			state.update = action.payload;
			state.loading = false;
		});
		builder.addCase(getUserUpdateThunk.rejected, state => {
			state.loading = false;
		});
	},
	
});

export const {} = authSlice.actions;

export default authSlice.reducer;