import React, { Fragment } from "react";
import styles from "./style.module.css";
import HomeSvg from "./assets/HomeSvg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function FromToPage({ pages = [] ,langs}) {
  const currentLanguage = useSelector(({ main }) => main.currentLanguage);
  return (
    <div className={styles.container}>
      <Link to={`${currentLanguage !== "hy" ? "/" + currentLanguage : "/" }`} className={styles.homeBtn}>
        <HomeSvg />
      </Link>

      {pages.map((item, index) => {
        return (
          <Fragment key={index}>
            <span className={styles.line} />
            <Link onClick={item?.onClick} to={item?.value}>
              <span
                className={styles.title}
                style={
                  item.active ? { color: "#d10000", fontWeight: "bold" } : {}
                }
              >
                {item.label}
              </span>
            </Link>
          </Fragment>
        );
      })}
    </div>
  );
}
