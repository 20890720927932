import {createSlice} from "@reduxjs/toolkit";
import {getContactInfo} from "./contactPageFetch";

const initialState = {
    isLoading:false,
    error:'',
    contactPage: [],
}
const ContactPageSlice = createSlice({
    name: 'contact',
    initialState,
    reducers:{},
    extraReducers:{
        [getContactInfo.pending.type]: (state) => {
            state.isLoading = true
        },
        [getContactInfo.fulfilled.type]:(state, action)=>{
            state.isLoading = false
            state.error = ''
            state.contactPage = action.payload
        },
        [getContactInfo.rejected.type]:(state, action)=>{
            state.isLoading = false
            state.error = action.payload
        }
    }
})

export const {} = ContactPageSlice.actions

export default ContactPageSlice.reducer
