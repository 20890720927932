import {getJobsPageInfo} from "./JobPageFetch";
import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    isLoadingJobPage:false,
    errorJobPage:'',
    jobPage: [],
}
const JobPageSlice = createSlice({
    name: 'jobPage',
    initialState,
    reducers:{},
    extraReducers:{
        [getJobsPageInfo.pending.type]: (state) => {
            state.isLoadingJobPage = true
        },
        [getJobsPageInfo.fulfilled.type]:(state, action)=>{
            state.isLoadingJobPage = false
            state.errorJobPage = ''
            state.jobPage = action.payload
        },
        [getJobsPageInfo.rejected.type]:(state, action)=>{
            state.isLoadingJobPage = false
            state.errorJobPage = action.payload
        }
    }
})
export const {} = JobPageSlice.actions

export default JobPageSlice.reducer
