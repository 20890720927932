import React, { useRef, useState } from "react";
import style from "./style.module.css";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import SliderNextArrowSvg from "../../../../assets/SVG/SliderNextArrowSvg";

export default function BrandBanerSlider({ bannerSliders }) {
  const [showBtns, setShowBtns] = useState(false);
  const currentLanguage = useSelector(({ main }) => main.currentLanguage);

  const [slidePosition, setSlidePosition] = useState({
    isBeginning: true,
    isEnd: false,
  });

  const swiperRef = useRef();
  return (
    <div
      className={style.container}
      onMouseMove={() => window.innerWidth > 768 && setShowBtns(true)}
      onMouseOut={() => window.innerWidth > 768 && setShowBtns(false)}
    >
      <>
        {window.innerWidth > 768 && (
          <div
            className="wrapper"
            style={{
              position: "absolute",
              height: "468px",
              left: 0,
              right: 0,
            }}
          >
            <div
              className={`${style.slideBtn} ${style.slidePrevArrow}`}
              style={{
                display:
                  slidePosition.isBeginning || !showBtns ? "none" : "flex",
              }}
              onClick={() => swiperRef.current?.slidePrev()}
            >
              <SliderNextArrowSvg />
            </div>
            <div
              className={`${style.slideBtn} ${style.slideNextArrow}`}
              style={{
                display: slidePosition.isEnd || !showBtns ? "none" : "flex",
              }}
              onClick={() => swiperRef.current?.slideNext()}
            >
              <SliderNextArrowSvg />
            </div>
          </div>
        )}

        <Swiper
          modules={[Pagination, Autoplay]}
          loop={false}
          autoplay
          slidesPerView={1}
          spaceBetween={20}
          onSlideChange={(e) => {
            setSlidePosition({
              isEnd: e.isEnd,
              isBeginning: e.isBeginning,
            });
          }}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          pagination={{ clickable: true }}
          centeredSlides={true}
        >
          {bannerSliders &&
            bannerSliders.map((item, index) => (
              <SwiperSlide key={index}>
                <a href={item?.href}>
                  <img
                    className={style.slideImg}
                    src={
                      process.env.REACT_APP_STORAGE_URL +
                      item["image_" + currentLanguage]
                    }
                    alt="VLV banner"
                  />
                </a>
              </SwiperSlide>
            ))}
        </Swiper>
      </>
    </div>
  );
}
