import React from 'react';
import style from "./style.module.css"
const ModalJobs = ({onClose,children}) => {
    return<div className={style.modal_jobs_block}>
            <div onClick={()=>onClose(false)} className={style.modal_jobs_bg}></div>
        <div className={style.modal_jobs_content}>
            {children}

        </div>
        </div>

};

export default ModalJobs;