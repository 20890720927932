export default {
  buyNow: {
    title: {
      hy: "Գնել հիմա",
      en: "Buy Now",
      ru: "КУПИТЬ В ОДИН КЛИК",
    },
    paymentTitle: {
      hy: "ՎՃԱՐՄԱՆ ՏԱՐԲԵՐԱԿ",
      en: "PAYMENT TYPE",
      ru: "ТИП ОПЛАТЫ",
    },
    paymentTypes: [
      {
        id: 19,
        imgs: ["img/visa.svg", "img/master.svg", "img/arca.svg"],
        title: {
          hy: "Բանկային քարտով",
          en: "By bank card",
          ru: "С помощью банковской карты",
        },
      },
      {
        id: 17,
        imgs: ["img/express.png"],
        title: {
          hy: "AMERICAN EXPRESS",
          ru: "AMERICAN EXPRESS",
          en: "AMERICAN EXPRESS",
        },
      },
      {
        id: 1,
        imgs: ["img/cash2.svg"],
        title: {
          hy: "Վճարել տեղում կանխիկ կամ բանկային քարտով",
          en: "PAY ON THE SPOT BY CASH OR Bank Card",
          ru: "ОПЛАТА НА МЕСТЕ НАЛИЧНЫМИ ИЛИ банковской картой",
        },
      },
      {
        id: 15,
        imgs: ["img/idram.svg"],
        title: {
          hy: "Idram",
          ru: "Idram",
          en: "Idram",
        },
      },
      {
        id: 16,
        imgs: ["img/telcell.svg"],
        title: {
          hy: "Telcell",
          ru: "Telcell",
          en: "Telcell",
        },
      },
    ],
  },
  credit: {
    title: {
      hy: "Գնել ապառիկ",
      en: "Buy on credit",
      ru: "КУПИТЬ В КРЕДИТ",
    },
    paymentTitle: {
      hy: "ԱՊԱՌԻԿ ՎՃԱՐՈՒՄ",
      en: "INSTALLMENT PAYMENT",
      ru: "ОПЛАТА В РАССРОЧКУ",
    },
    paymentTypes: [
      "img/vtb.png",
      // "img/unii.png",
      "img/ineco.png",
      "img/acba.png",
      // "img/evoca.png",
    ],
  },
  lang: {
    address: {
      hy: "Փողոց, տուն, բնակարան *",
      en: "Street, house, apartment *",
      ru: "Улица, дом, квартира *",
    },
    flat: {
      hy: "Լրացրեք բնակարան/ տուն / հարկ",
      en: "Fill in the apartment/house/floor",
      ru: "Заполните квартиру/дом/этаж",
    },
    delivary: {
      hy: "Առաքում",
      en: "Shipping",
      ru: "Доставка",
    },
    total: {
      hy: "Ընդհանուր",
      en: "Total",
      ru: "Итого",
    },
    textarea: {
      hy: "Լրացուցիչ մեկնաբանությունները գրեք այստեղ",
      en: "Fill in the address of delivery or move the red dot in the map",
      ru: "Если у вас есть дополнительные комментарий, укажите здесь",
    },
    buy: {
      hy: "Գնել",
      en: "Buy",
      ru: "Купить",
    },
  },
};
