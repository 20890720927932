import React from 'react';
import style from "./style.module.css"

const MyModal = ({onClose, children,removePadding,closeSvg}) => {
    return <div className={style.modal_block }>

        <div onClick={() => onClose(false)} className={style.modal_bg}></div>
        <div className={`${style.modal_content } ${removePadding && style.modal_remove_padding}`}>
            {closeSvg  &&
                <div onClick={()=>onClose(false)} className={style.modal_close}>
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L16 16M16 1L1 16" stroke="#282828"></path>
                    </svg>
                </div>
            }
            {children}

        </div>
    </div>

};

export default MyModal;