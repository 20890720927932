 import style from "./style.module.css"
import {useEffect, useState} from "react";

const TimerPrice = ({ targetDate }) => {

    const calculateTimeRemaining = () => {

        const now = new Date().getTime();
        const targetTime = new Date(targetDate.split('-').reverse().join('-')).getTime();

        const timeRemaining = targetTime - now;

        const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

        return { days, hours, minutes, seconds };
    };

    const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

    useEffect(() => {
        const timerInterval = setInterval(() => {
            setTimeRemaining(calculateTimeRemaining());
        }, 1000);

        return () => clearInterval(timerInterval);
    }, []);


        return <div className={style.countdown_block}>
        <div className={style.count_name}>
            <div className={style.countdown_win}>{timeRemaining.days}</div>
            <span className={style.nameof_count}>Օր</span>
        </div>
        <span className={style.period}>:</span>
        <div className={style.count_name}>
            <div className={style.countdown_win}> {timeRemaining.hours}</div>
            <span className={style.nameof_count}>Ժամ</span></div>
        <span className={style.period}>:</span>


        <div className={style.count_name}>
            <div className={style.countdown_win}>{timeRemaining.minutes}</div>
            <span className={style.nameof_count}>Րոպե</span></div>
           <span className={style.period}>:</span>

        <div className={style.count_name}>
            <div className={style.countdown_win}>{timeRemaining.seconds}</div>
            <span className={style.nameof_count}>Վայրկյան</span></div>
    </div>
};

export default TimerPrice;