import React, {
  memo,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import styles from "./style.module.css";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { useDispatch } from "react-redux";
import {
  filterProducts,
  setMaxPrice,
  setMinPrice,
} from "../../../../../../store/SearchPageSlice";

export default memo(function FilterItemPrice({
  title,
  min,
  max,
  defaultPrices,
}) {
  const [minPriceState, setMinPriceState] = useState(defaultPrices?.min);
  const [maxPriceState, setMaxPriceState] = useState(defaultPrices?.max);

  const [opened, setOpened] = useState(true);
  const dispatch = useDispatch();

  let timeOutIdRef = useRef();
  const changePriceFunc = async () => {
    if (timeOutIdRef.current) clearTimeout(timeOutIdRef.current);
    timeOutIdRef.current = setTimeout(() => {
      if (
        maxPriceState <= defaultPrices?.max &&
        maxPriceState >= defaultPrices?.min
      ) {
        dispatch(setMaxPrice(maxPriceState));
      }
      if (
        minPriceState <= defaultPrices?.max &&
        minPriceState >= defaultPrices?.min
      ) {
        dispatch(setMinPrice(minPriceState));
      }

      dispatch(filterProducts());
    }, 500);
  };
  useEffect(() => {
    if (minPriceState && maxPriceState) changePriceFunc();
  }, [minPriceState, maxPriceState]);

  useEffect(() => {
    if (min) setMinPriceState(min);
    if (max) setMaxPriceState(max);
  }, [min, max]);

  return (
    <div className={styles.container}>
      <div className={styles.titleBlock} onClick={() => setOpened(!opened)}>
        <span className={styles.title}>{title}</span>
        <span
          className={styles.arrow}
          style={{
            transform: opened ? "rotate(0deg)" : "rotate(180deg)",
          }}
        >
          <svg
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.0293 5L4.99991 0.97083L9.02908 4.94145"
              stroke="#282828"
            ></path>
          </svg>
        </span>
      </div>
      {opened && (
        <div className={styles.dropDownBlock}>
          <div className={styles.priceInputs}>
            <input
              className={styles.priceInput}
              // min={defaultPrices?.min}
              // max={defaultPrices?.max}
              // placeholder={defaultPrices?.min}
              min={min}
              max={max}
              placeholder={min}
              type="number"
              value={minPriceState}
              onChange={(e) => {
                setMinPriceState(e.target.value);
              }}
            />
            <span className={styles.inputsLine} />
            <input
              className={styles.priceInput}
              // min={defaultPrices?.min}
              // max={defaultPrices?.max}
              // placeholder={defaultPrices?.min}
              min={min}
              max={max}
              placeholder={min}
              type="number"
              value={maxPriceState}
              onChange={(e) => {
                setMaxPriceState(e.target.value);
              }}
            />
          </div>

          <Slider
            style={{
              width: "92%",
              margin: "0 auto 5px",
            }}
            trackStyle={{
              backgroundColor: "#d10000",
            }}
            onChange={(e) => {
              setMinPriceState(e[0]);
              setMaxPriceState(e[1]);
            }}
            defaultValue={[min, max]}
            value={[minPriceState, maxPriceState]}
            range
            min={min}
            max={max}
          />
        </div>
      )}
    </div>
  );
});
