import React, { useEffect } from "react";
import styles from "./style.module.css";
import { useSelector } from "react-redux";
import CircleLoader from "../CircleLoader";

const Loader = ({ isLoading,children }) => {
 

  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isLoading]); 
  
  return (
    <div>
      {isLoading && (
        <div className={styles.background}>
          <div>
            <img
              style={{
                width: window.innerWidth > 400 ? "200px" : "150px",
                height: "auto",
                objectFit: "contain",
              }}
              src="https://vlv.am/public/logos/header_hy.svg"
              alt=""
            />
            <CircleLoader containerStyle={{ height: "100px" }} />
            {/* <div className={styles.dotsContainer}>
              <span className={`${styles.dot} ${styles.dot1}`}></span>
              <span className={`${styles.dot} ${styles.dot2}`}></span>
              <span className={`${styles.dot} ${styles.dot3}`}></span>
              <span className={`${styles.dot} ${styles.dot4}`}></span>
            </div> */}
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

export default Loader;
