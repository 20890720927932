import {useState} from "react";


const useAddressValidate = () => {
    const [personInfo, setPersonInfo] = useState({
        firstName: '',
        lastName:'',
        email:'',
        number:'',
        province:'',

    })
    const [errorText, setErrorText] = useState({
        firstName: '',
        lastName:'',
        email:'',
        number:'',
        province:'',
    })

    const validateMail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    const validation = () => {
        let validate = true
        let errorString = {
            firstName: '',
            lastName:'',
            email:'',
            number:'',
            province:'',
        }
        if (!personInfo.firstName.trim().length) {
            validate = false
            errorString.firstName = "Fill in the required FirstName"
        }
        if (!personInfo.email.trim().length) {
            validate = false
            errorString.email = "Fill in the required EmailAddress"

        } else if (!validateMail.test(personInfo.email)) {
            validate = false
            errorString.email = "Fill in the email"
        }
        if (!personInfo.number.trim().length) {
            validate = false
            errorString.number = "Fill in the required Password"

        }

        if (!personInfo.province.trim().length) {
            validate = false
            errorString.province = "Fill in the required ConfirmPassword"

        }

        setErrorText(errorString)
        return validate
    }


    return {validation, personInfo, setPersonInfo, errorText, setErrorText}
}

export default useAddressValidate