import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
const CatalogItem = ({ item }) => {
  const { currentLanguage } = useSelector(({ main }) => main);

  return (
    <>
      <Link
        to={`${
          currentLanguage !== "hy" ? "/" + currentLanguage : ""
        }/category/filter/${item.slug}`}
        className={style.catalog_item}
      >
        <div className={style.catalog_item_img}>
          <img src={process.env.REACT_APP_STORAGE_URL + item.icon} alt="img" />
        </div>
        <div className={style.catalog_item_name}>
          {item["name_" + currentLanguage]}
        </div>
      </Link>
    </>
  );
};

export default CatalogItem;
