import React from "react";
import styles from "./styles.module.css";

const Switch = ({ isOn, handleToggle, onColor, disabled=false }) => {
  return (
    <>
      <input
        checked={isOn}
        onChange={handleToggle}
        type="checkbox"
        id={"react-switch-new"}
        className={styles.reactswitchcheckbox}
        disabled={disabled}
      />
      <label
        style={{ background: isOn ? onColor : "" }}
        className={styles.reactswitchlabel}
        htmlFor={"react-switch-new"}
      >
        <span className={styles.reactswitchbutton} />
      </label>
    </>
  );
};

export default Switch;
