import React from "react";
import style from "./style..module.css"
import lang from "../../../../lang/lang";

const DeleteModalInfo = ({onClose,currentLanguage}) => {
    return <div className={style.delete_modal_info}>
        <div className={style.small2_warning}>
            <div className={style.small2_icon_content}>!</div>
        </div>
        <span className={style.small2_title}>{lang[currentLanguage].are_you_sure}Are you sure?</span>
        <span className={style.small2_sub_title}>You won't be able to revert this!</span>
        <div className={style.delete_modal_info_btn}>
            <button className={style.tools_blue_btn}>Yes, delete it!</button>
            <button onClick={()=>onClose(false)} className={style.tools_red_btn}>Cancel</button>

        </div>
    </div>

};

export default DeleteModalInfo;
