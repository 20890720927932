import { createSlice } from '@reduxjs/toolkit';
import { productThunk } from './thunk';
import { initialState } from './initialState';

export const productSlice = createSlice({
	name: 'product',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(productThunk.pending, state => {
			state.loading = true;
		});
		builder.addCase(productThunk.fulfilled, (state, action) => {
			state.colors = action.payload.data_associated_products;
			state.loading = false;
		});
		builder.addCase(productThunk.rejected, state => {
			state.loading = false;
		});
	},
});

export const {} = productSlice.actions;

export default productSlice.reducer;