import React from "react";
import styles from "./style.module.css";
import MessageSvg from "./assets/MessageSvg";
import langData from "../../lang/data";
import { useDispatch, useSelector } from "react-redux";
import { setShowSubscribeModal } from "../../store/MainSlice";

export default function SubscribeModal() {
  const { currentLanguage, showSubscribeModal } = useSelector(
    ({ main }) => main
  );
  const dispatch = useDispatch();
  if (!showSubscribeModal) return null;
  return (
    <div className={styles.background}>
      <div className={styles.main}>
        <div
          className={styles.close}
          onClick={() => dispatch(setShowSubscribeModal(false))}
        >
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 1L16 16M16 1L1 16" stroke="#282828"></path>
          </svg>
        </div>
        <div className={styles.img}>
          <MessageSvg />
        </div>
        <div className={styles.title}>
          <span>{langData.footer.subscribe_modal.title[currentLanguage]}</span>
        </div>
        <div
          className={styles.btn}
          onClick={() => dispatch(setShowSubscribeModal(false))}
        >
          <span>{langData.footer.subscribe_modal.btn[currentLanguage]}</span>
        </div>
      </div>
    </div>
  );
}
