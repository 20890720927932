import React, { forwardRef, useEffect, useState } from "react";
import style from "./style.module.css";
import lang from "../../../../../../lang/lang";
import { useSelector } from "react-redux";
import BackSvg from "../../../../../../assets/SVG/BackSvg";

const Payment = forwardRef((props, ref) => {
  const [showCredit, setShowCredit] = useState(null);
  const { currentLanguage } = useSelector(({ main }) => main);

  const paymentItems = [
    {
      icon: "https://vlv.am/public/img/visa.svg",
      icon1: "https://vlv.am/public/img/master.svg",
      icon2: "https://vlv.am/public/img/arca.svg",
      name: lang[currentLanguage]["By bank card"],
      id: 19,
    },
    {
      icon: "https://vlv.am/public//img/express.svg",
      // icon1: "https://vlv.am/public//img/mir.svg",
      name: "AMERICAN EXPRESS",
      id: 17,
    },
    {
      icon: "https://vlv.am/public//img/receipt.svg",
      name: lang[currentLanguage].pay_by_receipt,
      id: 33333,
    },
    {
      icon: "https://vlv.am/public//img/aparik.svg",
      name: lang[currentLanguage]["Online credit"],
      id: 4,
    },
    {
      icon: "https://vlv.am/public//img/cash2.svg",
      name: lang[currentLanguage]["PAY ON THE SPOT BY CASH OR Bank Card"],
      id: 1,
    },
    {
      icon: "https://vlv.am/public//img/idram.svg",
      name: "Idram",
      id: 15,
    },
    {
      icon: "https://vlv.am/public//img/telcell.svg",
      name: "Telcell",
      id: 16,
    },
  ];
  const creditItems = [
    {
      icon: "https://vlv.am/public/img/vtb.png",
      name: "vtb",
    },
    // {
    //   icon: "https://vlv.am/public/img/unii.png",
    //   name: "unii",
    // },
    {
      icon: "https://vlv.am/public/img/ineco.png",
      name: "ineco",
    },
    {
      icon: "https://vlv.am/public/img/acba.png",
      name: "acba",
    },
    // {
    //   icon: "https://vlv.am/public/img/evoca.png",
    //   name: "evoca",
    // },
  ];
  const handleClick = (id) => {
    props.setActivePaymentId(id);
  };

  useEffect(() => {
    if (props.activePaymentId === "bankTranfer") {
      setShowCredit("bankTranfer");
    }
  }, [props.activePaymentId]);

  return (
    <div ref={ref} className={style.cart_payment_block}>
      {showCredit == "bankTranfer" && (
        <div
          className={`${style.infoBlock} scrollBar`}
          dangerouslySetInnerHTML={{
            __html:
              lang[currentLanguage]
                ?.PAY_ON_THE_SPOT_BY_CASH_OR_Bank_Card_Policy,
          }}
        />
      )}

      {showCredit ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            columnGap: "20px",
          }}
        >
          <div
            onClick={() => setShowCredit(null)}
            className={style.paymentBackBtn}
          >
            <BackSvg />
          </div>
          <span className={style.payment_title}>
            {
              lang[currentLanguage][
                showCredit === "credit" ? "Payment type" : "receipt_info"
              ]
            }
          </span>
        </div>
      ) : (
        <span className={style.payment_title}>
          {lang[currentLanguage]["Payment type"]}
        </span>
      )}

      <div className={style.payment_list}>
        {showCredit == "credit"
          ? creditItems.map((item, index) => {
              if (props.totalPrice < 26000 && item.name === "acba") {
                return null;
              }
              if (
                props.totalPrice < 30000 &&
                (item.name === "vtb" ||
                  item.name === "ineco")
              ) {
                return null;
              }
              
              return (
                <div
                  key={index}
                  onClick={() => {
                    handleClick(item.name);
                  }}
                  className={
                    style.payment_item +
                    (props.activePaymentId === item.id ||
                    props.activePaymentId === item.name
                      ? " " + style.payment_item_active
                      : "")
                  }
                >
                  <div className={style.cart_img_types}>
                    <div className={style.credit_imgsvg}>
                      <img alt="" src={item.icon} />
                    </div>
                  </div>
                </div>
              );
            })
          : showCredit !== "bankTranfer"
          ? paymentItems.map((item, index) => {
              if (props.totalPrice < 26000 && item.id == 4) return null;
              return (
                <div
                  key={index}
                  onClick={() => {
                    if (item.id == 33333) {
                      setShowCredit("bankTranfer");
                      props.setActivePaymentId("bankTranfer");
                    } else if (item.id == 4) {
                      setShowCredit("credit");
                      props.setActivePaymentId("credit");
                    } else {
                      setShowCredit(null);
                      handleClick(item.id);
                    }
                  }}
                  className={
                    style.payment_item +
                    (props.activePaymentId === item.id
                      ? " " + style.payment_item_active
                      : "")
                  }
                >
                  <div className={style.cart_img_types}>
                    <div className={style.cart_imgsvg}>
                      <img alt="" src={item.icon} />
                    </div>

                    {item.icon1 ? (
                      <div className={style.cart_imgsvg}>
                        <img alt="" src={item.icon1} />
                      </div>
                    ) : null}

                    {item.icon2 ? (
                      <div className={style.cart_imgsvg}>
                        <img alt="" src={item.icon2} />
                      </div>
                    ) : null}
                  </div>
                  <div className={style.nameofcart_type}>{item.name}</div>
                </div>
              );
            })
          : null}
      </div>
      {showCredit === "bankTranfer" && (
        <div>
          <input
            type="text"
            value={props.hvhh}
            onChange={(e) => props.setHvhh(e.target.value)}
            className={`${style.bankInput} ${
              props.hvhhError && style.inputError
            }`}
            maxLength={8}
            placeholder="ՀՎՀՀ *"
          />
          <input
            type="text"
            value={props.corporateName}
            onChange={(e) => props.setCorporateName(e.target.value)}
            className={`${style.bankInput} ${
              props.corporateNameError && style.inputError
            }`}
            placeholder="Ընկերության անվանումը *"
          />
          <input
            type="text"
            value={props.legalAddress}
            onChange={(e) => props.setLegalAddress(e.target.value)}
            className={`${style.bankInput} ${
              props.legalAddressError && style.inputError
            }`}
            placeholder="Իրավաբանական հասցե *"
          />
        </div>
      )}
    </div>
  );
});

export default Payment;
