import React, { useEffect, useState } from "react";
import style from "./style..module.css";
import FromToPage from "../../../components/FromToPage";
import lang from "../../../lang/lang";
import { useDispatch, useSelector } from "react-redux";
import UserMenu from "../../../components/Header/components/UserMenu";
import FavoritesProducts from "../components/FavoritesProducts";
import { Link } from "react-router-dom";
import { getProfilePageData } from "../../../store/MainSlice";

const PersonalPage = () => {
  
  const { currentLanguage, favorites, profilePageInfo } = useSelector(
    ({ main }) => main
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProfilePageData());
  }, []);

  return (
    <div className={style.user_page}>
      <div className="wrapper">
        <FromToPage
          pages={[
            {
              label: lang[currentLanguage].Personal_information,
              value: "/personal/Information",
              active: true,
            },
          ]}
        />

        <div className={style.user_left_block}>
          <div className={style.user_left_block_body}>
            <div className={style.bonus_card_block}>
              <img src="https://vlv.am/public/img/vlv_card.jpg" alt="img" />
            </div>
            <div className={style.user_three_general_categories}>
              <div className={style.user_favicon}>
                <div className={style.title_fgc}>
                  <span className={style.fgc_title}>
                    {lang[currentLanguage].Favorites}
                  </span>
                  <span className={style.fgc_num}>{favorites?.length}</span>
                </div>

                {favorites?.length ? <FavoritesProducts /> : null}
              </div>

              <Link to={"/profile/my-addresses"} className={style.user_favicon}>
                <div className={style.person_info_}>
                  <div className={style.person_img}>
                    <div className={style.user_Image}>
                      <img
                        src="https://vlv.am/public/frontend/default/img/avatar.jpg"
                        alt="img"
                      />
                    </div>
                  </div>
                  <div className={style.inf_person_about}>
                    <div className={style.title_fgc}>
                      <span className={style.name_person_inf}>
                        {lang[currentLanguage].my_addresses}
                      </span>
                      <div className={style.edit_person_inf}>
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.2795 0.57874C11.0512 -0.192913 12.2913 -0.192913 13.063 0.57874L13.4213 0.937008C14.1929 1.70866 14.1929 2.94882 13.4213 3.72047L7.96457 9.17717C7.74409 9.39764 7.44094 9.59055 7.11024 9.67323L4.35433 10.4724C4.13386 10.5276 3.88583 10.4724 3.72047 10.2795C3.52756 10.1142 3.47244 9.86614 3.52756 9.64567L4.32677 6.88976C4.40945 6.55906 4.60236 6.25591 4.82283 6.03543L10.2795 0.57874ZM12.126 1.51575C11.878 1.26772 11.4646 1.26772 11.2165 1.51575L10.3898 2.31496L11.685 3.61024L12.4843 2.78346C12.7323 2.53543 12.7323 2.12205 12.4843 1.87402L12.126 1.51575ZM5.59449 7.24803L5.12598 8.87402L6.75197 8.40551C6.8622 8.37795 6.94488 8.32283 7.02756 8.24016L10.748 4.51968L9.48032 3.25197L5.75984 6.97244C5.67717 7.05512 5.62205 7.1378 5.59449 7.24803ZM5.51181 1.65354C5.87008 1.65354 6.17323 1.95669 6.17323 2.31496C6.17323 2.70079 5.87008 2.97638 5.51181 2.97638H2.4252C1.79134 2.97638 1.32283 3.47244 1.32283 4.07874V11.5748C1.32283 12.2087 1.79134 12.6772 2.4252 12.6772H9.92126C10.5276 12.6772 11.0236 12.2087 11.0236 11.5748V8.48819C11.0236 8.12992 11.2992 7.82677 11.685 7.82677C12.0433 7.82677 12.3465 8.12992 12.3465 8.48819V11.5748C12.3465 12.9252 11.2441 14 9.92126 14H2.4252C1.0748 14 0 12.9252 0 11.5748V4.07874C0 2.75591 1.0748 1.65354 2.4252 1.65354H5.51181Z"
                            fill="#282828"
                          ></path>
                        </svg>
                      </div>
                    </div>
                    <span className={style.my_loc}>
                      {profilePageInfo?.addresses?.[0]?.address}
                    </span>
                  </div>
                </div>
              </Link>

              <Link
                to={"/profile/order-history"}
                className={style.user_favicon}
              >
                <div className={style.title_fgc}>
                  <span className={style.fgc_title}>
                    {lang[currentLanguage].Orders}
                  </span>
                  <span className={style.fgc_num}>
                    {profilePageInfo?.recent_order_products?.length}
                  </span>
                </div>
              </Link>
            </div>
          </div>

          <UserMenu containerStyle={true} />
        </div>
      </div>
      <div className={style.red_back}></div>
    </div>
  );
};
export default PersonalPage;
