
import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from "../request";

export const getFrequentQuestions = createAsyncThunk('frequent/Questions',
    async (data, {rejectWithValue}) => {
        try {
            const response = await axiosInstance.post(`faq`, {
                login: process.env.REACT_APP_LOGIN,
                password: process.env.REACT_APP_PASSWORD,
                token: process.env.REACT_APP_TOKEN,
            });

            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

