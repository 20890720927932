import React from 'react';
import style from "../ModalJobs/style.module.css";

const ModalSuccess = ({onClose}) => {
    return       <div className={style.modal_jobs_content_title}>
        <div onClick={()=>onClose(false)} className={style.modal_jobs_close}>
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L16 16M16 1L1 16" stroke="#282828"></path>
            </svg>
        </div>

        <div className={style.modal_jobs_content_img}>
            <img src="https://vlv.am/public/img/Group5655.png" alt="img"/>
        </div>
        <span>Ձեր ինքնակենսագրականը հաջողությամբ ուղարկվել է</span>
        <div  onClick={()=>onClose(false)} className={style.modal_jobs_content_button}>Ներկայացնել</div>
    </div>
};


export default ModalSuccess;