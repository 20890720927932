import React from "react";
import styles from "./style.module.css";
import { SwiperSlide, Swiper } from "swiper/react";
import { Scrollbar } from "swiper/modules";
import "swiper/css";
import "swiper/css/scrollbar";
import { useDispatch, useSelector } from "react-redux";
import LoadContent from "../../../../components/LoadContent";
import { getWorkInformations } from "../../../../store/HomeSlice";

export default function WorkInformations() {
  const { workInformations } = useSelector(({ home }) => home);
  const { currentLanguage } = useSelector(({ main }) => main);
  const dispatch = useDispatch();
  if (!workInformations.length) {
    dispatch(getWorkInformations());
  }

  return (
    <div className="wrapper">
      <div className={styles.container}>
        {window.innerWidth < 600 ? (
          <div className="scrollRow scrollBar">
            {!workInformations?.length
              ? Array.from({ length: 7 }).map((_, index) => (
                  <div style={{ width: "auto", display: "flex" }} key={index}>
                    <LoadContent containerClassName={styles.loadContent} />
                  </div>
                ))
              : workInformations.map((item, index) => (
                  <div
                    style={{ width: "auto", display: "flex" }}
                    className={styles.slideItem}
                    key={index}
                  >
                    <img
                      className={styles.slideItemImg}
                      src={process.env.REACT_APP_STORAGE_URL + item.icon}
                      alt={item.title_en}
                    />

                    <span className={styles.slideItemTitle}>
                      {item["title_" + currentLanguage]}
                    </span>
                  </div>
                ))}
          </div>
        ) : (
          <Swiper
            modules={[Scrollbar]}
            spaceBetween={window.innerWidth > 768 ? 24 : 15}
            slidesPerView={"auto"}
            scrollbar={{
              draggable: true,
              dragSize: "auto",
              hide: window.innerWidth < 768,
            }}
            style={{ paddingBottom: "15px" }}
            freeMode
          >
            {!workInformations?.length
              ? Array.from({ length: 7 }).map((_, index) => (
                  <SwiperSlide
                    style={{ width: "auto", display: "flex" }}
                    key={index}
                  >
                    <LoadContent containerClassName={styles.loadContent} />
                  </SwiperSlide>
                ))
              : workInformations.map((item, index) => (
                  <SwiperSlide
                    style={{ width: "auto", display: "flex" }}
                    className={styles.slideItem}
                    key={index}
                  >
                    <img
                      className={styles.slideItemImg}
                      src={process.env.REACT_APP_STORAGE_URL + item.icon}
                      alt={item.title_en}
                    />

                    <span className={styles.slideItemTitle}>
                      {item["title_" + currentLanguage]}
                    </span>
                  </SwiperSlide>
                ))}
          </Swiper>
        )}
      </div>
    </div>
  );
}
