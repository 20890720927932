import React from "react";
import styles from "./style.module.css";

export default function PaymentType({
  imgs = [],
  title = "",
  active = false,
  setActive = () => {},
}) {
  return (
    <div
      onClick={setActive}
      className={`${styles.container} ${active && styles.activeContainer}`}
    >
      <span
        className={`${styles.checkBtn} ${active && styles.activeCheckBtn}`}
      />
      <div className={styles.cartImgs}>
        {typeof imgs === "string" ? (
          <div className={styles.imgBuyNow}>
            <img src={process.env.REACT_APP_STORAGE_URL + imgs} />
          </div>
        ) : (
          imgs.map((img) => (
            <div className={styles.cartImg}>
              <img src={process.env.REACT_APP_STORAGE_URL + img} />
            </div>
          ))
        )}
      </div>
      {title ? <span className={styles.cartText}>{title}</span> : null}
    </div>
  );
}
