import React, { useRef, useState } from "react";
import styles from "./style.module.css";
import LoadContent from "../../../../../src/components/LoadContent/index";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import { Thumbs } from "swiper/modules";
import SliderNextArrowSvg from "../../../../assets/SVG/SliderNextArrowSvg";

const AboutSlider = ({ sliderImageList }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [showBtns, setShowBtns] = useState(false);
  const [slidePosition, setSlidePosition] = useState({
    isBeginning: true,
    isEnd: true,
  });
  const [thumbImgActive, setTthumbImgActive] = useState(0);
  const swiperRef = useRef();
  return (
    <div
      // onMouseMove={() => (window.innerWidth > 320 && slidePosition.isBeginning != true && slidePosition.isEnd != true) && setShowBtns(true)}

      onMouseMove={() => window.innerWidth > 320 && setShowBtns(true)}
      onMouseOut={() => window.innerWidth > 320 && setShowBtns(false)}
    >
      {!sliderImageList ? (
        <LoadContent containerClassName={styles.loadContent} />
      ) : (
        <>
          <Swiper
            spaceBetween={10}
            thumbs={{
              swiper:
                thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
            }}
            modules={[Thumbs]}
            className={`swiper-no-swiping ${styles.mySwiper2}`}
          >
            {!!Object.values(sliderImageList)?.length && Object.values(sliderImageList)?.map((item, index) => (
              <SwiperSlide key={index}>
                <div className={styles.slideImg}>
                  <img
                    src={process.env.REACT_APP_STORAGE_URL + item}
                    alt="VLV banner"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          <div
            className="wrapper"
            style={{
              position: "absolute",
              height: "400px",
              left: 0,
              right: 0,
            }}
          >
            <div
              className={`${styles.slideBtn} ${styles.slidePrevArrow}`}
              style={{
                display:
                  swiperRef?.current?.isBeginning || !showBtns
                    ? "none"
                    : "flex",
              }}
              onClick={() => swiperRef.current?.slidePrev()}
            >
              <SliderNextArrowSvg />
            </div>
            <div
              className={`${styles.slideBtn} ${styles.slideNextArrow}`}
              style={{
                display:
                  swiperRef?.current?.isEnd == true || !showBtns
                    ? "none"
                    : "flex",
              }}
              onClick={() => swiperRef.current?.slideNext()}
            >
              <SliderNextArrowSvg />
            </div>
          </div>
          <Swiper
            loop={false}
            autoplay
            slidesPerView={1.5}
            spaceBetween={15}
            onSlideChange={(e) => {
              setSlidePosition({
                isEnd: e.isEnd,
                isBeginning: e.isBeginning,
              });
            }}
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
            pagination={{ clickable: true }}
            onSwiper={setThumbsSwiper}
            className={styles.mySwiper}
            breakpoints={{
              320: {
                slidesPerView: 1.5,
                spaceBetween: 15,
              },
              400: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              640: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 15,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 15,
              },
              1440: {
                slidesPerView: 6,
                spaceBetween: 15,
              },
            }}
            modules={[Thumbs]}
          >
            { !!Object.values(sliderImageList)?.length && Object.values(sliderImageList)?.map((item, index) => (
              <SwiperSlide key={index}>
                <div
                  className={
                    index + 1 === thumbImgActive
                      ? styles.thumbImgActive + " " + styles.thumbImg
                      : styles.thumbImg
                  }
                  onClick={() => setTthumbImgActive(Number(index + 1))}
                >
                  <img
                    // className={styles.thumbImg}
                    src={process.env.REACT_APP_STORAGE_URL + item}
                    alt="VLV banner"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      )}
    </div>
  );
};

export default AboutSlider;
