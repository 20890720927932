import React, { useLayoutEffect, useState } from "react";
import styles from "./style.module.css";

import Paralax1Svg from "./assets/Paralax1Svg";
import Paralax2Svg from "./assets/Paralax2Svg";
import Paralax3Svg from "./assets/Paralax3Svg";
import Paralax4Svg from "./assets/Paralax4Svg";

export default function Paralax() {
  const [Paralax, setParalax] = useState({
    x: 0,
    y: 0,
  });
  const listenerFunc = (e) => {
    const { clientX, clientY } = e;
    setParalax({
      x: -clientX,
      y: -clientY,
    });
  };
  useLayoutEffect(() => {
    document.addEventListener("mousemove", listenerFunc);
    return () => {
      document.removeEventListener("mousemove", listenerFunc);
    };
  });

  return (
    <div className={styles.container}>
      <Paralax1Svg
        className={styles.leftTop}
        style={{
          transform: `translate(${Paralax.x / 10}px, ${Paralax.y / 20}px)`,
        }}
      />
      <Paralax2Svg
        className={styles.leftBottom}
        style={{
          transform: `translate(${Paralax.x / 20}px, ${Paralax.y / 20}px)`,
        }}
      />

      <Paralax3Svg
        className={styles.rightTop}
        style={{
          transform: `translate(${Paralax.x / 20}px, ${Paralax.y / 20}px)`,
        }}
      />
      <Paralax4Svg
        className={styles.rightBottom}
        style={{
          transform: `translate(${Paralax.x / 15}px, ${Paralax.y / 20}px)`,
        }}
      />
    </div>
  );
}
