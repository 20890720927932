import React, { useLayoutEffect, useRef, useState } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import styles from "./style.module.css";
import { useSelector } from "react-redux";

const defaultCenter = {
  lat: 40.150011,
  lng: 44.495715,
};

const libraries = ["places"];

const Map = React.memo(
  ({
    addresses = [],
    mapCenter,
    setMapCenter,
    selectedIndex,
    setSelected,
    selected,
    handleMarkerClick,
    disableMap,
    input,
    setAddressName,
  }) => {
    let searchBoxRef = useRef();
    const { currentLanguage } = useSelector(({ main }) => main);
    const [markerPosition, setMarkerPosition] = useState();
    const [countAddress, setCountAddress] = useState(0);

    const mapContainerStyle = {
      display: disableMap ? "none" : "block",
      width: "100%",
      height: "100%",
    };

    const validMapCenter = {
      lat: parseFloat(mapCenter?.lat) || defaultCenter.lat,
      lng: parseFloat(mapCenter?.lng) || defaultCenter.lng,
    };

    const getAddressFromCoordinates = async (markerPosition) => {
      try {
        const positions = {
          lat:
            typeof markerPosition?.lat == "function"
              ? markerPosition?.lat()
              : markerPosition?.lat,
          lng:
            typeof markerPosition?.lng == "function"
              ? markerPosition?.lng()
              : markerPosition?.lng,
        };
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${positions.lat},${positions.lng}&key=AIzaSyBbDPndkzEd7822EVHTjnNn9ab2uLNH1ko`
        );
        const data = await response.json();

        if (data.status === "OK") {
          !!countAddress && setAddressName(data.results[0].formatted_address);
          setCountAddress((prev) => prev + 1);
        } else {
          console.error("Error fetching address:", data);
        }
      } catch (error) {
        console.error("Error fetching address:", error);
      }
    };

    const onPlacesChanged = () => {
      const places = searchBoxRef.current.state.searchBox?.getPlaces();

      if (places.length > 0) {
        const { geometry } = places[0];
        const { location } = geometry;
        const latLng = { lat: location.lat(), lng: location.lng() };

        if (!disableMap) {
          setMapCenter(latLng);
          setMarkerPosition(latLng);
        } else {
          getAddressFromCoordinates(latLng);
        }
      }
    };

    const handleMarkerDragEnd = (mapProps) => {
      setMarkerPosition(mapProps.latLng);
    };
    const handleClick = (mapProps) => {
      setMarkerPosition(mapProps.latLng);
    };

    useLayoutEffect(() => {
      if (!Object.keys(markerPosition || {}).length)
        setTimeout(() => {
          setMarkerPosition(defaultCenter);
        }, 1000);
      if (Object.keys(markerPosition || {})?.length)
        getAddressFromCoordinates(markerPosition);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [markerPosition?.lat]);

    return (
      <div
        style={{
          height: disableMap ? "auto" : "460px",
          width: "100%",
        }}>
        <LoadScript
          googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP}
          libraries={libraries}>
          {disableMap ? (
            <StandaloneSearchBox
              ref={searchBoxRef}
              onPlacesChanged={onPlacesChanged}>
              <div>{input}</div>
            </StandaloneSearchBox>
          ) : (
            <>
              <StandaloneSearchBox
                ref={searchBoxRef}
                onPlacesChanged={onPlacesChanged}>
                <div>{input}</div>
              </StandaloneSearchBox>
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={validMapCenter}
                zoom={15}
                onClick={handleClick}
                options={{ streetViewControl: false }}>
                {addresses?.length > 0 &&
                  addresses.map((address, index) => (
                    <Marker
                      key={address.id || index}
                      position={{
                        lat:
                          parseFloat(address.location?.lat) ||
                          defaultCenter.lat,
                        lng:
                          parseFloat(address.location?.lng) ||
                          defaultCenter.lng,
                      }}
                      icon={require("./assets/mapMarker.png")}
                      onClick={() => handleMarkerClick(address)}
                    />
                  ))}
                {markerPosition && (
                  <Marker
                    position={markerPosition}
                    draggable={true}
                    onDragEnd={handleMarkerDragEnd}
                    icon={require("./assets/mapMarker.png")}
                  />
                )}

                {selected && selectedIndex !== null ? (
                  <InfoWindow
                    position={selected}
                    onCloseClick={() => setSelected(null)}>
                    <div className={styles.addr_cont_inf}>
                      <span className={styles.locname_ch}>
                        {
                          addresses[selectedIndex]?.[
                            "address_" + currentLanguage
                          ]
                        }
                      </span>

                      <div className={styles.time_phone}>
                        <div className={styles.chosen_phone_mail_user}>
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M7 14C3.11719 14 0 10.8828 0 7C0 3.14453 3.11719 0 7 0C10.8555 0 14 3.14453 14 7C14 10.8828 10.8555 14 7 14ZM6.34375 7C6.34375 7.21875 6.45312 7.4375 6.61719 7.54688L9.24219 9.29688C9.54297 9.51562 9.95312 9.43359 10.1445 9.13281C10.3633 8.83203 10.2812 8.42188 9.98047 8.20312L7.65625 6.67188V3.28125C7.65625 2.92578 7.35547 2.625 6.97266 2.625C6.61719 2.625 6.31641 2.92578 6.31641 3.28125L6.34375 7Z"
                              fill="#31BA5F"></path>
                          </svg>
                          {
                            addresses[selectedIndex]?.[
                              "work_time_" + currentLanguage
                            ]
                          }
                        </div>
                        <a
                          href="tel:374 10-44-99-68"
                          className={styles.chosen_phone_mail_user}>
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M13.9828 10.5589L13.326 13.3172C13.244 13.7269 12.9156 14 12.5051 14C5.60952 13.9727 0 8.37411 0 1.49195C0 1.0823 0.246272 0.754573 0.656725 0.672643L3.42044 0.0171991C3.80353 -0.0647314 4.21398 0.15375 4.37817 0.508782L5.66425 3.48559C5.80107 3.84062 5.71898 4.25027 5.41798 4.46875L3.94035 5.6704C4.87071 7.5548 6.40307 9.08417 8.31851 10.0127L9.52251 8.53797C9.74142 8.26487 10.1519 8.15563 10.5076 8.29218L13.4902 9.57575C13.8459 9.76692 14.0649 10.1766 13.9828 10.5589Z"
                              fill="#E31335"></path>
                          </svg>
                          {addresses[selectedIndex]?.["tel_" + currentLanguage]}
                        </a>
                      </div>
                      <img
                        src={
                          process.env.REACT_APP_STORAGE_URL +
                          addresses[selectedIndex]?.["image_" + currentLanguage]
                        }
                        alt={
                          addresses[selectedIndex]?.["image_" + currentLanguage]
                        }
                        style={{ maxHeight: 168 }}
                      />
                    </div>
                  </InfoWindow>
                ) : null}
              </GoogleMap>
            </>
          )}
        </LoadScript>
      </div>
    );
  }
);

export default Map;
