import React, { useRef, useState } from "react";
import styles from "./style.module.css";
import Filter from "../Filter";
import lang from "../../../../lang/lang";
import { useDispatch, useSelector } from "react-redux";
import UseOutSideClick from "../../../../helpers/UseOutSideClick";
import { setShowFilterModal } from "../../../../store/SearchPageSlice";

export default function FilterModal() {
  const currentLanguage = useSelector(({ main }) => main.currentLanguage);
  const showFilterModal = useSelector(
    ({ searchPage }) => searchPage.showFilterModal
  );

  const mainRef = useRef();
  const dispatch = useDispatch();

  UseOutSideClick(mainRef, () => dispatch(setShowFilterModal(false)));
  return (
    <div
      className={styles.container}
      style={!showFilterModal ? { display: "none" } : null}
    >
      <div ref={mainRef} className={`${styles.main} scrollBar`}>
        <div className={styles.filterHeader}>
          <div>
            <svg
              width="16"
              height="15"
              viewBox="0 0 16 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 12.5C0 12.25 0.21875 12 0.5 12H2.53125C2.78125 10.875 3.78125 10 5 10C6.1875 10 7.1875 10.875 7.4375 12H15.5C15.75 12 16 12.25 16 12.5C16 12.7812 15.75 13 15.5 13H7.4375C7.1875 14.1562 6.1875 15 5 15C3.78125 15 2.78125 14.1562 2.53125 13H0.5C0.21875 13 0 12.7812 0 12.5ZM6.5 12.5C6.5 11.6875 5.8125 11 5 11C4.15625 11 3.5 11.6875 3.5 12.5C3.5 13.3438 4.15625 14 5 14C5.8125 14 6.5 13.3438 6.5 12.5ZM11 5C12.1875 5 13.1875 5.875 13.4375 7H15.5C15.75 7 16 7.25 16 7.5C16 7.78125 15.75 8 15.5 8H13.4375C13.1875 9.15625 12.1875 10 11 10C9.78125 10 8.78125 9.15625 8.53125 8H0.5C0.21875 8 0 7.78125 0 7.5C0 7.25 0.21875 7 0.5 7H8.53125C8.78125 5.875 9.78125 5 11 5ZM12.5 7.5C12.5 6.6875 11.8125 6 11 6C10.1562 6 9.5 6.6875 9.5 7.5C9.5 8.34375 10.1562 9 11 9C11.8125 9 12.5 8.34375 12.5 7.5ZM15.5 2C15.75 2 16 2.25 16 2.5C16 2.78125 15.75 3 15.5 3H8.4375C8.1875 4.15625 7.1875 5 6 5C4.78125 5 3.78125 4.15625 3.53125 3H0.5C0.21875 3 0 2.78125 0 2.5C0 2.25 0.21875 2 0.5 2H3.53125C3.78125 0.875 4.78125 0 6 0C7.1875 0 8.1875 0.875 8.4375 2H15.5ZM4.5 2.5C4.5 3.34375 5.15625 4 6 4C6.8125 4 7.5 3.34375 7.5 2.5C7.5 1.6875 6.8125 1 6 1C5.15625 1 4.5 1.6875 4.5 2.5Z"
                fill="#E31335"
              ></path>
            </svg>
            <span>{lang[currentLanguage].filter}</span>
          </div>
          <span
            className={styles.close}
            onClick={() => dispatch(setShowFilterModal(false))}
          >
            ✖
          </span>
        </div>
        <Filter />
      </div>
    </div>
  );
}
