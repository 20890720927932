import React from "react";

export default function HomeSvg() {
  return (
    <svg
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.9062 10.5805L19.057 1.73013C17.9618 0.63812 16.4783 0.0249023 14.9317 0.0249023C13.385 0.0249023 11.9015 0.63812 10.8063 1.73013L1.95715 10.5805C1.63098 10.9045 1.37239 11.2901 1.19635 11.7149C1.02032 12.1397 0.930345 12.5952 0.931655 13.055V24.5081C0.931655 25.4364 1.3004 26.3266 1.95678 26.983C2.61316 27.6394 3.5034 28.0081 4.43165 28.0081H25.4317C26.3599 28.0081 27.2501 27.6394 27.9065 26.983C28.5629 26.3266 28.9317 25.4364 28.9317 24.5081V13.055C28.933 12.5952 28.843 12.1397 28.667 11.7149C28.4909 11.2901 28.2323 10.9045 27.9062 10.5805V10.5805ZM18.4317 25.6748H11.4317V21.0851C11.4317 20.1569 11.8004 19.2666 12.4568 18.6103C13.1132 17.9539 14.0034 17.5851 14.9317 17.5851C15.8599 17.5851 16.7502 17.9539 17.4065 18.6103C18.0629 19.2666 18.4317 20.1569 18.4317 21.0851V25.6748ZM26.5983 24.5081C26.5983 24.8176 26.4754 25.1143 26.2566 25.3331C26.0378 25.5519 25.7411 25.6748 25.4317 25.6748H20.765V21.0851C20.765 19.538 20.1504 18.0543 19.0564 16.9603C17.9625 15.8664 16.4788 15.2518 14.9317 15.2518C13.3846 15.2518 11.9008 15.8664 10.8069 16.9603C9.7129 18.0543 9.09832 19.538 9.09832 21.0851V25.6748H4.43165C4.12224 25.6748 3.82549 25.5519 3.6067 25.3331C3.3879 25.1143 3.26499 24.8176 3.26499 24.5081V13.055C3.26607 12.7458 3.38888 12.4494 3.60682 12.2301L12.456 3.3833C13.1136 2.72872 14.0038 2.36123 14.9317 2.36123C15.8595 2.36123 16.7497 2.72872 17.4073 3.3833L26.2565 12.2336C26.4736 12.4521 26.5963 12.747 26.5983 13.055V24.5081Z"
        fill="#282828"
      ></path>
    </svg>
  );
}
