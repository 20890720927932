import React from "react";
import style from "./style.module.css";
import styleVue2 from "../../style..module.css";
import langData from "../../../../lang/data";
import LoadContent from "../../../../components/LoadContent";
import ProductsWithSlide from "../../../../components/ProductsWithSlide";
import TimerPrice from "../../components/TimerPrice";
import DetailsBigBanner from "../../components/DetailsBigBanner";
import DetailsCategories from "../../components/DetailsCategories";
import lang from "../../../../lang/lang";
import FromToPage from "../../../../components/FromToPage";
import { Link } from "react-router-dom";

const DetailsVue1 = ({ detailsList, isLoading, error, currentLanguage }) => {
 
  return (
    <div className="wrapper">
      <FromToPage
        pages={[
          {
            label: langData.titles.Sales[currentLanguage],
            value: "",
            active: false,
          },
          {
            label: detailsList?.sale?.["name_" + currentLanguage],
            value: "",
            active: true,
          },
        ]}
      />
      <DetailsBigBanner
        isLoading={isLoading}
        currentLanguage={currentLanguage}
        detailsList={detailsList}
      />
      <div className={styleVue2.details_description}>
        <div className={style.promotion_descrip}>
          {detailsList.sale?.["title_" + currentLanguage]}
        </div>

        {!isLoading && detailsList.sale ? (
          <>
            <p
              dangerouslySetInnerHTML={{
                __html: detailsList.sale?.["description_" + currentLanguage],
              }}
            />
          </>
        ) : (
          <>
            {Array.from({ length: 3 }).map((_, index) => {
              return (
                <div className={styleVue2.details_description_loader_item}>
                  <LoadContent
                    key={index}
                    containerClassName={styleVue2.details_description_loader}
                  />
                </div>
              );
            })}
          </>
        )}
      </div>
      <div className={style.details_vue1_categories}>
        {!isLoading ? (
          <>
            {detailsList.categories ? (
              <>
                <DetailsCategories list={detailsList.categories} detailsList = {detailsList} />
              </>
            ) : null}
          </>
        ) : (
          <>
            <div className={style.details_loader_categories}>
              {Array.from({ length: 5 }).map((_, index) => {
                return (
                  <LoadContent
                    key={index}
                    containerClassName={style.details_loader_categories_item}
                  />
                );
              })}
            </div>
          </>
        )}
      </div>
      {!!detailsList?.products_second_slider?.length && (
        <div className={style.details_vue1_slider}>
          <div className={style.details_product_title}>
            <span> {langData.titles.SpecialPrices?.[currentLanguage]}</span>
            <Link to={detailsList.sale.view_href}>
              {lang[currentLanguage]?.see_all}
            </Link>
          </div>
          {detailsList?.products_second_slider?.length && (
            <ProductsWithSlide
              productsData={!isLoading && detailsList?.products_second_slider}
              noWrapper={true}
            />
          )}
        </div>
      )}
      {detailsList?.sale && detailsList?.sale?.time2 && (
        <div className={style.details_timer_block}>
          <div className={style.details_title_img}>
            <div className={style.procent_img}>
              <img
                data-src="https://vlv.am/public/img/procent.svg"
                alt="img"
                src="https://vlv.am/public/img/procent.svg"
              />
            </div>
            <div className={style.get_titles}>
              <span>{lang[currentLanguage].ship_sot}</span>
              <p>
                {lang[currentLanguage]?.stet_ended
                  .split(" ")
                  .filter((item) => item !== ":ender")
                  .join(" ")}{" "}
                {detailsList?.sale?.time2?.split("-")?.join("/")}
              </p>
            </div>
          </div>

          <TimerPrice targetDate={detailsList?.sale?.time2} />
        </div>
      )}
      <div className={styleVue2.details_footer_banners}>
        {!isLoading && detailsList.sale ? (
          <>
            <div className={styleVue2.details_footer_banners_item}>
              <img
                src={
                  process.env.REACT_APP_STORAGE_URL +
                  detailsList.sale?.["slider_image1_" + currentLanguage]
                }
                alt=""
              />
            </div>
            <div className={styleVue2.details_footer_banners_item}>
              <img
                src={
                  process.env.REACT_APP_STORAGE_URL +
                  detailsList.sale?.["slider_image2_" + currentLanguage]
                }
                alt=""
              />
            </div>
          </>
        ) : (
          <>
            <LoadContent
              containerClassName={styleVue2.details_footer_banners_loader}
            />
            <LoadContent
              containerClassName={styleVue2.details_footer_banners_loader}
            />
          </>
        )}
      </div>
      {detailsList?.products_first_slider?.length && (
        <ProductsWithSlide
          productsData={!isLoading && detailsList?.products_first_slider}
          noWrapper={true}
        />
      )}
    </div>
  );
};

export default DetailsVue1;
