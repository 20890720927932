import React, { useState } from "react";
import styles from "./style.module.css";
import MinusSvg from "../../assets/MinusSvg";
import PlusSvg from "../../assets/PlusSvg";
import HeartSvg from "../../../../../../assets/SVG/HeartSvg";
import CompareSvg from "../../../../../../assets/SVG/CompareSvg";
import langData from "../../../../../../lang/data";
import { useDispatch, useSelector } from "react-redux";
import { credit36Month } from "../../../../../../helpers/creditCalculator";
import {
  addCompare,
  addCompares,
  addFavorites,
  addWishList,
  removeCompares,
  removeFavorites,
} from "../../../../../../store/MainSlice";
import UseCalcPrice from "../../../../../../helpers/UseCalcPrice";
import { Link } from "react-router-dom";
export default function Product({ item, img,info, x, y, last }) {
  const [showInfo, setShowInfo] = useState();

  const { currentLanguage, currentCurrency, favorites, compares } = useSelector(
    ({ main }) => main
  );

  const dispatch = useDispatch();
 
  return (
    <div className={styles.product}>
       
      <div
        className={styles.plusBtn}
        style={{ left: x + "%", top: y + "%" }}
        onClick={() => setShowInfo(!showInfo)}
      >
        {showInfo ? <MinusSvg /> : <PlusSvg />}
      </div>
      <div
        className={`${styles.infoContainer} ${
          showInfo && styles.showedInfoContainer
        }`}
        style={
          last
            ? {
                right: 100 - x - (window.innerWidth > 600 ? 10 : 18) + "%",
                top: `calc(${
                  "calc(" +
                  y +
                  `%) + ${window.innerWidth > 768 ? "40" : "30"}px`
                })`,
              }
            : {
                left: (window.innerWidth > 600  || !info ?'-20+' : '-') + x + "%",
                top: `calc(${
                  "calc(" +
                  y +
                  `%) + ${window.innerWidth > 768 ? "40" : "30"}px`
                })`,
              }
        }
      >
        <div className={styles.smallImg}>
        <Link  to={`${
                    currentLanguage !== "hy" ? "/" + currentLanguage : ""
                  }/Product/` + item?.id}
                >
          <img
            src={
              process.env.REACT_APP_STORAGE_URL +
              item?.product?.gallary_images_api?.[0]?.thum_image_sourc
            }
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = require("../../../../../../assets/defaultImg.png");
            }}
            alt={item.product.brand.name + " " + item?.product?.product_name}
          />
          </Link>
        </div>
        <div className={styles.aboutProduct}>
          <Link  to={`${
                    currentLanguage !== "hy" ? "/" + currentLanguage : ""
                  }/Product/` + item?.id}
                >
          <div className={styles.aboutProductInfo}>
            <span className={styles.price}>
              {UseCalcPrice(item.skus?.[0]?.selling_price, currentCurrency)}
            </span>
            <span className={styles.category}>
              {item.categories["name_" + currentLanguage]}
            </span>
            <span className={styles.name}>
              {item.product.brand.name + " " + item?.product?.product_name}
            </span>
            {credit36Month(item.skus?.[0]?.selling_price) ? (
              <span className={styles.priceMounth}>
                {UseCalcPrice(
                  credit36Month(item.skus?.[0]?.selling_price),
                  currentCurrency
                )}{" "}
                / {langData.month[currentLanguage]}
              </span>
            ) : null}
          </div>
          </Link>
          <div className={styles.btnsContainer}>
            <div
              onClick={() => {
                if (!favorites.includes(item.id)) {
                  dispatch(addFavorites(item.id));
                } else {
                  dispatch(removeFavorites(item.id));
                }
                dispatch(
                  addWishList({
                    seller_product_id: item.id,
                  })
                );
              }}
            >
              <HeartSvg
                active={favorites.includes(item.id)}
                className={styles.heartSvg}
              />
            </div>
            <div
              onClick={() => {
                if (!compares.includes(item?.skus?.[0]?.id)) {
                  dispatch(addCompares(item?.skus?.[0]?.id));
                } else {
                  dispatch(removeCompares(item?.skus?.[0]?.id));
                }
                dispatch(
                  addCompare({
                    product_sku_id: item.skus?.[0].id,
                    data_type: item.product.product_type,
                  })
                );
              }}
              className={styles.compareBtn}
            >
              <CompareSvg active={compares.includes(item?.skus?.[0]?.id)} />
            </div>
          </div>
          
        </div>
      </div>
   
      <img
        className={styles.productImg}
        src={process.env.REACT_APP_STORAGE_URL + img}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = require("../../../../../../assets/defaultImg.png");
        }}
        alt={item.product.brand.name + " " + item?.product?.product_name}
      />
    
    </div>
  );
}
