import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "./request";
import { setPending } from "./MainSlice";

const initialState = {
  searchPageData: null,
  searchPageProducts: [],
  sortType: {
    label_en: "Price (Low to High)",
    label_hy: "Գին` ցածրից բարձր",
    label_ru: "Цена :  мин - макс",
    value: "low_to_high",
  },
  listType: "grid",
  attributes: [],
  brands: [],
  categories: [],
  colors: [],
  discount: false,
  minPrice: 0,
  maxPrice: 0,
  search: "",
  filterData: {},
  showFilterModal: false,
  slug: "",
  URLParams: null,
  URLSearch: null,
};
let defaultPrices, allBrands, allCategories;

// let process.env.REACT_APP_TOKEN;
// let CSRF_TOKEN;
export const saleFiltrSlice = createSlice({
  name: "saleFiltr",
  initialState,
  reducers: {
    setSearchPageData: (store, action) => {
      return {
        ...store,
        searchPageData: action.payload,
      };
    },
    setSearchPageProducts: (store, action) => {
      return {
        ...store,
        searchPageProducts: action.payload,
      };
    },
    setSortType: (store, action) => {
      return {
        ...store,
        sortType: action.payload,
      };
    },
    setListType: (store, action) => {
      return {
        ...store,
        listType: action.payload,
      };
    },
    setAttributes: (store, action) => {
      return {
        ...store,
        attributes: action.payload,
      };
    },
    addAttribute: (store, action) => {
      return {
        ...store,
        attributes: [...store.attributes, action.payload],
      };
    },
    removeAttribute: (store, action) => {
      const filteredArray = store.attributes.filter(
        (AttrId) => AttrId !== action.payload
      );
      return {
        ...store,
        attributes: filteredArray,
      };
    },
    addBrand: (store, action) => {
      return {
        ...store,
        brands: [...store.brands, action.payload],
      };
    },
    removeBrand: (store, action) => {
      const filteredArray = store.brands.filter(
        (AttrId) => AttrId !== action.payload
      );
      return {
        ...store,
        brands: filteredArray,
      };
    },
    setBrands: (store, action) => {
      return {
        ...store,
        brands: action.payload,
      };
    },
    setCategories: (store, action) => {
      return {
        ...store,
        categories: action.payload,
      };
    },
    addCategory: (store, action) => {
      return {
        ...store,
        categories: [...store.categories, action.payload],
      };
    },
    removeCategory: (store, action) => {
      const filteredArray = store.categories.filter(
        (AttrId) => AttrId !== action.payload
      );
      return {
        ...store,
        categories: filteredArray,
      };
    },

    addColor: (store, action) => {
      return {
        ...store,
        colors: [...store.colors, action.payload],
      };
    },
    removeColor: (store, action) => {
      const filteredArray = store.colors.filter(
        (AttrId) => AttrId !== action.payload
      );
      return {
        ...store,
        colors: filteredArray,
      };
    },
    setDiscount: (store, action) => {
      return {
        ...store,
        discount: action.payload,
      };
    },
    setMinPrice: (store, action) => {
      return {
        ...store,
        minPrice: action.payload,
      };
    },
    setMaxPrice: (store, action) => {
      return {
        ...store,
        maxPrice: action.payload,
      };
    },
    setSearch: (store, action) => {
      return {
        ...store,
        search: action.payload,
      };
    },
    setShowFilterModal: (store, action) => {
      return {
        ...store,
        showFilterModal: action.payload,
      };
    },
    setFilterData: (store, action) => {
      return {
        ...store,
        filterData: {
          ...store.filterData,
          ...action.payload,
        },
      };
    },
    setDefaultFilteres: (store, action) => {
      return {
        ...store,
        attributes: [],
        brands: [],
        categories: [],
        colors: [],
        discount: false,
        minPrice: defaultPrices?.min,
        maxPrice: defaultPrices?.max,
        search: "",
      };
    },
    setSlug: (store, action) => {
      return {
        ...store,
        slug: action.payload,
      };
    },
    setURLParams: (store, action) => {
      return {
        ...store,
        URLParams: action.payload,
      };
    },
    setURLSearch: (store, action) => {
      return {
        ...store,
        URLSearch: action.payload,
      };
    },
  },
});
export const updateSearchPageFilter =
  (products, lastChangeAttributeValues, lastCanged) =>
  async (dispatch, getState) => {
    let min,
      max,
      attributes = new Set(),
      attributeVales = new Set(),
      brands = new Set(),
      categories = new Set(),
      discount = false;
    products?.forEach((product) => {
      const price =
        (product.product.online_price &&
          product.product.online_selling_price) ||
        product.promo_price ||
        product.recommended_retail_price ||
        product.skus[0].selling_price;

      if (price < min || !min) {
        min = price;
      }
      if (price > max || !max) {
        max = price;
      }

      product?.product?.variations?.forEach((attribute) => {
        if (Object.keys(attribute?.attribute || {}).length)
          attributes.add(
            JSON.stringify({
              id: attribute?.attribute?.id,
              name_en: attribute?.attribute?.name_en,
              name_ru: attribute?.attribute?.name_ru,
              name_hy: attribute?.attribute?.name_hy,
            })
          );
        if (Object.keys(attribute.attribute_value || {}).length)
          attributeVales.add(
            JSON.stringify({
              attribute_id: attribute.attribute_value?.attribute_id,
              id: attribute.attribute_value?.id,
              value_en: attribute.attribute_value?.value_en,
              value_ru: attribute.attribute_value?.value_ru,
              value_hy: attribute.attribute_value?.value_hy,
            })
          );
      });

      brands.add(
        JSON.stringify({
          name: product.product?.brand.name,
          id: product.product?.brand.id,
        })
      );

      if (product?.categories?.length) {
        categories.add(
          JSON.stringify({
            name_en: product?.categories?.[0]?.name_en,
            name_ru: product?.categories?.[0]?.name_ru,
            name_hy: product?.categories?.[0]?.name_hy,
            id: product?.categories?.[0]?.id,
          })
        );
      }
      if (
        (product.product.online_price &&
          product.product.online_selling_price) ||
        product.promo_price ||
        product.recommended_retail_price
      ) {
        discount = true;
      }
    });
    if (!defaultPrices?.min && !defaultPrices?.max) {
      defaultPrices = {
        min: min,
        max: max,
      };
    }
    if (!allBrands) {
      allBrands = Array.from(brands).map(JSON.parse);
    }
    if (!allCategories) {
      allCategories = Array.from(categories).map(JSON.parse);
    }

    lastChangeAttributeValues?.forEach((item) => {
      attributeVales.add(JSON.stringify(item));
    });

    const {
      filterData,
      searchPageData,
      attributes: attributesState,
      brands: brandsState,
    } = await getState().searchPage;
    if (searchPageData?.products?.length) {
      brands.clear();
      searchPageData?.products?.forEach((product) => {
        if (attributesState?.length) {
          product.product.variations.forEach(({ attribute_value_id }) => {
            if (attributesState.includes(attribute_value_id)) {
              brands.add(
                JSON.stringify({
                  name: product.product?.brand.name,
                  id: product.product?.brand.id,
                })
              );
            }
          });
        } else {
          brands.add(
            JSON.stringify({
              name: product.product?.brand.name,
              id: product.product?.brand.id,
            })
          );
        }
      });
    }

    let brands1 = Array.from(brands).map(JSON.parse);
    let activeBrands = [];
    brandsState.forEach((id) => {
      let findedBrand = brands1.find((item) => item.id === id);
      if (findedBrand) activeBrands.push(findedBrand.id);
    });
    dispatch(setBrands(activeBrands));

    const newfilterData = {
      defaultPrices: defaultPrices,
      allBrands: allBrands,
      allCategories: allCategories,
      attributes: Array.from(attributes).map(JSON.parse),
      attributeVales: Array.from(attributeVales).map(JSON.parse),
      brands: brands1,
      categories: Array.from(categories).map(JSON.parse),
      discount,
      //
      min,
      max,
    };
    // if (lastCanged == "category") {
    //   newfilterData.categories = filterData.categories;
    // } else if (lastCanged == "brand") {
    //   newfilterData.brands = filterData.brands;
    // } else if (lastCanged == "price") {
    //   newfilterData.min = filterData.min;
    //   newfilterData.max = filterData.max;
    // }

    dispatch(setFilterData(newfilterData));
  };

export const filterProducts = (lastCanged) => async (dispatch, getState) => {
  const {
    searchPageData,
    brands,
    categories,
    attributes,
    colors,
    discount,
    minPrice,
    maxPrice,
    search,
    filterData,
    slug,
  } = await getState().searchPage;
  // if (typeof lastCanged !== "string") {
  //   var lastChangeAttributeValues = filterData?.attributeVales?.filter(
  //     ({ attribute_id }) => {
  //       return attribute_id == lastCanged;
  //     }
  //   );
  // }

  const filteredProducts =
    (searchPageData?.products || searchPageData?.products.data)?.length &&
    (searchPageData?.products?.data || searchPageData?.products)?.filter(
      (product) => {
        if (search) {
          let returnNull = false;
          let keywordsArray = search.toLowerCase().split(" ");

          keywordsArray?.forEach((keyword) => {
            if (
              !product?.product?.brand?.name
                ?.toLowerCase()
                ?.includes(keyword) &&
              !product?.product?.product_name
                ?.toLowerCase()
                ?.includes(keyword) &&
              !product?.product?.li_product_id?.toString()?.includes(keyword)
            ) {
              returnNull = true;
            }
          });
          if (returnNull) return null;
        }

        if (brands.length && !brands.includes(product.product.brand.id))
          return null;
        if (
          categories?.length &&
          !categories.includes(product?.categories[0]?.id)
        )
          return null;

        if (attributes.length) {
          let returnNull = true;
          attributes?.forEach?.((attrId) => {
            product.product?.variations?.forEach(({ attribute_value_id }) => {
              if (attribute_value_id == attrId) {
                returnNull = false;
              }
            });
          });
          if (returnNull) return null;
        }

        if (!!discount) {
          if (
            !(
              (product.product.online_price &&
                product.product.online_selling_price) ||
              product.promo_price ||
              product.recommended_retail_price
            )
          )
            return null;
        }
        const price =
          (product.product.online_price &&
            product.product.online_selling_price) ||
          product.promo_price ||
          product.recommended_retail_price ||
          product.skus[0].selling_price;

        if (price < minPrice || price > maxPrice) {
          return null;
        }

        if (colors.length) {
          let returnNull = true;
          colors.forEach((attrId) => {
            product.product?.variations?.forEach((color) => {
              if (color.attribute_value_id == attrId) {
                returnNull = false;
              }
            });
          });
          if (returnNull) return null;
        }
        return product;
      }
    );

  // dispatch(
  //   updateSearchPageFilter(
  //     filteredProducts,
  //     lastChangeAttributeValues,
  //     lastCanged
  //   )
  // );

  dispatch(setSearchPageProducts(filteredProducts));
  // const currentUrl = window.location.origin + window.location.pathname;
  // const newUrl =
  //   currentUrl +
  //   "?" +
  //   slug +
  //   (brands.length ? "&b=" + brands : "") +
  //   (categories.length ? "&ct=" + categories : "") +
  //   (discount ? "&d=1" : "") +
  //   (search ? "&st=" + search : "") +
  //   (attributes.length ? "&a=" + attributes : "") +
  //   (minPrice ? "&min=" + minPrice : "") +
  //   (maxPrice ? "&max=" + maxPrice : "");

  // window.history.replaceState({}, document.title, newUrl);
};
// export const getSearchRedirectInfo =
//   (slug = "", navigate, currentLanguage) =>
//   (dispatch) => {
//     dispatch(setPending(true));
//     dispatch(setFilterData({}));
//     const _token = document.querySelector('meta[name="_token"]')?.content;
//     axiosInstance
//       .post(`/category/${slug}?item=search`, {
//         login: process.env.REACT_APP_LOGIN,
//         password: process.env.REACT_APP_PASSWORD,
//         token: process.env.REACT_APP_TOKEN,
//         _token,
//       })
//       .then((response) => {
//         if (response.data.redirect_url) {
//           window.replace(response.data.redirect_url);
//           return null;
//         }
//         dispatch(setFilterData({}));
//         if (response.data?.seller_product_id) {
//           navigate(
//             `${currentLanguage == "hy" ? "/" + currentLanguage : ""}/Product/${
//               response.data?.seller_product_id
//             }`
//           );
//           dispatch(setPending(false));
//         } else if (
//           (response.data.slug == "hisense" ||
//             response.data.slug == "samsung" ||
//             response.data.slug == "toshiba" ||
//             response.data.slug == "vikass") &&
//           !response.data.st
//         ) {
//           navigate(
//             `${currentLanguage == "hy" ? "/" + currentLanguage : ""}/brand/${
//               response.data.slug
//             }`
//           );
//           dispatch(setPending(false));
//         } else if (Object.keys(response?.data?.products || {}).length) {
//           dispatch(setMinPrice(response?.data?.data?.min_price_lowest));
//           dispatch(setMaxPrice(response?.data?.data?.max_price_highest));

//           if (response?.data?.brandList.length == 1) {
//             dispatch(addBrand(response?.data?.brandList[0].id));
//           }
//           if (response?.data?.categoryList.length == 1) {
//             dispatch(addCategory(response?.data?.categoryList[0].id));
//           }
//           dispatch(setSearchPageProducts(response?.data?.products));
//           dispatch(setSearchPageData(response.data));
//           navigate(
//             `${currentLanguage == "hy" ? "/" + currentLanguage : ""}/search`
//           );

//           dispatch(setPending(false));
//         } else if (response.data.item && response.data.slug) {
//           if (response.data.slug) dispatch(setSlug(response.data.slug));
//           const sendData = {
//             item: response.data.item,
//             slug: response.data.slug,
//           };
//           const params = response.data;
//           delete params.item;
//           delete params.slug;
//           delete params?.t;

//           sendData.params = params;
//           let paramsUrl = "";
//           Object.keys(sendData.params)?.forEach?.((e) => {
//             paramsUrl += "&" + e + "=" + sendData.params[e];
//           });

//           navigate(
//             `${currentLanguage == "hy" ? "/" + currentLanguage : ""}/search/${
//               sendData.item
//             }?${sendData.slug}${paramsUrl}`
//           );
//         } else {
//           dispatch(setPending(false));

//           navigate(
//             `${
//               currentLanguage == "hy" ? "/" + currentLanguage : ""
//             }/search-null`
//           );
//         }
//       })
//       .catch((err) => {
//         console.warn("Error: getSearchRedirectInfo", err);
//       });
//   };
// export const getSearchPageData =
//   ({ item = "category", slug = "", params = {} }, navigate = () => {}) =>
//   async (dispatch, getState) => {
//     defaultPrices = null;
//     allCategories = null;
//     allBrands = null;
//     const currentLanguage = await getState().main.currentLanguage;
//     dispatch(setPending(true));
//     dispatch(setSearchPageData(null));
//     dispatch(setFilterData({}));
//     dispatch(setMaxPrice(null));
//     dispatch(setMinPrice(null));
//     dispatch(setSearch(""));
//     dispatch(setSearchPageProducts([]));
//     dispatch(setAttributes([]));
//     dispatch(setListType("grid"));
//     dispatch(setBrands([]));
//     dispatch(setCategories([]));
//     dispatch(setDiscount(false));

//     dispatch(setSlug(slug));
//     const _token = document.querySelector('meta[name="_token"]')?.content;

//     axiosInstance
//       .post(`/${item}/${slug}?item=${item}`, {
//         login: process.env.REACT_APP_LOGIN,
//         password: process.env.REACT_APP_PASSWORD,
//         token: process.env.REACT_APP_TOKEN,
//         _token,
//       })
//       .then((response) => {
//         if (response.data.redirect_url) {
//           window.replace(response.data.redirect_url);
//           return null;
//         }

//         if (
//           !response.data?.products?.length &&
//           !response.data?.data?.products?.length
//         ) {
//           navigate(
//             `${
//               currentLanguage == "hy" ? "/" + currentLanguage : ""
//             }/search-null`
//           );
//           dispatch(setPending(false));
//           return null;
//         }
//         dispatch(updateSearchPageFilter(response.data.data?.products));
//         dispatch(setMinPrice(response?.data?.data?.min_price_lowest));
//         dispatch(setMaxPrice(response?.data?.data?.max_price_highest));
//         dispatch(setSearchPageProducts(response.data.data?.products));
//         dispatch(setSearchPageData(response.data.data));

//         if (Object.keys(params || {}).length) {
//           if (params?.b) {
//             JSON.parse("[" + params?.b + "]")?.forEach((brand) => {
//               dispatch(addBrand(brand));
//             });
//           }
//           if (params?.ct) {
//             JSON.parse("[" + params?.ct + "]")?.forEach((category) => {
//               dispatch(addCategory(category));
//             });
//           }
//           if (params?.d) {
//             dispatch(setDiscount(true));
//           }
//           if (params?.a) {
//             dispatch(setAttributes(JSON.parse("[" + params?.a + "]")));
//           }
//           if (params?.min) {
//             dispatch(setMinPrice(params?.min));
//             dispatch(
//               setFilterData({
//                 min: params.min,
//               })
//             );
//             defaultPrices = {
//               ...defaultPrices,
//               min: params.min,
//             };
//           }
//           if (params?.max) {
//             dispatch(
//               setFilterData({
//                 max: params.max,
//               })
//             );
//             defaultPrices = {
//               ...defaultPrices,
//               max: params.max,
//             };
//             dispatch(setMaxPrice(params?.max));
//           }
//           if (params?.st) {
//             dispatch(setSearch(params.st));
//           }
//           dispatch(filterProducts());
//         }
//         dispatch(setPending(false));
//       })
//       .catch((err) => {
//         console.warn("Error: getSearchPageData", err);
//       });
//   };

export const {
  setSearchPageData,
  setSearchPageProducts,
  setListType,
  setSortType,
  setAttributes,
  addAttribute,
  removeAttribute,
  addBrand,
  removeBrand,
  addCategory,
  removeCategory,
  addColor,
  removeColor,
  setCategories,
  setDiscount,
  setMinPrice,
  setMaxPrice,
  setSearch,
  setShowFilterModal,
  setFilterData,
  setDefaultFilteres,
  setSlug,
  setURLParams,
  setURLSearch,
  setBrands,
} = saleFiltrSlice.actions;
export default saleFiltrSlice.reducer;
