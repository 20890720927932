import React, { Fragment, useEffect, useRef, useState } from "react";
import styles from "./style.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getSearchInfo, setCompareSearch } from "../../../../store/MainSlice";
import langData from "../../../../lang/data";
import ComapreSearchModal from "./components/ComapreSearchModal";
import ComparedProduct from "./components/ComparedProduct";
import LoadContent from "../../../../components/LoadContent";

export default function ProductPageCompare() {
  const [requestToken, setRequestToken] = useState();
  const [searchText, setSearchText] = useState("");
  const [compareProducts, setCompareProducts] = useState([]);
  const [comparenTitles, setComparenTitles] = useState([]);

  const { currentLanguage, productInfo } = useSelector(({ main }) => main);

  const compareSearchInputRef = useRef();
  const dispatch = useDispatch();

  const product = productInfo?.product;

  useEffect(() => {
    setCompareProducts([]);
    if (product?.variations) {
      const titles = [];
      product?.variations?.forEach((item) => {
        if (
          item.attribute?.["name_" + currentLanguage] &&
          item?.attribute_value?.["value_" + currentLanguage]
        ) {
          titles.push({
            key: item.attribute,
            value: [
              item?.attribute_value?.color
                ? {
                    value_en: item?.attribute_value?.color?.name_en,
                    value_ru: item?.attribute_value?.color?.name_ru,
                    value_hy: item?.attribute_value?.color?.name_hy,
                  }
                : item?.attribute_value,
              "",
              "",
            ],
          });
        }
      });
      setComparenTitles(titles);
    }
  }, [product]);

  return (
    <div className={styles.container}>
      {Object.keys(product || {}).length ? (
        <div className={styles.searchContainer}>
          <input
            ref={compareSearchInputRef}
            placeholder={langData.header.searchPlaceholder[currentLanguage]}
            className={styles.searchInput}
            onFocus={(e) => {
              if (e.target.value.length && compareProducts.length < 2) {
                dispatch(
                  getSearchInfo(
                    e.target.value,
                    requestToken,
                    setRequestToken,
                    1
                  )
                );
              }
            }}
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              if (e.target.value.length && compareProducts.length < 2) {
                dispatch(
                  getSearchInfo(
                    e.target.value,
                    requestToken,
                    setRequestToken,
                    1
                  )
                );
              } else {
                dispatch(setCompareSearch(null));
              }
            }}
          />
          <span className={styles.searchBtn}>
            {langData.buttons.search[currentLanguage]}
          </span>

          <ComapreSearchModal
            setCompareProducts={(e) => {
              setCompareProducts(e);
              let comparenTitlesArray = Array.from(comparenTitles);

              e.map(({ product }, i) => {
                product?.variations?.map((variant, index) => {
                  const id = comparenTitlesArray.findIndex((item) => {
                    return item?.key?.name_en === variant?.attribute?.name_en;
                  });

                  if (id > 0) {
                    if (
                      Object.keys(variant?.attribute_value?.color || {}).length
                    ) {
                      comparenTitlesArray[id].value[i + 1] = {
                        value_en: variant?.attribute_value?.color.name_en,
                        value_ru: variant?.attribute_value?.color.name_ru,
                        value_hy: variant?.attribute_value?.color.name_hy,
                      };
                    } else {
                      comparenTitlesArray[id].value[i + 1] = {
                        value_en: variant?.attribute_value?.value_en,
                        value_ru: variant?.attribute_value?.value_ru,
                        value_hy: variant?.attribute_value?.value_hy,
                      };
                    }
                  }
                });
              });
              setComparenTitles(comparenTitlesArray);
            }}
            compareProducts={compareProducts}
          />
        </div>
      ) : (
        <LoadContent
          containerStyle={{
            height: "48.5px",
          }}
        />
      )}

      <div className={styles.compareProductsBlock}>
        {Object.keys(product || {}).length ? (
          <>
            <ComparedProduct product={product} />
            {compareProducts.map((product, index) => {
              return (
                <ComparedProduct
                  product={product}
                  key={index}
                  removeCompare={(id) => {
                    let comparenTitlesArray = comparenTitles?.map((item, i) => {
                      if (index === 0) {
                        return {
                          key: item.key,
                          value: [item?.value[0], item?.value[2], ""],
                        };
                      }
                      return {
                        key: item.key,
                        value: [item?.value[0], item?.value[1], ""],
                      };
                    });
                    setComparenTitles(comparenTitlesArray);
                    setCompareProducts((state) =>
                      state.filter((e) => e.id !== id)
                    );
                  }}
                />
              );
            })}
            {compareProducts.length < 2 ? (
              <div
                className={styles.plusProductCompare}
                onClick={() => {
                  compareSearchInputRef.current.focus();
                }}>
                <div>
                  <svg
                    width="27"
                    height="27"
                    viewBox="0 0 27 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M1 13.5H13.5M13.5 13.5H26M13.5 13.5V1M13.5 13.5V26"
                      stroke="#E31335"
                      strokeWidth="2"
                      strokeLinecap="round"></path>
                  </svg>
                </div>
              </div>
            ) : null}
          </>
        ) : (
          <>
            <LoadContent containerClassName={styles.productCartLoader} />
            <LoadContent containerClassName={styles.productCartLoader} />
            {window.innerWidth > 810 && (
              <LoadContent containerClassName={styles.productCartLoader} />
            )}
          </>
        )}
      </div>
      <div className={styles.comparenProductsInfo}>
        {Object.keys(product || {}).length
          ? comparenTitles.map((item, index) => (
              <Fragment key={index}>
                <span className={styles.comparedItemTitle}>
                  {item?.key?.["name_" + currentLanguage]}
                </span>
                <div className={styles.comparedItemValue}>
                  {item.value.map((value, index) => (
                    <span key={index}>
                      {value?.["value_" + currentLanguage]}
                    </span>
                  ))}
                </div>
              </Fragment>
            ))
          : Array.from({ length: 15 }).map((_, index) => (
              <Fragment key={index}>
                <LoadContent containerStyle={{ height: "21px" }} />
                <LoadContent containerStyle={{ height: "52.5px" }} />
              </Fragment>
            ))}
      </div>
    </div>
  );
}
