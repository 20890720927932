import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import FromToPage from "../../../components/FromToPage";
import lang from "../../../lang/lang";
import { useDispatch, useSelector } from "react-redux";
import UserMenu from "../../../components/Header/components/UserMenu";
import langData from "../../../lang/data";
import BackRoute from "../components/BackRoute";
import MyModal from "../../../components/MyModal";
import UserEditInfo from "../components/UserEditInfo";
import { getProfilePageData } from "../../../store/MainSlice";

const PersonalInformation = () => {
  const { currentLanguage, profilePageInfo } = useSelector(({ main }) => main);
  const [discounted, setDiscounted] = useState(false);
  const [editUserIfo, setEditUserInfo] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [repeatPasswordVisible, setRepeatPasswordVisible] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProfilePageData());
  }, [dispatch]);

  const [personPassword, setPersonPassword] = useState({
    password: "",
    confirmPassword: "",
  });
  const [errorText, setErrorText] = useState({
    password: "",
    confirmPassword: "",
  });
  const checkDiscounted = () => {
    setDiscounted(!discounted);
  };
  const editUserHandler = () => {
    setEditUserInfo(!editUserIfo);
  };
  const togglePasswordVisibility = () => {
    setPasswordVisible((prev) => !prev);
  };
  const toggleRepeatPasswordVisibility = () => {
    setRepeatPasswordVisible((prev) => !prev);
  };

  const handleChange = (e) => {
    setPersonPassword({
      ...personPassword,
      [e.target.name]: e.target.value,
    });
    setErrorText({ ...errorText, [e.target.name]: "" });
  };

  const validation = () => {
    let validate = true;
    let errorString = {
      password: "",
      confirmPassword: "",
    };
    if (!personPassword.password.trim().length) {
      validate = false;
      errorString.password = "Fill in the required password";
    }
    if (!personPassword.confirmPassword.trim().length) {
      validate = false;
      errorString.confirmPassword = "Fill in the required confirmPassword";
    }
    setErrorText(errorString);
    return validate;
  };

  const handleClick = (e) => {
    e.preventDefault();
    if (validation()) {
    }
  };
  return (
    <div className={style.user_page}>
      <div className="wrapper">
        <FromToPage
          pages={[
            {
              label: lang[currentLanguage].Personal_information,
              value: "/personal/Information",
              active: true,
            },
          ]}
        />

        <div className={style.user_page_body}>
          <UserMenu containerStyle={true} />

          <div className={style.for_user_person}>
            <div className={style.for_user_person_title}>
              <BackRoute />
              <span className={style.user_second_title}>
                {lang[currentLanguage].personal_info}
              </span>
            </div>

            <div
              className={
                style.already_created_info +
                " " +
                style.already_created_info_first
              }>
              <div className={style.person_info_}>
                <div className={style.person_img}>
                  <div className={style.user_Image}>
                    <img
                      src="https://vlv.am/public/frontend/default/img/avatar.jpg"
                      alt="img"
                    />
                  </div>
                </div>
                <div className={style.inf_person_about}>
                  <span className={style.name_person_inf}>
                    {profilePageInfo?.personal_information?.name}{" "}
                  </span>
                  <div className={style.phone_mail}>
                    <div className={style.chosen_phone_mail_user}>
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M11.9852 9.0505L11.4223 11.4148C11.352 11.7659 11.0705 12 10.7187 12C4.80816 11.9766 0 7.17781 0 1.27881C0 0.927682 0.21109 0.646777 0.562907 0.576551L2.93181 0.0147421C3.26017 -0.0554841 3.61199 0.131786 3.75271 0.436099L4.85507 2.98765C4.97235 3.29196 4.90198 3.64309 4.64398 3.83036L3.37744 4.86034C4.17489 6.47554 5.48834 7.78643 7.13016 8.58233L8.16215 7.31826C8.34979 7.08417 8.7016 6.99054 9.00651 7.10758L11.563 8.20779C11.868 8.37165 12.0556 8.72278 11.9852 9.0505Z"
                          fill="#E31335"></path>
                      </svg>

                      {profilePageInfo?.personal_information?.phone}
                    </div>

                    <div className={style.chosen_phone_mail_user}>
                      <svg
                        width="16"
                        height="12"
                        viewBox="0 0 16 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9.1875 8.625C8.46875 9.15625 7.5 9.15625 6.78125 8.625L0 3.5V10C0 11.125 0.875 12 2 12H14C15.0938 12 16 11.125 16 10V3.5L9.1875 8.625Z"
                          fill="#282828"
                          fillOpacity="0.4"></path>
                        <path
                          d="M14.5 0C15.3125 0 16 0.6875 16 1.5C16 2 15.75 2.4375 15.375 2.71875L8.59375 7.8125C8.21875 8.09375 7.75 8.09375 7.375 7.8125L0.59375 2.71875C0.21875 2.4375 0 2 0 1.5C0 0.6875 0.65625 0 1.5 0H14.5Z"
                          fill="#E31335"></path>
                      </svg>
                      {profilePageInfo?.personal_information?.email}
                    </div>
                  </div>
                </div>
              </div>

              <div
                onClick={editUserHandler}
                className={style.edit_personal_inf}>
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10.2795 0.57874C11.0512 -0.192913 12.2913 -0.192913 13.063 0.57874L13.4213 0.937008C14.1929 1.70866 14.1929 2.94882 13.4213 3.72047L7.96457 9.17717C7.74409 9.39764 7.44094 9.59055 7.11024 9.67323L4.35433 10.4724C4.13386 10.5276 3.88583 10.4724 3.72047 10.2795C3.52756 10.1142 3.47244 9.86614 3.52756 9.64567L4.32677 6.88976C4.40945 6.55906 4.60236 6.25591 4.82283 6.03543L10.2795 0.57874ZM12.126 1.51575C11.878 1.26772 11.4646 1.26772 11.2165 1.51575L10.3898 2.31496L11.685 3.61024L12.4843 2.78346C12.7323 2.53543 12.7323 2.12205 12.4843 1.87402L12.126 1.51575ZM5.59449 7.24803L5.12598 8.87402L6.75197 8.40551C6.8622 8.37795 6.94488 8.32283 7.02756 8.24016L10.748 4.51968L9.48032 3.25197L5.75984 6.97244C5.67717 7.05512 5.62205 7.1378 5.59449 7.24803ZM5.51181 1.65354C5.87008 1.65354 6.17323 1.95669 6.17323 2.31496C6.17323 2.70079 5.87008 2.97638 5.51181 2.97638H2.4252C1.79134 2.97638 1.32283 3.47244 1.32283 4.07874V11.5748C1.32283 12.2087 1.79134 12.6772 2.4252 12.6772H9.92126C10.5276 12.6772 11.0236 12.2087 11.0236 11.5748V8.48819C11.0236 8.12992 11.2992 7.82677 11.685 7.82677C12.0433 7.82677 12.3465 8.12992 12.3465 8.48819V11.5748C12.3465 12.9252 11.2441 14 9.92126 14H2.4252C1.0748 14 0 12.9252 0 11.5748V4.07874C0 2.75591 1.0748 1.65354 2.4252 1.65354H5.51181Z"
                    fill="#282828"></path>
                </svg>
              </div>
            </div>

            <span className={style.user_title}>
              {lang[currentLanguage].change_password}
            </span>

            <form className={style.already_created_info}>
              <div className={style.password_area}>
                <input
                  name={"password"}
                  onChange={handleChange}
                  className={` ${errorText.password && style.inputError}`}
                  value={personPassword.password}
                  placeholder={
                    langData.loginModal.password[currentLanguage] + "*"
                  }
                  type={passwordVisible ? "text" : "password"}
                />

                <span
                  className={style.eye}
                  onClick={togglePasswordVisibility}>
                  {passwordVisible ? (
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12 8.5C11.6562 8.53125 11.3125 8.5625 11 8.65625C11.1562 8.90625 11.2188 9.21875 11.25 9.5C11.25 10.4688 10.4375 11.25 9.5 11.25C9.1875 11.25 8.875 11.1875 8.65625 11.0312C8.5625 11.3438 8.5 11.6562 8.5 12C8.5 13.9375 10.0625 15.5 12 15.5C13.9375 15.5 15.5 13.9375 15.5 12C15.5 10.0938 13.9375 8.53125 12 8.53125V8.5ZM20.875 11.5625C19.1875 8.25 15.8125 6 12 6C8.15625 6 4.78125 8.25 3.09375 11.5625C3.03125 11.6875 3 11.8438 3 12C3 12.1875 3.03125 12.3438 3.09375 12.4688C4.78125 15.7812 8.15625 18 12 18C15.8125 18 19.1875 15.7812 20.875 12.4688C20.9375 12.3438 20.9688 12.1875 20.9688 12.0312C20.9688 11.8438 20.9375 11.6875 20.875 11.5625ZM12 16.5C8.90625 16.5 6.0625 14.7812 4.5625 12C6.0625 9.21875 8.90625 7.5 12 7.5C15.0625 7.5 17.9062 9.21875 19.4062 12C17.9062 14.7812 15.0625 16.5 12 16.5Z"
                        fill="#282828"
                        fillOpacity="0.5"></path>
                    </svg>
                  ) : (
                    <svg
                      width="18"
                      height="15"
                      viewBox="0 0 18 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M9 3.62499C11.07 3.62499 12.75 5.30499 12.75 7.37499C12.75 7.75749 12.675 8.12499 12.57 8.46999L14.865 10.765C15.9075 9.84249 16.7325 8.68749 17.25 7.36749C15.9525 4.08249 12.75 1.74999 9 1.74999C8.0475 1.74999 7.1325 1.89999 6.27 2.17749L7.8975 3.80499C8.25 3.69999 8.6175 3.62499 9 3.62499ZM2.0325 1.11999C1.74 1.41249 1.74 1.88499 2.0325 2.17749L3.51 3.65499C2.295 4.62249 1.3275 5.89749 0.75 7.37499C2.0475 10.6675 5.25 13 9 13C10.14 13 11.2275 12.775 12.2325 12.385L14.2725 14.425C14.565 14.7175 15.0375 14.7175 15.33 14.425C15.6225 14.1325 15.6225 13.66 15.33 13.3675L3.0975 1.11999C2.805 0.827495 2.325 0.827495 2.0325 1.11999ZM9 11.125C6.93 11.125 5.25 9.44499 5.25 7.37499C5.25 6.79749 5.385 6.24999 5.6175 5.76999L6.795 6.94749C6.7725 7.08249 6.75 7.22499 6.75 7.37499C6.75 8.61999 7.755 9.62499 9 9.62499C9.15 9.62499 9.285 9.60249 9.4275 9.57249L10.605 10.75C10.1175 10.99 9.5775 11.125 9 11.125ZM11.2275 7.12749C11.115 6.07749 10.29 5.25999 9.2475 5.14749L11.2275 7.12749Z"
                        fill="#717171"
                        fillOpacity="0.2"></path>
                    </svg>
                  )}
                </span>
              </div>

              <div className={style.password_area}>
                <input
                  name={"confirmPassword"}
                  onChange={handleChange}
                  value={personPassword.confirmPassword}
                  type={repeatPasswordVisible ? "text" : "password"}
                  className={` ${
                    errorText.confirmPassword && style.inputError
                  }`}
                  placeholder={lang[currentLanguage].repeat_password + "*"}
                />

                <span
                  className={style.eye}
                  onClick={toggleRepeatPasswordVisibility}>
                  {repeatPasswordVisible ? (
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12 8.5C11.6562 8.53125 11.3125 8.5625 11 8.65625C11.1562 8.90625 11.2188 9.21875 11.25 9.5C11.25 10.4688 10.4375 11.25 9.5 11.25C9.1875 11.25 8.875 11.1875 8.65625 11.0312C8.5625 11.3438 8.5 11.6562 8.5 12C8.5 13.9375 10.0625 15.5 12 15.5C13.9375 15.5 15.5 13.9375 15.5 12C15.5 10.0938 13.9375 8.53125 12 8.53125V8.5ZM20.875 11.5625C19.1875 8.25 15.8125 6 12 6C8.15625 6 4.78125 8.25 3.09375 11.5625C3.03125 11.6875 3 11.8438 3 12C3 12.1875 3.03125 12.3438 3.09375 12.4688C4.78125 15.7812 8.15625 18 12 18C15.8125 18 19.1875 15.7812 20.875 12.4688C20.9375 12.3438 20.9688 12.1875 20.9688 12.0312C20.9688 11.8438 20.9375 11.6875 20.875 11.5625ZM12 16.5C8.90625 16.5 6.0625 14.7812 4.5625 12C6.0625 9.21875 8.90625 7.5 12 7.5C15.0625 7.5 17.9062 9.21875 19.4062 12C17.9062 14.7812 15.0625 16.5 12 16.5Z"
                        fill="#282828"
                        fillOpacity="0.5"></path>
                    </svg>
                  ) : (
                    <svg
                      width="18"
                      height="15"
                      viewBox="0 0 18 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M9 3.62499C11.07 3.62499 12.75 5.30499 12.75 7.37499C12.75 7.75749 12.675 8.12499 12.57 8.46999L14.865 10.765C15.9075 9.84249 16.7325 8.68749 17.25 7.36749C15.9525 4.08249 12.75 1.74999 9 1.74999C8.0475 1.74999 7.1325 1.89999 6.27 2.17749L7.8975 3.80499C8.25 3.69999 8.6175 3.62499 9 3.62499ZM2.0325 1.11999C1.74 1.41249 1.74 1.88499 2.0325 2.17749L3.51 3.65499C2.295 4.62249 1.3275 5.89749 0.75 7.37499C2.0475 10.6675 5.25 13 9 13C10.14 13 11.2275 12.775 12.2325 12.385L14.2725 14.425C14.565 14.7175 15.0375 14.7175 15.33 14.425C15.6225 14.1325 15.6225 13.66 15.33 13.3675L3.0975 1.11999C2.805 0.827495 2.325 0.827495 2.0325 1.11999ZM9 11.125C6.93 11.125 5.25 9.44499 5.25 7.37499C5.25 6.79749 5.385 6.24999 5.6175 5.76999L6.795 6.94749C6.7725 7.08249 6.75 7.22499 6.75 7.37499C6.75 8.61999 7.755 9.62499 9 9.62499C9.15 9.62499 9.285 9.60249 9.4275 9.57249L10.605 10.75C10.1175 10.99 9.5775 11.125 9 11.125ZM11.2275 7.12749C11.115 6.07749 10.29 5.25999 9.2475 5.14749L11.2275 7.12749Z"
                        fill="#717171"
                        fillOpacity="0.2"></path>
                    </svg>
                  )}
                </span>
              </div>

              <button
                onClick={handleClick}
                className={style.save_person_inf_btn}>
                {lang[currentLanguage].save_change}
              </button>
            </form>

            <span className={style.user_title}>
              {lang[currentLanguage].notification}
            </span>
            <div className={style.already_created_info}>
              <span className={style.cun_cum}>
                {lang[currentLanguage].notification_email}
              </span>

              <div
                onClick={checkDiscounted}
                className={
                  !discounted
                    ? style.check_discounted
                    : style.check_discounted_active
                }></div>
            </div>
          </div>
        </div>
      </div>
      {editUserIfo ? (
        <MyModal onClose={setEditUserInfo}>
          <UserEditInfo />
        </MyModal>
      ) : null}
    </div>
  );
};
export default PersonalInformation;
