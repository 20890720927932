import React from "react";

export default function PlusSvg() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.57137 17.6858V9.45727M9.57137 9.45727V1.2287M9.57137 9.45727H1.3428M9.57137 9.45727H17.7999"
        stroke="white"
        strokeWidth="2.05714"
        strokeLinecap="round"
      ></path>
    </svg>
  );
}
