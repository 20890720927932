import React, { useEffect, useRef, useState } from "react";
import styles from "./style.module.css";
import { useSelector } from "react-redux";
import PercentSvg from "./assets/PercentSvg";
import Timer from "./components/Timer";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Product from "./components/Product/Product";
import LoadContent from "../../../../components/LoadContent";
import SliderNextArrowSvg from "../../../../assets/SVG/SliderNextArrowSvg";
import { Autoplay } from "swiper/modules";
import langData from "../../../../lang/data";

export default function SpecialOffer() {
  const { specialOffer } = useSelector(({ home }) => home);
  const { currentLanguage } = useSelector(({ main }) => main);
  const [products, setProducts] = useState([]);
  const [loadVideo, setLoadVideo] = useState(false); //window.innerWidth < 768
  const [showBtns, setShowBtns] = useState(false);
  
  const containerRef = useRef();
  const videoRef = useRef();

  useEffect(() => {
    let productsArray1 = [];
    let productsArray2 = [];
    specialOffer?.products?.forEach((product, index) => {
      if (productsArray1.length === 2) {
        productsArray2.push(productsArray1);
        productsArray1 = [product];
      } else {
        productsArray1.push(product);
      }
      if (index + 1 === specialOffer?.products.length) {
        productsArray2.push(productsArray1);
      }
    });
    setProducts(productsArray2);
  }, [specialOffer?.products]);

  const scrollListnerFunc = () => {
    if (
      document.body.parentNode.scrollTop >=
        containerRef?.current?.offsetTop - window.innerHeight - 300 &&
      !loadVideo
    ) {
      setLoadVideo(true);
    }
    if (
      document.body.parentNode.scrollTop + window.innerHeight >=
        containerRef?.current?.offsetTop &&
      document.body.parentNode.scrollTop <=
        containerRef?.current?.offsetTop + window.innerHeight
    ) {
      if (videoRef?.current?.play) videoRef.current?.play();
    } else {
      if (videoRef?.current?.pause) videoRef.current?.pause();
    }
  };
  // useEffect(() => {
  //   // if (window.innerWidth > 768) {
  //   //   document.addEventListener("scroll", scrollListnerFunc);
  //   //   return () => {
  //   //     document.removeEventListener("scroll", scrollListnerFunc);
  //   //   };
  //   // }
  // }, [containerRef]);
  const swiperRef = useRef();

  const slidesPerView = window.innerWidth > 1280 ? 2 : "auto";

  if (specialOffer?.error) return null;
  return (
    <div className="wrapper" ref={containerRef}>
      <div className={styles.main}>
        {!specialOffer?.flashDeals?.video_link ? (
          <LoadContent containerClassName={styles.loadContentVideo} />
        ) : (
          <div className={styles.video}>
            <video
              ref={videoRef}
              autoPlay
              playsInline
              muted
              controls={false}
              type="video/mp4"
              onError={(error) => {
                console.warn("video error", error);
              }}
            >
              <source
                src={
                  process.env.REACT_APP_STORAGE_URL +
                  specialOffer?.flashDeals?.video_link
                }
                type="video/mp4"
              />
            </video>
          </div>
        )}
        <div className={styles.rightBlock}>
          {!specialOffer?.flashDeals?.start_date ? (
            <LoadContent containerClassName={styles.loadContentTimer} />
          ) : (
            <div className={styles.timeBlock}>
              <div className={styles.imgTitle}>
                <div className={styles.percent}>
                  <PercentSvg />
                </div>
                <div className={styles.titles}>
                  <span className={styles.title}>
                    {langData.SpecialOffer.title[currentLanguage]}
                  </span>
                  <span className={styles.startTime}>
                    {langData.SpecialOffer.sub_title[currentLanguage]}
                    {new Date(specialOffer?.flashDeals?.start_date)
                      .toLocaleDateString()
                      .split(".")
                      .join("/")}
                  </span>
                </div>
              </div>
              <Timer endDate={specialOffer?.flashDeals?.end_date} />
            </div>
          )}
          <div
            className={styles.swiperContainer}
            onMouseMove={() => window.innerWidth > 768 && setShowBtns(true)}
            onMouseOut={() => window.innerWidth > 768 && setShowBtns(false)}
          >
            {window.innerWidth > 768 && (
              <>
                <div
                  className={`${styles.slideBtn} ${styles.slidePrevArrow}`}
                  style={{
                    display: showBtns ? "flex" : "none",
                  }}
                  onClick={() => swiperRef.current?.slidePrev()}
                >
                  <SliderNextArrowSvg />
                </div>
                <div
                  className={`${styles.slideBtn} ${styles.slideNextArrow}`}
                  style={{
                    display: showBtns ? "flex" : "none",
                  }}
                  onClick={() => swiperRef.current?.slideNext()}
                >
                  <SliderNextArrowSvg />
                </div>
              </>
            )}
            <Swiper
              modules={[Autoplay]}
              loop
              autoplay
              onBeforeInit={(swiper) => {
                swiperRef.current = swiper;
              }}
              spaceBetween={10}
              slidesPerView={slidesPerView}
              className={styles.swiper}
            >
              {!products.length ? (
                <>
                  {Array.from({ length: 2 }).map((_, index) => (
                    <SwiperSlide key={index}>
                      <div className={styles.slidesColumn}>
                        <LoadContent
                          containerClassName={styles.loadContentProduct}
                        />
                        <LoadContent
                          containerClassName={styles.loadContentProduct}
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </>
              ) : (
                [...products, ...products]?.map((productsArray, index) => {
                  return (
                    <SwiperSlide key={index} style={{ width: "auto" }}>
                      <div className={styles.slidesColumn}>
                        <Product product={productsArray[0]} />
                        {productsArray[1] && (
                          <Product product={productsArray[1]} />
                        )}
                      </div>
                    </SwiperSlide>
                  );
                })
              )}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
}
