import {createSlice} from "@reduxjs/toolkit";
import {getSeeAllBrands} from "./brandSeeAllFetch";

const initialState = {
    isLoading:false,
    error:'',
    brands: [],

}
const BrandSeeAllSlice = createSlice({
    name: 'brands',
    initialState,
    reducers:{},
    extraReducers:{
        [getSeeAllBrands.pending.type]: (state) => {
            state.isLoading = true
        },
        [getSeeAllBrands.fulfilled.type]:(state, action)=>{
            state.isLoading = false
            state.error = ''
            state.brands = action.payload
        },
        [getSeeAllBrands.rejected.type]:(state, action)=>{
            state.isLoading = false
            state.error = action.payload
        },

    }
})
export const {} = BrandSeeAllSlice.actions

export default BrandSeeAllSlice.reducer
