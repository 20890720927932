import { createSlice } from '@reduxjs/toolkit';
import { bannerSliderThunk } from './thunk';
import { initialState } from './initialState';

export const sliderSlice = createSlice({
	name: 'product',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(bannerSliderThunk.pending, state => {
			state.loading = true;
		});
		builder.addCase(bannerSliderThunk.fulfilled, (state, action) => {
			state.bannerSliders = action.payload;
			state.loading = false;
		});
		builder.addCase(bannerSliderThunk.rejected, state => {
			state.loading = false;
		});
	},
});

export const {} = sliderSlice.actions;

export default sliderSlice.reducer;