import React, { useLayoutEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCompareProductAnimation } from "../../store/MainSlice";

export default function AddToCompareAnimation() {
  const { shopCartPositions, compareProductAnimation } = useSelector(
    ({ main }) => main
  );
  const imgRef = useRef();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    if (window.innerWidth < 993) {
      if (imgRef.current) {
        setTimeout(() => {
          imgRef.current.style.top = "calc(100vh - 120px)";
          imgRef.current.style.left = "calc(70vw - 82px)";
          imgRef.current.style.opacity = 0.5;
          imgRef.current.style.transform = "scale(0.5)";
          setTimeout(() => {
            dispatch(setCompareProductAnimation(null));
          }, 800);
        }, 0);
      }
    } else {
      if (imgRef.current && Object.keys(shopCartPositions || {}).length) {
        setTimeout(() => {
          imgRef.current.style.top = shopCartPositions?.top - 30 + "px";
          imgRef.current.style.left = shopCartPositions?.left - 180 + "px";
          imgRef.current.style.opacity = 0.5;
          imgRef.current.style.transform = "scale(0.5)";
          setTimeout(() => {
            dispatch(setCompareProductAnimation(null));
          }, 800);
        }, 0);
      }
    }
  }, [imgRef, shopCartPositions, compareProductAnimation, dispatch]);

  if (!Object.keys(compareProductAnimation || {}).length) return null;
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 999999,
        pointerEvents: "none",
      }}>
      <div
        ref={imgRef}
        style={{
          position: "absolute",
          top: compareProductAnimation?.position?.top,
          left: compareProductAnimation.position?.left,
          transition: "all 800ms ease-out",
          opacity: 1,
          transfrom: "scale(1)",
          width: "164px",
          height: "auto",
        }}>
        <img
          src={
            process.env.REACT_APP_STORAGE_URL + compareProductAnimation.image
          }
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
          }}
          alt={""}
        />
      </div>
    </div>
  );
}
