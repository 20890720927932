import {createSlice} from "@reduxjs/toolkit";
import {getFrequentQuestions} from "./frequentQuestionsFetch";

const initialState = {
    isLoading:false,
    error:'',
    questionsList: [],

}
const FrequentQuestionsSlice = createSlice({
    name: 'questionsList',
    initialState,
    reducers:{},
    extraReducers:{
        [getFrequentQuestions.pending.type]: (state) => {
            state.isLoading = true
        },
        [getFrequentQuestions.fulfilled.type]:(state, action)=>{
            state.isLoading = false
            state.error = ''
            state.questionsList = action.payload
        },
        [getFrequentQuestions.rejected.type]:(state, action)=>{
            state.isLoading = false
            state.error = action.payload
        },

    }
})
export const {} = FrequentQuestionsSlice.actions

export default FrequentQuestionsSlice.reducer
