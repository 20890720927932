import React from 'react';
import style from "./style.module.css";

const FavoritesProducts = () => {

    return  <div className={style.prod_img_plus}>
        <a href="https://vlv.am/Product/28851" className={style.dashboard_order_list }>
            <img  className={`${style.round_mini_img} ${style.mini_img1}`}
                           src="https://vlv.am/public/uploads/images/03-10-2023/651c139e78a41.webp"
                 alt="img"/>
        </a>
        <a href="https://vlv.am/Product/28556" className={style.dashboard_order_list}>
            <img className={`${style.round_mini_img} ${style.mini_img2}`}
                 src="https://vlv.am/public/uploads/images/15-09-2023/6503ee6a192b3.webp"
                 alt="img"/>
        </a>
        <a href="https://vlv.am/Product/28556" className={style.dashboard_order_list}>
            <img className={`${style.round_mini_img} ${style.mini_img3}`}
                 src="https://vlv.am/public/uploads/images/15-09-2023/6503ee6a192b3.webp"
                 alt="img"/>
        </a>
        <a href="https://vlv.am/Product/28556" className={style.dashboard_order_list}>
            <img className={`${style.round_mini_img} ${style.mini_img4}`}
                 src="https://vlv.am/public/uploads/images/15-09-2023/6503ee6a192b3.webp"
                 alt="img"/>
        </a>

        <span className={style.plus_num}>+3</span>
    </div>

};

export default FavoritesProducts;