import React, { Fragment, useRef } from "react";
import styles from "../style.module.css";
import SliderNextArrowSvg from "../../../../../assets/SVG/SliderNextArrowSvg";
import { Swiper, SwiperSlide } from "swiper/react";
import checkBase64 from "../../../../../helpers/checkBase64";
import { Autoplay } from "swiper/modules";
import { useSelector } from "react-redux";

export default function BrandsSwiper({ data }) {
  const currentLanguage = useSelector(({ main }) => main.currentLanguage);
  const swiperRef = useRef();
  return (
    <div className={styles.brandsContainer}>
      {data?.brands.length > 9 ? (
        <div
          className={`${styles.brandArrow} ${styles.arrowTop}`}
          onClick={() => swiperRef.current?.slidePrev()}
        >
          <SliderNextArrowSvg />
        </div>
      ) : null}

      <Swiper
        modules={[Autoplay]}
        slidesPerView={"auto"}
        loop={true}
        autoplay={{ delay: 3000 }}
        grabCursor={true}
        direction="vertical"
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
      >
        {data?.brands.map((brand, index) => {
          if (brand?.logo)
            return (
              <SwiperSlide className={styles.brandItem} key={index}>
                <a
                  href={`https://vlv.am/category/${data?.item?.slug}?item=category&b=${brand?.id}&t=1&lang=${currentLanguage}`}
                >
                  <img
                    src={
                      checkBase64(brand?.logo)
                        ? "data:image/png;base64," + brand?.logo
                        : process.env.REACT_APP_STORAGE_URL + brand?.logo
                    }
                    className={styles.brandImg}
                  />
                </a>
              </SwiperSlide>
            );
        })}
      </Swiper>

      {data?.brands.length > 9 ? (
        <div
          className={`${styles.brandArrow} ${styles.arrowBottom}`}
          onClick={() => swiperRef.current?.slideNext()}
        >
          <SliderNextArrowSvg />
        </div>
      ) : null}
    </div>
  );
}
