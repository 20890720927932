import React from 'react';
import {NavLink} from "react-router-dom";
import HomeSvg from "../../../../assets/SVG/homeSvg";
import LineSvg from "../../../../assets/SVG/LineSvg";
import style from './style.module.css'
const CompareNull = () => {
    return (
            <div className={style.compare_null_block}>
                <div className={style.compare_null_content}>
                    <div className={style.empty_compare_img}>
                        <img src="https://vlv.am/public/img/empty_compare.svg" alt="img"/>
                    </div>
                    <h1>Համեմատության ցանկը դատարկ է</h1>

                </div>
            </div>
    );
};

export default CompareNull;