import React from "react";

export default function CloseSvg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      {...props}
    >
      <rect
        y="11.6316"
        width="16.4495"
        height="1.93524"
        transform="rotate(-45 0 11.6316)"
        fill="#282828"
      ></rect>
      <rect
        x="1.36914"
        width="16.4495"
        height="1.93524"
        transform="rotate(45 1.36914 0)"
        fill="#282828"
      ></rect>
    </svg>
  );
}
