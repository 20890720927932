import * as React from "react";

const StoryCross = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none">
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2.5}
        d="m27.75 9.75-18.5 18.5M9.25 9.75l18.5 18.5"
      />
    </svg>
  );
};

export default StoryCross;
