import React, { useEffect, useRef, useState } from "react";
import styles from "./style.module.css";
import { useDispatch, useSelector } from "react-redux";
import HeaderSortBlock from "./components/HeaderSortBlock";
import ProductCard from "./components/ProductCard";
import {
  setCurrentPage,
  setRecordsPerPage,
} from "../../../../store/SearchPageSlice";
import Pageination from "../../../../components/Pageination";
import lang from "../../../../lang/lang";
import UseOutSideClick from "../../../../helpers/UseOutSideClick";
let sortedType = "";
export default function ProductsList() {
  const [showPageRecordsDragable, setShowPageRecordsDragable] = useState(false);
  const [nPages, setNPages] = useState(1);
  const [sortedProducts, setSortedProducts] = useState([]);

  const {
    searchPageProducts,
    sortType,
    listType,
    currentPage,
    recordsPerPage,
  } = useSelector(({ searchPage }) => searchPage);
  const currentLanguage = useSelector(({ main }) => main.currentLanguage);
  const mainRef = useRef();
  const pageRecordsDragableRef = useRef();
  const dispatch = useDispatch();

  UseOutSideClick(pageRecordsDragableRef, () =>
    setShowPageRecordsDragable(false)
  );

  useEffect(() => {
    if (searchPageProducts?.length) {
      let productsArray = Array.from(searchPageProducts);
      switch (sortType?.value) {
        case "high_to_low":
          productsArray?.sort(function (a, b) {
            const aPrice =
              (a.product.online_price && a.product.online_selling_price) ||
              a.promo_price ||
              a.recommended_retail_price ||
              a.skus[0].selling_price;
            const bPrice =
              (b.product.online_price && b.product.online_selling_price) ||
              b.promo_price ||
              b.recommended_retail_price ||
              b.skus[0].selling_price;

            return bPrice - aPrice;
          });
          break;
        case "low_to_high":
          productsArray?.sort(function (a, b) {
            const aPrice =
              (a.product.online_price && a.product.online_selling_price) ||
              a.promo_price ||
              a.recommended_retail_price ||
              a.skus[0].selling_price;
            const bPrice =
              (b.product.online_price && b.product.online_selling_price) ||
              b.promo_price ||
              b.recommended_retail_price ||
              b.skus[0].selling_price;

            return aPrice - bPrice;
          });
          break;
        case "alpha_asc":
          productsArray?.sort(function (a, b) {
            return a?.product?.product_name?.localeCompare(
              b?.product?.product_name
            );
          });
          break;
        case "alpha_desc":
          productsArray?.sort(function (a, b) {
            return b?.product?.product_name?.localeCompare(
              a?.product?.product_name
            );
          });
          break;
        case "old":
          productsArray?.sort(function (a, b) {
            return (
              new Date(a.product.created_at) - new Date(b.product.created_at)
            );
          });
          break;
        default:
          productsArray?.sort(function (a, b) {
            return (
              new Date(b.product.created_at) - new Date(a.product.created_at)
            );
          });
          break;
      }
      sortedType = sortType?.value;
      setSortedProducts(productsArray);
    }
  }, [sortType, searchPageProducts]);

  const updatedSearchParams = new URLSearchParams(window.location.search);
  const currentPageFromURL = updatedSearchParams.get("page");
  useEffect(() => {
    if (currentPageFromURL != null) {
      window.scrollTo(0, mainRef.current?.offsetTop);
    }
  }, [currentPage]);

  useEffect(() => {
    let pagesLength = Math.ceil(
      searchPageProducts && searchPageProducts?.length / recordsPerPage
    );
    setNPages(pagesLength);

    if (
      Math.ceil(
        searchPageProducts && searchPageProducts?.length / recordsPerPage
      ) < currentPage
    ) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set(
        "page",
        Math.ceil(
          searchPageProducts && searchPageProducts?.length / recordsPerPage
        ) - 1 || 1
      );
      const updatedUrl = `${window.location.pathname}?${searchParams
        .toString()
        .replace("=", "", 1)}}`;

      window.history.replaceState(null, "", updatedUrl);
      dispatch(
        setCurrentPage(
          Math.ceil(
            searchPageProducts && searchPageProducts?.length / recordsPerPage
          ) - 1 || 1
        )
      );
    }
  }, [searchPageProducts, recordsPerPage]);

  if (!searchPageProducts?.length) {
    return (
      <div className={styles.search_null_block_body}>
        <div className={styles.search_null_block_text}>
          <h1>{lang[currentLanguage].not_vos_query}</h1>
        </div>

        <div className={styles.search_null_block_image}>
          <img src={require("../../../../assets/notfound.png")} />
        </div>
      </div>
    );
  }

  return (
    <div ref={mainRef}>
      <HeaderSortBlock />
      <div
        className={
          listType == "grid"
            ? styles.productsList
            : styles.productsListFullWidth
        }
      >
        {!!sortedProducts?.length &&
          sortedType &&
          sortedProducts?.map((product, index) => {
            let start =
              currentPage > 1 ? (currentPage - 1) * recordsPerPage : 0;
            let end = start + recordsPerPage;
            if (index < start || index >= end) return null;

            return (
              <ProductCard
                key={index}
                product={product}
                flexContainer={listType == "grid"}
                fullWidth={listType == "list"}
              />
            );
          })}
      </div>
      <div className={styles.footer}>
        {recordsPerPage + 10 < searchPageProducts?.length && (
          <div
            className={styles.moreBtn}
            onClick={() => {
              const searchParams = new URLSearchParams(window.location.search);
              searchParams.set("paginate", recordsPerPage + 10);
              const updatedUrl = `${window.location.pathname}?${searchParams
                .toString()
                .replace("=", "", 1)}}`;
              window.history.replaceState(null, "", updatedUrl);
              dispatch(setRecordsPerPage(recordsPerPage + 10));
            }}
          >
            <span>{lang[currentLanguage].load_more}</span>
          </div>
        )}

        {searchPageProducts?.length > recordsPerPage && (
          <>
            <Pageination
              nPages={nPages}
              currentPage={currentPage}
              setCurrentPage={(e) => {
                const searchParams = new URLSearchParams(
                  window.location.search
                );

                searchParams.set("page", e);
                const updatedUrl = `${window.location.pathname}?${searchParams
                  .toString()
                  .replace("=", "", 1)}`;
                window.history.replaceState(null, "", updatedUrl);
                dispatch(setCurrentPage(e));
              }}
            />
            <div className={styles.recordsPerPageContainer}>
              <span className={styles.recordsPerPageTitle}>
                {lang?.[currentLanguage]?.show} :
              </span>
              <div className={styles.pageRecordsCountContainer}>
                <span
                  className={styles.pageRecordsCount}
                  onClick={() =>
                    setShowPageRecordsDragable(!showPageRecordsDragable)
                  }
                >
                  {recordsPerPage}
                  <svg
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.0293 5L4.99991 0.97083L9.02908 4.94145"
                      stroke="#282828"
                    ></path>
                  </svg>
                </span>
                {showPageRecordsDragable && (
                  <div
                    ref={pageRecordsDragableRef}
                    className={styles.pageRecordsDragable}
                  >
                    {[20, 30, 40, 50, 60].map((item) => (
                      <span
                        key={item}
                        onClick={() => {
                          const searchParams = new URLSearchParams(
                            window.location.search
                          );
                          searchParams.set("paginate", item);
                          const updatedUrl = `${
                            window.location.pathname
                          }?${searchParams.toString().replace("=", "", 1)}}`;

                          window.history.replaceState(null, "", updatedUrl);
                          dispatch(setRecordsPerPage(item));
                          setShowPageRecordsDragable(false);
                        }}
                        style={
                          recordsPerPage == item ? { color: "#282828" } : null
                        }
                      >
                        {item}
                      </span>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
