import React, { useState } from "react";
import styles from "../style.module.css";
import EyeSvg from "../../../assets/SVG/EyeSvg";
import CloseEyeSvg from "../../../assets/SVG/CloseEyeSvg";
import { useDispatch, useSelector } from "react-redux";
import { UserLogin } from "../../../store/MainSlice";
import langData from "../../../lang/data";
import isValidEmail from "helpers/checkEmail";

export default function Login({ setShowLogin, setForgotPassword }) {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const currentLanguage = useSelector(({ main }) => main.currentLanguage);

  const dispatch = useDispatch();
  const handleSubmit = () => {
    setLoginError(!login || !isValidEmail(login));
    setPasswordError(!password);

    if (login && isValidEmail(login) && password){
      dispatch(UserLogin({ login, password })); 
    } 
        
  };
  return (
    <>
      <span className={styles.subTitle}>
        {langData.loginModal.enterLoginAndPassword[currentLanguage]}
      </span>
      <input
        type="email"
        placeholder={langData.loginModal.email[currentLanguage]}
        className={`${styles.input} ${loginError && styles.inputError}`}
        value={login}
        onChange={(e) => setLogin(e.target.value)}
      />
      <div className={styles.passwordContainer}>
        <input
          type={showPass ? "text" : "password"}
          placeholder={langData.loginModal.password[currentLanguage]}
          className={`${styles.input} ${passwordError && styles.inputError}`}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <span className={styles.eye} onClick={() => setShowPass(!showPass)}>
          {!showPass ? <CloseEyeSvg /> : <EyeSvg />}
        </span>
      </div>
      <span
        className={styles.forgotPassword}
        onClick={() => setForgotPassword(true)}
      >
        {langData.loginModal.forgotPassword[currentLanguage]}
      </span>
      <div
        className={`${styles.btn} ${styles.btn_hover}`}
        onClick={handleSubmit}
      >
        <span>{langData.loginModal.login[currentLanguage]}</span>
      </div>
      <div className={styles.line} />
      <div
        className={`${styles.btn} ${styles.darkBtn}`}
        onClick={() => setShowLogin(false)}
      >
        <span>{langData.loginModal.signup[currentLanguage]}</span>
      </div>
    </>
  );
}
