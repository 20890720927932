import * as React from "react";
const StoryBuyNow = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    fill="none">
    <g
      fill="#fff"
      clipPath="url(#a)">
      <path d="M20.876 4.48a2.653 2.653 0 0 0-2.04-.955H4.508l-.037-.31A2.658 2.658 0 0 0 1.832.866h-.196a.886.886 0 0 0 0 1.772h.197a.886.886 0 0 1 .88.782L3.932 13.79a4.43 4.43 0 0 0 4.4 3.914h9.254a.886.886 0 0 0 0-1.772H8.332a2.659 2.659 0 0 1-2.498-1.773h10.562a4.43 4.43 0 0 0 4.36-3.644l.696-3.858a2.654 2.654 0 0 0-.576-2.177ZM19.713 6.34l-.697 3.858a2.659 2.659 0 0 1-2.62 2.187H5.552l-.834-7.089h14.117a.886.886 0 0 1 .878 1.044ZM6.953 22.133a1.772 1.772 0 1 0 0-3.544 1.772 1.772 0 0 0 0 3.544ZM15.814 22.133a1.772 1.772 0 1 0 0-3.544 1.772 1.772 0 0 0 0 3.544Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path
          fill="#fff"
          d="M.75.867h21.267v21.267H.75z"
        />
      </clipPath>
    </defs>
  </svg>
);
export default StoryBuyNow;
