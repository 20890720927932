import React from "react";
import langData from "../lang/data";
export default function UseGetDate(propsDate, currentLanguage = "hy") {
  let date = new Date(propsDate);

  return (
    <>
      {`${date.getDate()} ${
        langData?.months?.[date.getMonth() + 1]?.[currentLanguage]
      } ${date.getFullYear()}`}
    </>
  );
}
