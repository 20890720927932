import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import { useSelector } from "react-redux";
import DynamicHTMLComponent from "../../../../components/DynamicHTMLComponent";
import axios from "axios";
import ModalJobs from "../ModalJobs";
import ModalSuccess from "../ModalSuccess";
import ModalError from "../ModalError";

const JobsItem = ({ openSubList, index, activeIndex, item }) => {
  const isOpen = index === activeIndex;
  const { currentLanguage } = useSelector(({ main }) => main);
  const [userCv, setUserCv] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);
  const [errorSendBtnStyles, setErrorSendBtnStyles] = useState(false);
  const [buttonText, setButtonText] = useState("");
  const handleChange = (event) => {
    const selectedFile = event.target.files[0];
    setUserCv(selectedFile);
    setErrorSendBtnStyles(false);
  };

  useEffect(() => {
    if (userCv === null) {
      setButtonText(`Կցել ինքնակենսագրական`);
    }
    if (userCv) {
      setButtonText(userCv.name);
    }
  }, [userCv, isOpen, index]);

  const postData = async (e, index) => {
    e.preventDefault();
    let formData = new FormData();

    try {
      const _token = document.querySelector('meta[name="_token"]')?.content;
      formData.append("login", "VlvShopA");
      formData.append("password", "oL0rG6vQ6k");
      formData.append("token", "quHRhfW9R7SbNFUCEBEBmzCRbIa5Z3");
      formData.append("job_id", item.id);
      formData.append("file", userCv);

      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL + "api/job/send-cv",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-CSRF-Token": _token, // Add CSRF token to headers
          },
        }
      );

      if (response?.data?.success === true) {
        setButtonText("Կցել ինքնակենսագրական");
        setOpenModal(true);
        return;
      }
      if (response?.data?.error === 1) {
        setErrorSendBtnStyles(true);
        setOpenModalError(true);
        return;
      }
    } catch (error) {
      setOpenModalError(true);
      setErrorSendBtnStyles(true);
    }
  };

  return (
    <>
      <div
        className={
          !isOpen ? style.job_accordion_item : style.job_accordion_item_active
        }>
        <div
          onClick={() => openSubList(index)}
          className={style.job_accordion_item_title}>
          <span>{item["name_" + currentLanguage]}</span>

          {!isOpen ? (
            <span className={style.job_accordion_plus}>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9 0V9M9 9V18M9 9H18M9 9H0"
                  stroke="#282828"
                  strokeWidth="3"></path>
              </svg>
            </span>
          ) : (
            <span className={style.job_accordion_minus}>
              <svg
                width="18"
                height="4"
                viewBox="0 0 18 4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M18 2H9H0"
                  stroke="#282828"
                  strokeWidth="3"></path>
              </svg>
            </span>
          )}
        </div>
        <div className={style.accordion_sub_hidden_list}>
          <DynamicHTMLComponent itemHtml={item["job_" + currentLanguage]} />
          <form className={style.accordion_pdf_save}>
            <div className={style.accordion_files_block}>
              <label
                className={
                  errorSendBtnStyles
                    ? style.file_label_error + " " + style.file_label
                    : style.file_label
                }
                htmlFor={`pdf_${index + 1}`}
                id="changeImg">
                {buttonText}
              </label>
              <input
                onChange={handleChange}
                id={`pdf_${index + 1}`}
                type="file"
                accept="application/pdf,application/msword, application/vnd.ms-excel, .doc, .docx,"
                name="file"
              />
            </div>
            <button
              onClick={(e) => postData(e, index)}
              className={style.send_message_btn}>
              Ուղարկել
            </button>
          </form>
        </div>
      </div>
      {openModal ? (
        <ModalJobs onClose={setOpenModal}>
          <ModalSuccess onClose={setOpenModal} />
        </ModalJobs>
      ) : null}
      {openModalError ? (
        <ModalJobs onClose={setOpenModal}>
          <ModalError onClose={setOpenModalError} />
        </ModalJobs>
      ) : null}
    </>
  );
};

export default JobsItem;
