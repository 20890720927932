import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from "../../request";

export const productThunk = createAsyncThunk('colors', 
    async (params, thunkAPI) => {
	try {
        const response = await axiosInstance.post(`/product/associated-products/${params}`, {
            login: process.env.REACT_APP_LOGIN,
            password: process.env.REACT_APP_PASSWORD,
            token: process.env.REACT_APP_TOKEN,
        });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});