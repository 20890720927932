import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bannerSliderThunk } from 'store/sliders/thunk'
import BanerSlider from 'components/BanerSlider'
import LoadContent from 'components/LoadContent'
import style from './style.module.css'

const BanerSliders = () => {
    const dispatch = useDispatch()
    const { bannerSliders, loading } = useSelector(({sliders}) => sliders)

    useEffect(() => {
        dispatch(bannerSliderThunk())
    },[])
    
     if(!bannerSliders?.bannerSliders1?.length || !bannerSliders?.bannerSliders2?.length || !bannerSliders?.bannerSliders2?.length){
        return null
     } 
  return (
    <div className='wrapper'>
    <div className={style.bannerSliders}>
        <div className={style.bannerSlider1}>
            {loading ? <LoadContent containerClassName={style.loadContent} /> :
              <BanerSlider
               effect = "cube"
               pagination={false}
               container
            //   height="320px"
               bannerSliders={bannerSliders?.bannerSliders1}
                />
             }
            {/* <div className={style.shadow1}></div> */}
        </div>
        <div className={style.bannerSlider2}>
        {loading ? <LoadContent containerClassName={style.loadContent} /> :
               <BanerSlider
                effect = "cube"
                pagination={false}
                container
          //      height="320px"
                bannerSliders={bannerSliders?.bannerSliders2}
                 />
            }
            {/* <div className={style.shadow2}></div> */}
        </div>
        <div className={style.bannerSlider3}>
            {loading ? <LoadContent containerClassName={style.loadContent} /> :
               <BanerSlider
                effect = "cube"
                pagination={false}
                container
             //   height="320px"
                bannerSliders={bannerSliders?.bannerSliders3}
                 />
            }
             {/* <div className={style.shadow3}></div> */}
        </div>
    </div>
    </div>
  )
}

export default BanerSliders