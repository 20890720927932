import React, {useEffect, useState} from "react";
import style from "./style..module.css";
import FromToPage from "../../components/FromToPage";
import langData from "../../lang/data";
import {useSelector} from "react-redux";
const Payment = () => {
    const {currentLanguage} = useSelector(({main}) => main);

    return <div className="wrapper">
        <FromToPage pages={[{
            label: langData.footer.Information.menu.Payment[currentLanguage],
            value: "/payment",
            active: true
        }]}/>
        <div className={style.general_credit_banner}>
            <img
                alt="img"
                src={
                    currentLanguage === "hy" && require("../../assets/paymentAm.png") ||
                    currentLanguage === "en" && require("../../assets/paymentEn.png") ||
                    currentLanguage === "ru" && require("../../assets/paymentRu.png")
                }
            />
        </div>

        <div className={style.payment_block_list}>

            <div className={style.payments_block}>

                <div className={style.choose_payment_type}>
                    <div className={style.cart_img_types}>
                        <div className={style.cart_imgSvg2}>
                            <img data-src="https://vlv.am/public/payment_gateway/cache.png" alt="img"
                                 src="https://vlv.am/public/payment_gateway/cache.png"/>
                        </div>

                    </div>
                    <div className={style.nameofcart_type}>{langData.PaymentCardText.card1[currentLanguage]}</div>
                </div>

                <div className={style.choose_payment_type}>
                    <div className={style.cart_img_types}>
                        <div className={style.cart_imgSvg2}>
                            <img
                                data-src="https://vlv.am/public/payment_gateway/idram.png" alt="img"
                                src="https://vlv.am/public/payment_gateway/idram.png"/>
                        </div>

                    </div>
                    <div className={style.nameofcart_type}>{langData.PaymentCardText.card2[currentLanguage]}</div>
                </div>

                <div className={style.choose_payment_type}>
                    <div className={style.cart_img_types}>
                        <div className={style.cart_imgSvg2}>
                            <img
                                data-src="https://vlv.am/public/payment_gateway/telcell.png" alt="img"
                                src="https://vlv.am/public/payment_gateway/telcell.png"/>
                        </div>

                    </div>
                    <div className={style.nameofcart_type}>{langData.PaymentCardText.card3[currentLanguage]}</div>
                </div>

                <div className={style.choose_payment_type}>
                    <div className={style.cart_img_types}>
                        <div className={style.cart_imgSvg2}>
                            <img
                                data-src="https://vlv.am/public/img/express.svg" alt="img"
                                src="https://vlv.am/public/img/express.svg"/>
                        </div>

                    </div>
                    <div className={style.nameofcart_type}>{langData.PaymentCardText.card4[currentLanguage]}</div>
                </div>

                <div className={style.choose_payment_type}>
                    <div className={style.cart_img_types}>
                        <div className={style.cart_imgSvg2}>
                            <img
                                data-src="https://vlv.am/public/payment_gateway/online_aparic.png" alt="img"
                                src="https://vlv.am/public/payment_gateway/online_aparic.png"/>
                        </div>

                    </div>
                    <div className={style.nameofcart_type}>{langData.PaymentCardText.card5[currentLanguage]}</div>
                </div>

                <div className={style.choose_payment_type}>
                    <div className={style.cart_img_types}>
                        <div className={style.cart_imgSvg2}>
                            <img
                                data-src="https://vlv.am/public/payment_gateway/bank_cart.png" alt="img"
                                src="https://vlv.am/public/payment_gateway/bank_cart.png"/>
                        </div>

                    </div>
                    <div className={style.nameofcart_type}>{langData.PaymentCardText.card6[currentLanguage]}</div>
                </div>

                <div className={style.choose_payment_type}>
                    <div className={style.cart_img_types}>
                        <div className={style.cart_imgSvg2}>
                            <img
                                data-src="https://vlv.am/public/payment_gateway/invoice.png" alt="img"
                                src="https://vlv.am/public/payment_gateway/invoice.png"/>
                        </div>

                    </div>
                    <div className={style.nameofcart_type}>{langData.PaymentCardText.card7[currentLanguage]}</div>
                </div>

                <div className={style.choose_payment_type}>
                    <div className={style.cart_img_types}>
                        <div className={style.cart_imgSvg2}>
                            <img
                                data-src="https://vlv.am/public/payment_gateway/bonus.png" alt="img"
                                src="https://vlv.am/public/payment_gateway/bonus.png"/>
                        </div>

                    </div>
                    <div className={style.nameofcart_type}>{langData.PaymentCardText.card8[currentLanguage]}</div>
                </div>

                <div className={style.choose_payment_type}>
                    <div className={style.cart_img_types}>
                        <div className={style.cart_imgSvg2}>
                            <img
                                data-src="https://vlv.am/public/payment_gateway/easypay.png" alt="img"
                                src="https://vlv.am/public/payment_gateway/easypay.png"/>
                        </div>

                    </div>
                    <div className={style.nameofcart_type}>{langData.PaymentCardText.card9[currentLanguage]}
                    </div>
                </div>


            </div>
        </div>

    </div>
};

export default Payment;
