import * as React from "react";
const StoryPlay = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    fill="none">
    <path
      stroke="#FFF"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.376}
      d="M9 8.663c0-1.582 1.75-2.538 3.082-1.682l14.815 9.524a2 2 0 0 1 0 3.365l-14.815 9.524C10.75 30.25 9 29.294 9 27.712V8.663Z"
    />
  </svg>
);
export default StoryPlay;
