import React, { useEffect, useMemo, useState } from "react";
import FromToPage from "../../components/FromToPage";
import langData from "../../lang/data";
import { useDispatch, useSelector } from "react-redux";
import style from "./style..module.css";
import CheckedItem from "./Components/CheckedItem";
import QuestionsList from "./Components/QuestionsList";
import { getFrequentQuestions } from "../../store/frequentQuestions/frequentQuestionsFetch";
import LoadContent from "../../components/LoadContent";

const FrequentQuestions = () => {
  const { currentLanguage } = useSelector(({ main }) => main);
  const [search, setSearch] = useState("");
  const [checkedItem, setCheckedItem] = useState();

  const { isLoading, error, questionsList } = useSelector(
    (state) => state.FrequentQuestionsSlice
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFrequentQuestions());
  }, []);

  const handleClick = (item) => {
    setCheckedItem(item);
  };

  const filterQuestionsList = useMemo(() => {
    if (questionsList.length) {
      if (search) {
        return questionsList.filter((item) =>
          item?.["name_" + currentLanguage].toString().includes(search)
        );
      }
      return questionsList;
    }
    return [];
  }, [search, questionsList]);

  return (
    <div className="wrapper">
      <FromToPage
        pages={[
          {
            label: langData.titles.FrequentQuestions[currentLanguage],
            value: "",
            active: true,
          },
        ]}
      />

      <div className={style.questions_body}>
        <h1>{langData.titles.FrequentQuestions[currentLanguage]}</h1>

        <div className={style.questions_list_content}>
          {!isLoading && questionsList ? (
            <>
              <QuestionsList
                setSearch={setSearch}
                activeId={checkedItem}
                handleClick={handleClick}
                questionsList={filterQuestionsList}
              />

              <CheckedItem checkedItem={checkedItem} />
            </>
          ) : (
            <div className={style.questionsList_loader}>
              <LoadContent
                containerClassName={style.questionsList_loader_item}
              />
              <LoadContent
                containerClassName={style.questionsList_loader_item}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FrequentQuestions;
