import React from "react";
import style from "./style.module.css";
import styles from "../../style..module.css";
import DetailsTitle from "../../components/DetailsTitle";
import DetailsBigBanner from "../../components/DetailsBigBanner";
import DetailsCategories from "../../components/DetailsCategories";
import LoadContent from "../../../../components/LoadContent";
import ProductCard from "../../../../components/ProductCard";
import DescriptionTitle from "../../components/DescriptionTitle";
import langData from "../../../../lang/data";
import FromToPage from "../../../../components/FromToPage";

const DetailsVue3 = ({ detailsList, isLoading, error, currentLanguage }) => {
  return (
    <div className="wrapper">
      <div className="style_viue_3">
        <FromToPage
          pages={[
            {
              label: langData.titles.Sales[currentLanguage],
              value: "",
              active: false,
            },
            {
              label: detailsList?.sale?.["name_" + currentLanguage],
              value: "",
              active: true,
            },
          ]}
        />
        <DetailsBigBanner
          isLoading={isLoading}
          currentLanguage={currentLanguage}
          detailsList={detailsList}
        />

        {!isLoading ? (
          <>
            {detailsList.categories ? (
              <>
                <DetailsCategories list={detailsList.categories} />
              </>
            ) : null}
          </>
        ) : (
          <>
            <div className={style.details_loader_categories}>
              {Array.from({ length: 7 }).map((_, index) => {
                return (
                  <LoadContent
                    key={index}
                    containerClassName={style.details_loader_categories_item}
                  />
                );
              })}
            </div>
          </>
        )}

        <div className={style.details_vue3_all_prods_catalog}>
          {!isLoading && detailsList?.products_first_slider?.length ? (
            <>
              {detailsList.products_first_slider.map((item, index) => {
                return <ProductCard width={"16%"} key={index} product={item} />;
              })}
            </>
          ) : (
            <>
              {Array.from({ length: 10 }).map((_, index) => {
                return (
                  <LoadContent
                    key={index}
                    containerClassName={styles.details_all_prods_catalog_loader}
                  />
                );
              })}
            </>
          )}
        </div>

        {!isLoading && detailsList?.sale ? (
          <DescriptionTitle
            currentLanguage={currentLanguage}
            detailsList={detailsList}
          />
        ) : null}
      </div>
    </div>
  );
};

export default DetailsVue3;
