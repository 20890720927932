import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { salesDetailsFetch } from "../../store/SalesDetails/salesDetailsFetch";
import DetailsVue3 from "./pages/DetailsVue3";
import DetailsVue2 from "./pages/DetailsVue2 (BROUN)";
import DetailsVue1 from "./pages/DetailsVue1(sevUrbat )";
import DetailsVue4 from "./pages/DetailsVue4(NewDesign)";

const SalesProductDetails = () => {
  const { currentLanguage } = useSelector(({ main }) => main);
  const { isLoading, error, detailsList } = useSelector(
    (state) => state.SalesDetailsSlice
  );
  const dispatch = useDispatch();
  const { id, view } = useParams();

  useEffect(() => {
    if (!detailsList.length) {
      dispatch(salesDetailsFetch(id));
    }
  }, [detailsList.length, dispatch, id]);

  return (
    <>
      {+view === 2 ? (
        <DetailsVue2
          currentLanguage={currentLanguage}
          detailsList={detailsList}
          isLoading={isLoading}
          error={error}
        />
      ) : +view === 3 ? (
        <DetailsVue3
          currentLanguage={currentLanguage}
          detailsList={detailsList}
          isLoading={isLoading}
          error={error}
        />
      ) : +view === 4 ? (
        <DetailsVue4
          currentLanguage={currentLanguage}
          detailsList={detailsList}
          isLoading={isLoading}
          error={error}
        />
      ) : (
        <DetailsVue1
          currentLanguage={currentLanguage}
          detailsList={detailsList}
          isLoading={isLoading}
          error={error}
        />
      )}
    </>
  );
};

export default SalesProductDetails;
