const credit36Month = (price) => {
  if (price && price > 26000) {
    return Math.round((price / 36 + (price * 1.01) / 100) / 100) * 100;
  }
  return null;
};
const credit24Month = (price) => {
  if (price && price > 26000) {
    return Math.round((price / 24 + (price * 1.01) / 100) / 100) * 100;
  }
  return null;
};
export { credit36Month, credit24Month };
