import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import CompareNull from "./components/CompareNull";

import FromToPage from "../../components/FromToPage";
import lang from "../../lang/lang";
import { useDispatch, useSelector } from "react-redux";
import { getComparePageProducts } from "../../store/MainSlice";
import ProductCard from "../../components/ProductCard/index";
import Loader from "./components/Loader";
const ComparePage = () => {
  const [variations, setVariations] = useState({});
  const [pending, setPending] = useState(true);

  const { currentLanguage, comparePageProducts, compares } = useSelector(
    ({ main }) => main
  );

  const dispatch = useDispatch();
  const compare_id = document.querySelector('meta[name="compare_d"]')?.content;
  useEffect(() => {
    setTimeout(() => {
      setPending(false);
    }, 2000);

    if (compare_id) dispatch(getComparePageProducts(compare_id));
  }, [compare_id, compares]);
  useEffect(() => {
    let variations = {};
    comparePageProducts?.forEach(({ product }, index) => {
      product.variations.forEach((item) => {
        let attribute_id = item.attribute_id;
        if (Object.keys(variations?.[attribute_id] || {}).length) {
          variations[attribute_id].values[index] = item.attribute_value;
        } else {
          variations[attribute_id] = {
            attribute: item.attribute,
            values: Array.from({ length: comparePageProducts.length }),
          };

          variations[attribute_id].values[index] = item.attribute_value;
        }
      });
    });
    setVariations(variations);
  }, [comparePageProducts]);


  return (
    <div className="wrapper">
      <FromToPage
        pages={[{ label: lang[currentLanguage]?.COMPARE, active: true }]}
      />
      {pending ? (
        <Loader />
      ) : !comparePageProducts?.length ? (
        <CompareNull />
      ) : (
        <div
          className="scrollBar"
          style={{
            overflow: "auto",
          }}
        >
          <div className={styles.products}>
            {comparePageProducts.map((product) => (
              <ProductCard
                product={product}
                containerStyle={{ minWidth: "218px" }}
              />
            ))}
          </div>
          <div>
            {Object.keys(variations || {})?.map((item) => {
              return (
                <div className={styles.variationsContainer}>
                  <span className={styles.variationTitle}>
                    {variations[item].attribute["name_" + currentLanguage]}
                  </span>
                  <div className={styles.variationValues}>
                    {variations[item].values.map((item) => {
                      return <span>{item?.["value_" + currentLanguage]}</span>;
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default ComparePage;
