import React, {useState} from 'react';
import style from "./style.module.css";

const UserAddressItems = ({removeHandler,addressList,editHandler}) => {
    const [checkedAddress,setCheckAddress] = useState(false)

    const checkHandler = () => {
        setCheckAddress(!checkedAddress)
    }

    return <div className={style.user_address_list}>
        {addressList.map((item, index) => {
            return <div key={index} className={style.user_address_item}>
                <div  onClick={checkHandler} className={style.user_address_info}>
                    <div className={ !checkedAddress ? style.check_address :style.check_address_active }></div>
                    <span>{item.address}</span>

                </div>

                <div className={style.edit_person_inf}>
                    <svg onClick={editHandler} width="14" height="14" viewBox="0 0 14 14" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10.2795 0.57874C11.0512 -0.192913 12.2913 -0.192913 13.063 0.57874L13.4213 0.937008C14.1929 1.70866 14.1929 2.94882 13.4213 3.72047L7.96457 9.17717C7.74409 9.39764 7.44094 9.59055 7.11024 9.67323L4.35433 10.4724C4.13386 10.5276 3.88583 10.4724 3.72047 10.2795C3.52756 10.1142 3.47244 9.86614 3.52756 9.64567L4.32677 6.88976C4.40945 6.55906 4.60236 6.25591 4.82283 6.03543L10.2795 0.57874ZM12.126 1.51575C11.878 1.26772 11.4646 1.26772 11.2165 1.51575L10.3898 2.31496L11.685 3.61024L12.4843 2.78346C12.7323 2.53543 12.7323 2.12205 12.4843 1.87402L12.126 1.51575ZM5.59449 7.24803L5.12598 8.87402L6.75197 8.40551C6.8622 8.37795 6.94488 8.32283 7.02756 8.24016L10.748 4.51968L9.48032 3.25197L5.75984 6.97244C5.67717 7.05512 5.62205 7.1378 5.59449 7.24803ZM5.51181 1.65354C5.87008 1.65354 6.17323 1.95669 6.17323 2.31496C6.17323 2.70079 5.87008 2.97638 5.51181 2.97638H2.4252C1.79134 2.97638 1.32283 3.47244 1.32283 4.07874V11.5748C1.32283 12.2087 1.79134 12.6772 2.4252 12.6772H9.92126C10.5276 12.6772 11.0236 12.2087 11.0236 11.5748V8.48819C11.0236 8.12992 11.2992 7.82677 11.685 7.82677C12.0433 7.82677 12.3465 8.12992 12.3465 8.48819V11.5748C12.3465 12.9252 11.2441 14 9.92126 14H2.4252C1.0748 14 0 12.9252 0 11.5748V4.07874C0 2.75591 1.0748 1.65354 2.4252 1.65354H5.51181Z"
                            fill="#282828"></path>
                    </svg>
                    <svg onClick={removeHandler} width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4.34286 11.2812C4.34286 11.5351 4.12571 11.7325 3.90857 11.7325C3.66429 11.7325 3.47429 11.5351 3.47429 11.2812V5.415C3.47429 5.18938 3.66429 4.96375 3.90857 4.96375C4.12571 4.96375 4.34286 5.18938 4.34286 5.415V11.2812ZM6.51429 11.2812C6.51429 11.5351 6.29714 11.7325 6.08 11.7325C5.83571 11.7325 5.64571 11.5351 5.64571 11.2812V5.415C5.64571 5.18938 5.83571 4.96375 6.08 4.96375C6.29714 4.96375 6.51429 5.18938 6.51429 5.415V11.2812ZM8.68571 11.2812C8.68571 11.5351 8.46857 11.7325 8.25143 11.7325C8.00714 11.7325 7.81714 11.5351 7.81714 11.2812V5.415C7.81714 5.18938 8.00714 4.96375 8.25143 4.96375C8.46857 4.96375 8.68571 5.18938 8.68571 5.415V11.2812ZM8.60429 0.705078L9.60857 2.25625H11.5086C11.8614 2.25625 12.16 2.56648 12.16 2.93313C12.16 3.32797 11.8614 3.61 11.5086 3.61H11.2914V12.1838C11.2914 13.4529 10.3143 14.44 9.12 14.44H3.04C1.81857 14.44 0.868571 13.4529 0.868571 12.1838V3.61H0.651429C0.271429 3.61 0 3.32797 0 2.93313C0 2.56648 0.271429 2.25625 0.651429 2.25625H2.52429L3.52857 0.705078C3.8 0.282031 4.28857 0 4.80429 0H7.32857C7.84429 0 8.33286 0.282031 8.60429 0.705078ZM4.09857 2.25625H8.03429L7.51857 1.46656C7.49143 1.41016 7.41 1.35375 7.32857 1.35375H4.80429C4.72286 1.35375 4.64143 1.41016 4.61429 1.46656L4.09857 2.25625ZM2.17143 12.1838C2.17143 12.6914 2.55143 13.0863 3.04 13.0863H9.12C9.58143 13.0863 9.98857 12.6914 9.98857 12.1838V3.61H2.17143V12.1838Z"
                            fill="#323232"></path>
                    </svg>
                </div>
            </div>
        })}

    </div>

};

export default UserAddressItems;