import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function LoadContent({
  height = "100%",
  width = "100%",
  containerStyle = {},
  containerClassName,
  ...props
}) {
  return (
    <div
      style={{
        borderRadius: "10px",
        overflow: "hidden",
        ...containerStyle,
      }}
      className={containerClassName}
    >
      <Skeleton height={height} width={width} borderRadius={10} {...props} />
    </div>
  );
}
