import React from "react";

export default function MenuArrowSvg() {
  return (
    <svg
      width="7"
      height="11"
      viewBox="0 0 7 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 0.529297L6 5.5293L1 10.5293" stroke="#282828"></path>
    </svg>
  );
}
