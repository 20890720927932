import React from "react";

export default function SliderNextArrowSvg(props) {
  return (
    <svg
      width="13"
      height="22"
      viewBox="0 0 13 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.8001 20.9521L11.5715 11.13L1.80011 1.30777"
        stroke="#282828"
        strokeWidth="2"
        strokeLinecap="round"
      ></path>
    </svg>
  );
}
