import React from "react";
import style from "./style.module.css";

const DescriptionTitle = ({ detailsList, currentLanguage }) => {
  return (
    <div className={style.details_description_title}>
      <h3>{detailsList.sale?.["title_" + currentLanguage]}</h3>
    </div>
  );
};

export default DescriptionTitle;
