import {createSlice} from "@reduxjs/toolkit";
import {getServiceFetch} from "./serviceFetch";

const initialState = {
    isLoading:false,
    error:'',
    serviceList: [],

}
const ServiceSlice = createSlice({
    name: 'service',
    initialState,
    reducers:{},
    extraReducers:{
        [getServiceFetch.pending.type]: (state) => {
            state.isLoading = true
        },
        [getServiceFetch.fulfilled.type]:(state, action)=>{
            state.isLoading = false
            state.error = ''
            state.serviceList = action.payload
        },
        [getServiceFetch.rejected.type]:(state, action)=>{
            state.isLoading = false
            state.error = action.payload
        },

    }
})
export const {} = ServiceSlice.actions

export default ServiceSlice.reducer
