import React from "react";
import style from "./style.module.css";
import LoadContent from "../../../../components/LoadContent";

const DetailsBigBanner = ({ detailsList, currentLanguage, isLoading }) => {
  return (
    <div className={style.details_big_banner}>
      {!isLoading && detailsList.sale ? (
        <>
          <img
            src={
              process.env.REACT_APP_STORAGE_URL +
              detailsList.sale?.["baner_gl_" + currentLanguage]
            }
            alt=""
          />
        </>
      ) : (
        <>
          <LoadContent containerClassName={style.details_big_banner_loader} />
        </>
      )}
    </div>
  );
};

export default DetailsBigBanner;
