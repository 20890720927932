import React from 'react';
import styles from "./style.module.css"
const CreditItem = ({item}) => {
    return (
        <div className={styles.creditItem}>
            <div className={styles.redImgCredit}>
                <img className={styles.redCreditImage} src={process.env.REACT_APP_STORAGE_URL + item?.icon} />
            </div>
            <span className={styles.creditInfoTitle}>{item?.title}</span>
        </div>
    );
};

export default CreditItem;