import React, { useCallback, useEffect, useState } from "react";
import style from "./style.module.css";
import searchNullImg from "../../assets/notfound.png";
import FeatureCategories from "../Home/components/FeatureCategories";
import { useDispatch, useSelector } from "react-redux";
import langData from "../../lang/data";
import {
  getCategoriesFetch,
  getFeaturedFetch,
} from "../../store/SearchNull/searchNullFetch";
import ProductsWithSlide from "../../components/ProductsWithSlide";
import lang from "../../lang/lang";
import MyModal from "../../components/MyModal";
import Order from "../Order";

const SearchNull = () => {
  const { currentLanguage } = useSelector(({ main }) => main);
  const { productsFeatured, categories } = useSelector(
    (state) => state.SearchNullSlice
  );
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFeaturedFetch());
    dispatch(getCategoriesFetch());
  }, []);

  const clickHandler = () => {
    setOpenModal(!openModal);
  };

  return (
    <>
      <div className={style.search_null_block}>
        <div className="wrapper">
          <div className={style.search_null_block_body}>
            <div className={style.search_null_block_text}>
              <h1>{lang[currentLanguage].not_vos_query}</h1>
              <button type="button" onClick={clickHandler}>
                {lang[currentLanguage].order}
              </button>
            </div>

            <div className={style.search_null_block_image}>
              <img src={searchNullImg} alt="" />
            </div>
          </div>

          <div className={style.search_null_categories}>
            <FeatureCategories
              featureCategories={categories.categories}
              title={langData.titles.Categories[currentLanguage]}
            />
          </div>

          <ProductsWithSlide
            productsData={productsFeatured.products}
            title={langData.titles.BestDealProduct[currentLanguage]}
          />
        </div>
      </div>
      {openModal && (
        <MyModal closeSvg={true} removePadding={true} onClose={setOpenModal}>
          <Order />
        </MyModal>
      )}
    </>
  );
};

export default SearchNull;
