import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

const useScrollToTop = () => {
  const { params } = useParams();
  const { pathname, search } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [params, pathname, search]);
  return null;
};

export default useScrollToTop;
