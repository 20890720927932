import React, { memo, useEffect, useLayoutEffect, useState } from "react";
import styles from "./style.module.css";
import lang from "../../../../../../lang/lang";
import { filterProducts } from "../../../../../../store/SearchPageSlice";
import { useDispatch } from "react-redux";

export default memo(function FilterItem({
  title,
  keyName,
  data,
  currentLanguage,
  withLanguage,
  addAttribute,
  removeAttribute,
  activeAttributes,
  filterName,
}) {
  const [opened, setOpened] = useState(activeAttributes?.length);
  const [seeMore, setSeeMore] = useState(false);
  const dispatch = useDispatch();

  if (!data?.length) return null;
  return (
    <div className={styles.container}>
      <div className={styles.titleBlock} onClick={() => setOpened(!opened)}>
        <span className={styles.title}>{title}</span>
        <span
          className={styles.arrow}
          style={{
            transform: opened ? "rotate(0deg)" : "rotate(180deg)",
          }}
        >
          <svg
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.0293 5L4.99991 0.97083L9.02908 4.94145"
              stroke="#282828"
            ></path>
          </svg>
        </span>
      </div>
      {!!opened && (
        <div className={styles.dropDownBlock}>
          {data?.length &&
            [...(data || [])]
              // ?.sort((item) => {
              //   const include = activeAttributes?.includes(item?.id);

              //   if (include) {
              //     return -1;
              //   }
              //   if (!include) return 1;
              //   return 0;
              // })
              ?.map((item, index) => {
                const include = activeAttributes?.includes(item?.id);
                if (!seeMore && index > 2 && !include) return null;

                return (
                  <div
                    className={styles.eachItem}
                    onClick={() => {
                      if (include) {
                        removeAttribute(item?.id);
                        dispatch(
                          filterProducts(filterName || item.attribute_id)
                        );
                      } else {
                        addAttribute(item?.id);
                        dispatch(
                          filterProducts(filterName || item.attribute_id)
                        );
                      }
                    }}
                    key={index}
                  >
                    <div className={styles.checkBox}>
                      <span>
                        {include ? (
                          <svg
                            width="9"
                            height="6"
                            viewBox="0 1 9 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 2.5L3.5 5L8 0.5"
                              stroke={"#E31335"}
                            ></path>
                          </svg>
                        ) : null}
                      </span>
                    </div>
                    {item?.icon && (
                      <img
                        className={styles.icon}
                        src={process.env.REACT_APP_STORAGE_URL + item?.icon}
                      />
                    )}
                    <span className={styles.label}>
                      {item[keyName + (withLanguage ? currentLanguage : "")]}
                    </span>
                  </div>
                );
              })}
          {data?.length > 3 && (
            <span
              className={styles.seeMore}
              onClick={() => setSeeMore(!seeMore)}
            >
              {lang[currentLanguage][seeMore ? "to_close" : "see_more"]}
            </span>
          )}
        </div>
      )}
    </div>
  );
});
