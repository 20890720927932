import React, { useRef, useState } from "react";
import styles from "./style.module.css";
import { SwiperSlide, Swiper } from "swiper/react";
import "swiper/css";
import "swiper/css/scrollbar";
import { useSelector } from "react-redux";
import LoadContent from "../../../../components/LoadContent";
import SliderNextArrowSvg from "../../../../assets/SVG/SliderNextArrowSvg";
import { Autoplay } from "swiper/modules";
import langData from "../../../../lang/data";
import { Link } from "react-router-dom";

export default function CategoriesSlider() {
  const categoryList = useSelector(
    ({ searchPage }) => searchPage.searchPageData?.categoryList
  );
  const currentLanguage = useSelector(({ main }) => main.currentLanguage);
  const [showBtns, setShowBtns] = useState(false);
  const [slidePosition, setSlidePosition] = useState({
    isBeginning: true,
    isEnd: false,
  });

  const swiperRef = useRef();
  if (!categoryList?.length || categoryList?.length == 1) return null;
  return (
    <div className="wrapper">
      <h3 className="title">
        {langData.titles.FeatureCategories[currentLanguage]}
      </h3>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          position: "relative",
        }}
        onMouseMove={() => window.innerWidth > 768 && setShowBtns(true)}
        onMouseOut={() => window.innerWidth > 768 && setShowBtns(false)}
      >
        {window.innerWidth < 600 ? (
          <div className="scrollRow">
            {!categoryList?.length
              ? Array.from({ length: 7 }).map((_, index) => (
                  <SwiperSlide
                    style={{ width: "auto", display: "flex" }}
                    key={index}
                  >
                    <LoadContent containerClassName={styles.loadContent} />
                  </SwiperSlide>
                ))
              : categoryList.map((item, index) => (
                  <div
                    style={{ width: "auto", display: "flex" }}
                    className={styles.slideItem}
                    key={index}
                  >
                    <Link
                      to={`${
                        currentLanguage !== "hy" ? "/" + currentLanguage : ""
                      }/category/filter/${item.slug}`}
                    >
                      <img
                        className={styles.slideItemImg}
                        src={
                          process.env.REACT_APP_STORAGE_URL +
                          item?.category_image?.image
                        }
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = require("../../../../assets/defaultImg.png");
                        }}
                        alt={item?.name_en}
                      />
                      <span className={styles.slideItemTitle}>
                        {item["name_" + currentLanguage]}
                      </span>
                    </Link>
                  </div>
                ))}
          </div>
        ) : (
          <>
            {categoryList.length > 4 && (
              <>
                <div
                  className={`${styles.slideBtn} ${styles.slidePrevArrow}`}
                  style={{
                    opacity: slidePosition.isBeginning ? "0.35" : "1",
                    display: showBtns ? "flex" : "none",
                  }}
                  onClick={() => swiperRef.current?.slidePrev()}
                >
                  <SliderNextArrowSvg />
                </div>
                <div
                  className={`${styles.slideBtn} ${styles.slideNextArrow}`}
                  style={{
                    opacity: slidePosition.isEnd ? "0.35" : "1",
                    display: showBtns ? "flex" : "none",
                  }}
                  onClick={() => swiperRef.current?.slideNext()}
                >
                  <SliderNextArrowSvg />
                </div>
              </>
            )}

            <Swiper
              spaceBetween={20}
              slidesPerView={"auto"}
              style={{ paddingBottom: "15px", margin: 0 }}
              onSlideChange={(e) => {
                setSlidePosition({
                  isEnd: e.isEnd,
                  isBeginning: e.isBeginning,
                });
              }}
              onBeforeInit={(swiper) => {
                swiperRef.current = swiper;
              }}
              centeredSlides={false}
            >
              {!categoryList?.length
                ? Array.from({ length: 11 }).map((_, index) => (
                    <SwiperSlide
                      style={{ width: "auto", display: "flex" }}
                      key={index}
                    >
                      <LoadContent containerClassName={styles.loadContent} />
                    </SwiperSlide>
                  ))
                : categoryList.map((item, index) => (
                    <SwiperSlide
                      style={{ width: "auto", display: "flex" }}
                      className={styles.slideItem}
                      key={index}
                    >
                      <Link
                        to={`${
                          currentLanguage !== "hy" ? "/" + currentLanguage : ""
                        }/category/filter/${item.slug}`}
                      >
                        <img
                          className={styles.slideItemImg}
                          src={
                            process.env.REACT_APP_STORAGE_URL +
                            item?.category_image?.image
                          }
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = require("../../../../assets/defaultImg.png");
                          }}
                          alt={item?.name_en}
                        />
                        <span className={styles.slideItemTitle}>
                          {item["name_" + currentLanguage]}
                        </span>
                      </Link>
                    </SwiperSlide>
                  ))}
            </Swiper>
          </>
        )}
      </div>
    </div>
  );
}
