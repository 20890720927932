import {useState} from "react";


const useOrderValidate = () => {
    const [personInfo, setPersonInfo] = useState({
        firstName: '',
        lastName:'',
        email:'',
        number:'',
        manufacturer:'',
        category: '',
        comment:''


    })
    const [errorText, setErrorText] = useState({
        firstName: '',
        email: '',
        manufacturer: '',
        category: ''
    })

    const validateMail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    const phoneRegex = /^\d{10}$/;

    const validation = () => {
        let validate = true
        let errorString = {
            firstName: '',
            email: '',
            manufacturer: '',
            category: ''
        }
        if (!personInfo.firstName.trim().length) {
            validate = false
            errorString.firstName = "Fill in the required FirstName"
        }
        if (!personInfo.email.trim().length) {
            validate = false
            errorString.email = "Fill in the required EmailAddress"

        } else if (!validateMail.test(personInfo.email)) {
            validate = false
            errorString.email = "Fill in the email"
        }
        if (!personInfo.number.trim().length) {
            validate = false
            errorString.number = "Fill in the required Password"

        }else if(personInfo.number && personInfo.number.length < 9){
            validate = false
            errorString.number = "Fill in the required Password"

        }



        if (!personInfo.manufacturer.trim().length) {
            validate = false
            errorString.manufacturer = "Fill in the required ConfirmPassword"

        }
        if (!personInfo.category.trim().length ) {
            validate = false
            errorString.category = "l to each other"
        }
        setErrorText(errorString)
        return validate
    }


    return {validation, personInfo, setPersonInfo, errorText, setErrorText}
}

export default useOrderValidate