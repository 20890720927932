import React, { useEffect, useRef, useState } from "react";
import styles from "./style.module.css";
import FromToPage from "../../components/FromToPage/index";
import Filter from "./components/Filter";
import ProductsList from "./components/ProductsList";
import { useDispatch, useSelector } from "react-redux";
import BanerSlider from "../../components/BanerSlider";
import CategoriesSlider from "./components/CategoriesSlider";
import FilterModal from "./components/FilterModal";
import lang from "../../lang/lang";
import {
  getSearchPageData,
  getSearchRedirectInfo,
  setDinamicPageData,
  setShowFilterModal,
} from "../../store/SearchPageSlice";
import useScrollToTop from "../../helpers/useScrollToTop";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { setPending } from "../../store/MainSlice";

export default function SearchResult() {
  const [requestToken, setRequestToken] = useState(null);
  const { currentLanguage } = useSelector(({ main }) => main);
  const { searchPageData, searchPageProducts, dinamicPageData } = useSelector(
    ({ searchPage }) => searchPage
  );

  const pathnameRef = useRef("");
  const searchRef = useRef("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { params, slug: dinamicPageSlug } = useParams();

  const location = useLocation();

  const search = location?.search?.slice(1).split("&");

  useEffect(() => {
    dispatch(setDinamicPageData(null));
    if (dinamicPageSlug) {
      let propsParams = {};
      if (search?.length > 1) {
        search.slice(1)?.forEach((item) => {
          let slug = item.split("=");
          propsParams[slug[0]] = slug[1];
        });
      }
      // dispatch(
      //   getDinamicPageData(
      //     { slug: dinamicPageSlug, params: propsParams },
      //     navigate
      //   )
      // );

      dispatch(
        getSearchRedirectInfo(
          dinamicPageSlug,
          navigate,
          currentLanguage,
          {},
          true
        )
      );
    } else if (
      params &&
      search.length &&
      (location.pathname !== pathnameRef.current ||
        location.search !== searchRef.current)
    ) {
      pathnameRef.current = location.pathname;
      searchRef.current = location.search;

      let propsParams = {};
      if (search?.length > 1) {
        search.slice(1)?.forEach((item) => {
          let slug = item.split("=");
          propsParams[slug[0]] = slug[1];
        });
      }

      dispatch(
        getSearchPageData(
          {
            item: params,
            slug: search?.[0],
            params: propsParams,
          },
          navigate,
          requestToken,
          setRequestToken
        )
      );
    } else {
      dispatch(setPending(false));
    }
  }, [
    currentLanguage,
    dinamicPageSlug,
    dispatch,
    location,
    navigate,
    params,
    requestToken,
    search,
  ]);

  useScrollToTop();

  if (!Object.keys(searchPageData || {}).length) return null;

  return (
    <div className="wrapper">
      <FromToPage
        pages={[
          ...(searchPageData.length
            ? searchPageData?.breadcrumbs?.map((item) => ({
                value: item?.slug,
                label: item?.["name_" + currentLanguage],
              }))
            : []),
          {
            label:
              dinamicPageData?.["title_" + currentLanguage] ||
              searchPageData?.brand_name ||
              searchPageData.category?.["name_" + currentLanguage] ||
              lang[currentLanguage]?.search,
            active: true,
          },
        ]}
      />
      <div className={styles.title}>
        {/* {['/search/brand'].includes(location?.pathname) && <img
          src={process.env.REACT_APP_STORAGE_URL + searchPageData?.brand_logo}
          className={styles.logo}
        />} */}
        <span className={styles.titleText}>
          {dinamicPageData?.["title_" + currentLanguage] ||
            searchPageData?.category?.["name_" + currentLanguage] ||
            searchPageData?.brand_name ||
            lang[currentLanguage].search_resalt}
        </span>

        <span className={styles.titleCount}>
          {searchPageProducts?.length || 0}
        </span>
      </div>
      {/* {!!searchPageData?.brand_logo && (
        <img
          src={process.env.REACT_APP_STORAGE_URL + searchPageData?.brand_logo}
          className={styles.logo}
        />
      )} */}

      <div className={styles.main}>
        {window.innerWidth >= 1024 ? <Filter /> : <FilterModal />}
        <div className={styles.rightBlock}>
          {(!!searchPageData?.category?.category_slider_image.length ||
            !!dinamicPageData?.sliders?.length) && (
            <BanerSlider
              bannerSliders={
                dinamicPageData?.sliders ||
                searchPageData?.category?.category_slider_image
              }
              containerStyle={{
                margin: 0,
                padding: 0,
              }}
            />
          )}
          <CategoriesSlider />
          {window.innerWidth < 1024 && (
            <div
              className={styles.filterBtn}
              onClick={() => dispatch(setShowFilterModal(true))}>
              <svg
                width="24"
                height="23"
                viewBox="0 0 24 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0 18.75C0 18.375 0.328125 18 0.75 18H3.79688C4.17188 16.3125 5.67188 15 7.5 15C9.28125 15 10.7812 16.3125 11.1562 18H23.25C23.625 18 24 18.375 24 18.75C24 19.1719 23.625 19.5 23.25 19.5H11.1562C10.7812 21.2344 9.28125 22.5 7.5 22.5C5.67188 22.5 4.17188 21.2344 3.79688 19.5H0.75C0.328125 19.5 0 19.1719 0 18.75ZM9.75 18.75C9.75 17.5312 8.71875 16.5 7.5 16.5C6.23438 16.5 5.25 17.5312 5.25 18.75C5.25 20.0156 6.23438 21 7.5 21C8.71875 21 9.75 20.0156 9.75 18.75ZM16.5 7.5C18.2812 7.5 19.7812 8.8125 20.1562 10.5H23.25C23.625 10.5 24 10.875 24 11.25C24 11.6719 23.625 12 23.25 12H20.1562C19.7812 13.7344 18.2812 15 16.5 15C14.6719 15 13.1719 13.7344 12.7969 12H0.75C0.328125 12 0 11.6719 0 11.25C0 10.875 0.328125 10.5 0.75 10.5H12.7969C13.1719 8.8125 14.6719 7.5 16.5 7.5ZM18.75 11.25C18.75 10.0312 17.7188 9 16.5 9C15.2344 9 14.25 10.0312 14.25 11.25C14.25 12.5156 15.2344 13.5 16.5 13.5C17.7188 13.5 18.75 12.5156 18.75 11.25ZM23.25 3C23.625 3 24 3.375 24 3.75C24 4.17188 23.625 4.5 23.25 4.5H12.6562C12.2812 6.23438 10.7812 7.5 9 7.5C7.17188 7.5 5.67188 6.23438 5.29688 4.5H0.75C0.328125 4.5 0 4.17188 0 3.75C0 3.375 0.328125 3 0.75 3H5.29688C5.67188 1.3125 7.17188 0 9 0C10.7812 0 12.2812 1.3125 12.6562 3H23.25ZM6.75 3.75C6.75 5.01562 7.73438 6 9 6C10.2188 6 11.25 5.01562 11.25 3.75C11.25 2.53125 10.2188 1.5 9 1.5C7.73438 1.5 6.75 2.53125 6.75 3.75Z"
                  fill="white"></path>
              </svg>
              <span>{lang[currentLanguage].filter}</span>
            </div>
          )}

          <ProductsList />
        </div>
      </div>
    </div>
  );
}
