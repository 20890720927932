import {createSlice} from "@reduxjs/toolkit";
import {postHeaderCategories} from "./headerCategoriesFetch";

const initialState = {
    isLoading:false,
    error:'',
    responseList: [],
    updateSlug:null
}
const HeaderCategoriesSlice = createSlice({
    name: 'responseList',
    initialState,
    reducers:{
        createCatalogSlug:(state,action) =>{
            state.updateSlug = action.payload
        }
    },

    extraReducers:{
        [postHeaderCategories.pending.type]: (state) => {
            state.isLoading = true
        },
        [postHeaderCategories.fulfilled.type]:(state, action)=>{
            state.isLoading = false
            state.error = ''
            state.responseList = action.payload;
            },
        [postHeaderCategories.rejected.type]:(state, action)=>{
            state.isLoading = false
            state.error = action.payload
        },

    }
})

export const {createCatalogSlug} = HeaderCategoriesSlice.actions

export default HeaderCategoriesSlice.reducer
