import React from "react";

export default function GridSvg() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="7.75"
        height="7.75"
        rx="1.5"
        stroke="#D9D9D9"
      ></rect>
      <rect
        x="11.75"
        y="0.5"
        width="7.75"
        height="7.75"
        rx="1.5"
        stroke="#D9D9D9"
      ></rect>
      <rect
        x="0.5"
        y="11.75"
        width="7.75"
        height="7.75"
        rx="1.5"
        stroke="#D9D9D9"
      ></rect>
      <rect
        x="11.75"
        y="11.75"
        width="7.75"
        height="7.75"
        rx="1.5"
        stroke="#D9D9D9"
      ></rect>
    </svg>
  );
}
