import React from "react";

export default function ButtonArrowSvg(props) {
  return (
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: "pointer" }}
      {...props}
    >
      <path
        d="M1 1L7 7L13 1"
        stroke="#282828"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  );
}
