import React, { useRef, useState } from "react";
import styles from "./style.module.css";
import SliderNextArrowSvg from "../../assets/SVG/SliderNextArrowSvg";
import LoadContent from "../LoadContent";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, EffectCube } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-cube";
import "swiper/css/pagination";
export default function BanerSlider({
  bannerSliders = [],
  effect,
  containerStyle = {},
  imgStyle = {},
  loop = false,
  height = "",
  container,
  pagination = true,
  loading,
}) {
  const [showBtns, setShowBtns] = useState(false);
  const [slidePosition, setSlidePosition] = useState({
    isBeginning: true,
    isEnd: false,
  });
  const { currentLanguage } = useSelector(({ main }) => main);
  const swiperRef = useRef();
  const filteredSliders = bannerSliders.find((item) => item?.lang)
    ? bannerSliders.filter((item) => item.lang === currentLanguage)
    : bannerSliders;

  const hasMultipleSlides = filteredSliders.length > 1;

  return (
    <div
      className={container}
      style={{ position: "relative", ...containerStyle }}
      onMouseMove={() => window.innerWidth > 768 && setShowBtns(true)}
      onMouseOut={() => window.innerWidth > 768 && setShowBtns(false)}>
      {bannerSliders?.[0] === true ? (
        <LoadContent containerClassName={styles.loadContent} />
      ) : (
        <>
          {window.innerWidth > 768 && bannerSliders.length > 1 && (
            <div
              className="wrapper"
              style={{
                position: "absolute",
                height: "100%",
                left: 0,
                right: 0,
              }}>
              {loop ? (
                <>
                  <div
                    className={`${styles.slideBtn} ${styles.slidePrevArrow}`}
                    style={{ display: "flex" }}
                    onClick={() => swiperRef.current?.slidePrev()}>
                    <SliderNextArrowSvg />
                  </div>
                  <div
                    className={`${styles.slideBtn} ${styles.slideNextArrow}`}
                    style={{ display: "flex" }}
                    onClick={() => swiperRef.current?.slideNext()}>
                    <SliderNextArrowSvg />
                  </div>
                </>
              ) : (
                <>
                  <div
                    className={`${styles.slideBtn} ${styles.slidePrevArrow}`}
                    style={{
                      display:
                        slidePosition.isBeginning || !showBtns
                          ? "none"
                          : "flex",
                    }}
                    onClick={() => swiperRef.current?.slidePrev()}>
                    <SliderNextArrowSvg />
                  </div>
                  <div
                    className={`${styles.slideBtn} ${styles.slideNextArrow}`}
                    style={{
                      display:
                        slidePosition.isEnd || !showBtns ? "none" : "flex",
                    }}
                    onClick={() => swiperRef.current?.slideNext()}>
                    <SliderNextArrowSvg />
                  </div>
                </>
              )}
            </div>
          )}

          <Swiper
            modules={
              (bannerSliders.find((item) => item?.lang)
                ? bannerSliders.filter((item) => item.lang == currentLanguage)
                : bannerSliders
              ).length > 1 && pagination
                ? [Pagination, Autoplay, EffectCube]
                : [Autoplay, EffectCube]
            }
            {...(effect ? { effect: "cube" } : "")}
            grabCursor={true}
            pagination={true}
            cubeEffect={{
              shadow: hasMultipleSlides,
              slideShadows: hasMultipleSlides,
              shadowOffset: 20,
              shadowScale: 0.94,
            }}
            loop={loop}
            autoplay
            slidesPerView={1}
            spaceBetween={20}
            onSlideChange={(e) => {
              setSlidePosition({
                isEnd: e.isEnd,
                isBeginning: e.isBeginning,
              });
            }}
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
            centeredSlides={true}
            style={{
              maxWidth: "1170px!important",
              minWidth: "320px!important",
            }}>
            {(bannerSliders.find((item) => item?.lang)
              ? bannerSliders.filter((item) => item.lang == currentLanguage)
              : bannerSliders
            ).map((item, index) => (
              <SwiperSlide
                key={index}
                style={{ maxWidth: "100%" }}>
                <a
                  href={item?.href}
                  className={styles.imgContainer}
                  style={{ height: height ? height : "" }}>
                  <img
                    className={
                      container ? styles.slideImgBanner : styles.slideImg
                    }
                    src={
                      process.env.REACT_APP_STORAGE_URL +
                      (item["image_" + currentLanguage] || item.image)
                    }
                    style={imgStyle}
                    alt="VLV baner"
                  />
                </a>
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      )}
    </div>
  );
}
