import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "./request";

const initialState = {
  haederSliders: [],
  sales: [],
  featureCategories: [],
  bannerSliders: [],
  workInformations: [],
  bestDealProduct: [],
  topRatingProduct: [],
  topPicksProduct: [],
  blogs: [],
  footerBaner: null,
  haveMainInfo: false,
  headerBaners: null,
  treeInOne: null,
  specialOffer: null,
};

export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setHeaderSliders: (store, action) => {
      return {
        ...store,
        headerSliders: action.payload,
      };
    },
    setHeaderBaners: (store, action) => {
      return {
        ...store,
        headerBaners: action.payload,
      };
    },
    setSales: (store, action) => {
      return {
        ...store,
        sales: action.payload,
      };
    },
    setFeatureCategories: (store, action) => {
      return {
        ...store,
        featureCategories: action.payload,
      };
    },
    setBanerSliders: (store, action) => {
      return {
        ...store,
        bannerSliders: action.payload,
      };
    },

    setWorkInformations: (store, action) => {
      return {
        ...store,
        workInformations: action.payload,
      };
    },
    setBestDealProduct: (store, action) => {
      return {
        ...store,
        bestDealProduct: action.payload,
      };
    },
    setTopRatingProduct: (store, action) => {
      return {
        ...store,
        topRatingProduct: action.payload,
      };
    },
    setTopPicksProduct: (store, action) => {
      return {
        ...store,
        topPicksProduct: action.payload,
      };
    },

    setBlogs: (store, action) => {
      return {
        ...store,
        blogs: action.payload,
      };
    },

    setFooterBaner: (store, action) => {
      return {
        ...store,
        footerBaner: action.payload,
      };
    },
    setHaveMainInfo: (store, action) => {
      return {
        ...store,
        haveMainInfo: action.payload,
      };
    },
    setTreeInOne: (store, action) => {
      return {
        ...store,
        treeInOne: action.payload,
      };
    },
    setSpecialOffer: (store, action) => {
      return {
        ...store,
        specialOffer: action.payload,
      };
    },
  },
});

export const getMainInfo = () => (dispatch) => {
  Promise.all([
    dispatch(getHaedaerSliders()),
    dispatch(getHaedaerBaners()),
  ]).finally(() => {
    Promise.all([
      dispatch(getSpecialOffer()),
      dispatch(getSales()),
      dispatch(getBestDealProduct()),
      dispatch(getBanerSliders()),
      dispatch(getTopRatingProduct()),
      dispatch(getThreeInOne()),
      dispatch(getTopPicksProduct()),
      dispatch(getBlog()),
      dispatch(getFooterBaner()),
    ]).finally(() => {
      dispatch(setHaveMainInfo(true));
    });
  });
};
export const getHaedaerSliders = () => async (dispatch) => {
  await axiosInstance
    .post("/header-slider", {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
    })
    .then((response) => {
      dispatch(setHeaderSliders(response?.data?.Sliders));
    })
    .catch((err) => {
      console.warn("Error: getHaedaerSliders", err);
    });
};
export const getHaedaerBaners = () => async (dispatch) => {
  await axiosInstance
    .post("/header-baners", {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
    })
    .then((response) => {
      dispatch(setHeaderBaners(response?.data));
    })
    .catch((err) => {
      console.warn("Error: getHaedaerSliders", err);
    });
};
export const getWorkInformations = () => async (dispatch) => {
  await axiosInstance
    .post("/work-informations", {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
    })
    .then((response) => {
      dispatch(setWorkInformations(response.data.workInformations));
    })
    .catch((err) => {
      console.warn("Error: getWorkInformations", err);
    });
};
export const getFeatureCategories = () => async (dispatch) => {
  await axiosInstance
    .post("/feature-categories-cat", {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
    })
    .then((response) => {
      dispatch(setFeatureCategories(response.data.featureCategoriesCat));
    })
    .catch((err) => {
      console.warn("Error: getFeatureCategories", err);
    });
};
export const getSales = () => async (dispatch) => {
  await axiosInstance
    .post("/sales", {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
    })
    .then((response) => {
      dispatch(setSales(response.data?.sales));
    })
    .catch((err) => {
      console.warn("Error: getSales", err);
    });
};
export const getBanerSliders = () => async (dispatch) => {
  await axiosInstance
    .post("/baner-slider", {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
    })
    .then((response) => {
      dispatch(setBanerSliders(response?.data?.banerSlider));
    })
    .catch((err) => {
      console.warn("Error: getBanerSliders", err);
    });
};
export const getBestDealProduct = () => async (dispatch) => {
  await axiosInstance
    .post("/best-deal-product", {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
    })
    .then((response) => {
      dispatch(setBestDealProduct(response.data.bestDealProduct));
    })
    .catch((err) => {
      console.warn("Error: getBestDealProduct", err);
    });
};
export const getTopRatingProduct = () => async (dispatch) => {
  await axiosInstance
    .post("/top-rating-product", {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
    })
    .then((response) => {
      dispatch(setTopRatingProduct(response.data?.topRatingProduct));
    })
    .catch((err) => {
      console.warn("Error: getTopRatingProduct", err);
    });
};
export const getThreeInOne = () => async (dispatch) => {
  await axiosInstance
    .post("/three-in-one", {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
    })
    .then((response) => {
      dispatch(setTreeInOne(response.data));
    })
    .catch((err) => {
      console.warn("Error: getThreeInOne", err);
    });
};
export const getTopPicksProduct = () => async (dispatch) => {
  await axiosInstance
    .post("/top-picks-product", {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
    })
    .then((response) => {
      dispatch(setTopPicksProduct(response.data?.topPicksProduct));
    })
    .catch((err) => {
      console.warn("Error: getTopPicksProduct", err);
    });
};
export const getBlog = () => async (dispatch) => {
  await axiosInstance
    .post("/blog", {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
    })
    .then((response) => {
      dispatch(setBlogs(response.data?.Blog));
    })
    .catch((err) => {
      console.warn("Error: getBlog", err);
    });
};

export const getFooterBaner = () => async (dispatch) => {
  await axiosInstance
    .post("/footer-baner", {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
    })
    .then((response) => {
      dispatch(setFooterBaner(response.data.footerBaner));
    })
    .catch((err) => {
      console.warn("Error: getFooterBaner", err);
    });
};
export const getSpecialOffer = () => async (dispatch) => {
  await axiosInstance
    .post("/flash-deals", {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
    })
    .then((response) => {
      dispatch(setSpecialOffer(response.data));
    })
    .catch((err) => {
      console.warn("Error: getSpecialOffer", err);
    });
};

export const {
  setHeaderSliders,
  setHeaderBaners,
  setSales,
  setFeatureCategories,
  setBanerSliders,
  setWorkInformations,
  setBestDealProduct,
  setTopRatingProduct,
  setTopPicksProduct,
  setBlogs,
  setFooterBaner,
  setHaveMainInfo,
  setTreeInOne,
  setSpecialOffer,
} = homeSlice.actions;
export default homeSlice.reducer;
