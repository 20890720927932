import { RouterProvider } from "react-router-dom";
import "./App.css";
import "./fonts.css";
import { Provider } from "react-redux";
import { store } from "./store/store";

import Loader from "./components/Loader";
import "react-toastify/dist/ReactToastify.css";
import routes from "routes";

function App() {
  return (
    <Provider store={store}>
      <Loader>
        <RouterProvider router={routes} />
      </Loader>
    </Provider>
  );
}

export default App;
// kogaha3417@brinkc.com
// test1234
// 088888888