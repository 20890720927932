import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from "../request";

export const bannerSliderThunk = createAsyncThunk('bannerSlider', 
    async (_, thunkAPI) => {
	try {
        const response = await axiosInstance.post(`/banner-slider-section`, {
            login: process.env.REACT_APP_LOGIN,
            password: process.env.REACT_APP_PASSWORD,
            token: process.env.REACT_APP_TOKEN,
        });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});