import * as React from "react";

const StoryUnmute = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    fill="none">
    <path
      stroke="#FFF"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.5}
      d="M16.958 7.708 9.25 13.875H3.083v9.25H9.25l7.708 6.167V7.708ZM29.4 7.6a15.416 15.416 0 0 1 0 21.8m-5.442-16.357a7.708 7.708 0 0 1 0 10.9"
    />
  </svg>
);
export default StoryUnmute;
