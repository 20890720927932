import { createSlice } from "@reduxjs/toolkit";
import { categoryThunk } from "./thunk";
import { initialState } from "./initialState";

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    setCategory: (state, action) => {
      return {
        ...state,
        category: action.payload,
      };
    },
  },
  extraReducers: (builder, oldState) => {
    builder.addCase(categoryThunk.pending, (state) => {
      state.category = {};
      state.loading = true;
    });
    builder.addCase(categoryThunk.fulfilled, (state, action) => {
      // if (action.payload.data) {
      state.category = action.payload.data;
      // }

      state.loading = false;
    });
    builder.addCase(categoryThunk.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { setCategory } = categorySlice.actions;

export default categorySlice.reducer;
