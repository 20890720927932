import React from "react";
import "./style.css";

export default function CircleLoader({ containerStyle }) {
  return (
    <div id="pre-loader-frontend" style={containerStyle}>
      <div className="preloader_body">
        <div className="preloader_body_item">
          <span className="preloader_loader"></span>
        </div>
      </div>
    </div>
  );
}
