import React from "react";

export default function HomeSvg() {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.7943 9.52102C22.0618 9.75562 22.0618 10.1466 21.8326 10.4203C21.6415 10.694 21.2594 10.694 20.9919 10.4594L19.5782 9.16911V16.8719C19.5782 18.6315 18.2026 20 16.5214 20H5.51685C3.7974 20 2.46005 18.6315 2.46005 16.8719V9.16911L1.00807 10.4594C0.740596 10.694 0.358495 10.694 0.167445 10.4203C-0.0618155 10.1466 -0.0618155 9.75562 0.205655 9.52102L10.5988 0.175953C10.8281 -0.058651 11.1719 -0.058651 11.4012 0.175953L21.7943 9.52102ZM5.51685 18.7488H7.9623V12.4927C7.9623 11.828 8.49724 11.2414 9.18502 11.2414H12.8532C13.5028 11.2414 14.0759 11.828 14.0759 12.4927V18.7488H16.5214C17.5148 18.7488 18.3554 17.9277 18.3554 16.8719V8.07429L11.0191 1.46628L3.68277 8.07429V16.8719C3.68277 17.9277 4.48518 18.7488 5.51685 18.7488ZM9.18502 18.7488H12.8532V12.4927H9.18502V18.7488Z"
        fill="#323232"
      ></path>
    </svg>
  );
}
