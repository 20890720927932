import React from 'react';
import styles from './style.module.css'

const Spinner = ({
	width,
	height,
	bradius,
	bcolor,
	color,
	bline,
	speed,
}) => {
	return (
		<div
			className={styles.spinner}
			style={{
				width: `${width}`,
				height: `${height}`,
				borderRadius: `${bradius}`,
				borderColor: `${bcolor}`,
				borderWidth: `${bline}`,
				borderTopColor: `${color}`,
				animationDuration: `${speed}`,
			}}
		/>
	);
};

export default Spinner;