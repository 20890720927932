import axios from "axios";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}api/`,
  headers: {
    Accept: "/",
    "Content-Type": "application/json; charset=utf-8",
  },
  mode: "no-cors",
});

axiosInstance.interceptors.request.use(
  (config) => {
    // const _token = document.querySelector('meta[name="_token"]')?.content;
    // if (_token) {
    //   config.headers.common["X-CSRF-TOKEN"] = _token;
    // }

    // if (typeof process.env.REACT_APP_TOKEN === "string") {
    //   config.headers.Authorization = `Bearer ${process.env.REACT_APP_TOKEN}`;
    // }
    return config;
  },
  (error) => {
    console.warn("axiosInstance error", error);
    return Promise.reject(error);
  }
);

export default axiosInstance;
