import React from "react";

export default function SortBtnSvg() {
  return (
    <svg
      width="18"
      height="13"
      viewBox="0 0 18 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.64645 12.8536C3.84171 13.0488 4.15829 13.0488 4.35355 12.8536L7.53553 9.67157C7.7308 9.47631 7.7308 9.15973 7.53553 8.96447C7.34027 8.7692 7.02369 8.7692 6.82843 8.96447L4 11.7929L1.17157 8.96447C0.976311 8.7692 0.659728 8.7692 0.464466 8.96447C0.269204 9.15973 0.269204 9.47631 0.464466 9.67157L3.64645 12.8536ZM3.5 0V12.5H4.5V0H3.5Z"
        fill="white"
      ></path>
      <path
        d="M14.3536 0.146446C14.1583 -0.0488157 13.8417 -0.0488157 13.6464 0.146446L10.4645 3.32843C10.2692 3.52369 10.2692 3.84027 10.4645 4.03553C10.6597 4.2308 10.9763 4.2308 11.1716 4.03553L14 1.20711L16.8284 4.03553C17.0237 4.2308 17.3403 4.2308 17.5355 4.03553C17.7308 3.84027 17.7308 3.52369 17.5355 3.32843L14.3536 0.146446ZM14.5 13L14.5 0.5L13.5 0.5L13.5 13L14.5 13Z"
        fill="white"
      ></path>
    </svg>
  );
}
