import React from "react";
import styles from "../../style.module.css";
import { Link } from "react-router-dom";
import Counter from "../../../Counter";
import UseCalcPrice from "../../../../../../helpers/UseCalcPrice";
import lang from "../../../../../../lang/lang";
import { useDispatch, useSelector } from "react-redux";
import {
  addFavorites,
  addWishList,
  removeFavorites,
  setCartPageUpdateQty,
} from "../../../../../../store/MainSlice";
import HeartSvg from "../../../../../../assets/SVG/HeartSvg";
import { toast } from "react-toastify";
import GiftSvg from "../../../../../../assets/SVG/GiftSvg";
import { onClickInstallingBtn } from "../../../../../../store/SearchPageSlice";

export default function CartProduct({ product, setProduct, deleteProduct }) {
  const { favorites, currentLanguage, currentCurrency } = useSelector(
    ({ main }) => main
  );
  const dispatch = useDispatch();
  return (
    <div className={styles.cart_products_item}>
      <div className={styles.products_item_img}>
        {product?.gift_images?.length ? (
          <div className={styles.giftBox}>
            <GiftSvg />
            <div className={styles.giftHovered}>
              {product?.gift_images?.map((item, index) => (
                <div className={styles.giftHoveredMain} key={index}>
                  <Link
                    to={`${
                      currentLanguage !== "hy" ? "/" + currentLanguage : ""
                    }/Product/${item?.product_id}`}
                  >
                    <img
                      src={process.env.REACT_APP_STORAGE_URL + item.images}
                      className={styles.giftHoveredImg}
                      alt="gift"
                    />
                  </Link>
                </div>
              ))}
            </div>
          </div>
        ) : null}
        {!!product?.sticker?.length && (
          <img
            src={
              process.env.REACT_APP_STORAGE_URL +
              product?.sticker?.[0]?.["image_" + currentLanguage]
            }
            className={styles.sticker}
          />
        )}
        <Link
          to={`${
            currentLanguage !== "hy" ? "/" + currentLanguage : ""
          }/Product/${product?.id}`}
        >
          <img
            className={styles.productImage}
            src={process.env.REACT_APP_STORAGE_URL + product?.image}
            alt=""
          />
        </Link>
      </div>

      <div className={styles.products_item_info}>
        <div className={styles.item_info_title_count}>
          <Link
            to={`${
              currentLanguage !== "hy" ? "/" + currentLanguage : ""
            }/Product/${product?.id}`}
            className={styles.item_info_title}
          >
            {product?.brandName + " " + product?.productName}
          </Link>
          <Counter
            count={product.count}
            decrement={() => {
              if (product.count > 1) {
                setProduct({
                  ...product,
                  installing: product.installing ? product.installing - 1 : 0,
                  count: product.count - 1,
                });
                dispatch(
                  setCartPageUpdateQty({
                    type: "-",
                    id: product.cartId,
                    qty: product.count - 1,
                  })
                );
              } else {
                toast(
                  <div>
                    <p style={{ fontWeight: 700, fontSize: "14px" }}>
                      Զգուշացում
                    </p>
                    <span style={{ wordWrap: "break-word", fontSize: "12px" }}>
                      Նվազագույն քանակի սահմանաչափը գերազանցվել է:
                    </span>
                  </div>,
                  {
                    type: "info",
                  }
                );
              }
            }}
            increment={() => {
              setProduct({
                ...product,
                installing: product.installing ? product.installing + 1 : 0,
                count: product.count + 1,
              });
              dispatch(
                setCartPageUpdateQty({
                  type: "+",
                  id: product.cartId,
                  qty: product.count + 1,
                })
              );
            }}
          />
          {window.innerWidth <= 992 && (
            <div className={styles.info_prise_block}>
              {product?.promoPrice || product?.OnlineSelingPrice ? (
                <>
                  <span className={styles.newPrice}>
                    {UseCalcPrice(
                      (() => {
                        let total = 0;

                        if (product?.installing) {
                          total +=
                            (product.count - +product.installing) *
                            ((product?.promoPrice ||
                              product?.OnlineSelingPrice) -
                              product?.installingPrice);
                          total +=
                            +product.installing *
                            (product?.promoPrice || product?.OnlineSelingPrice);
                        } else {
                          total +=
                            product.count *
                            (product?.haveInstalling && !product.installing
                              ? (product?.promoPrice ||
                                  product?.OnlineSelingPrice) -
                                product.installingPrice
                              : product?.promoPrice ||
                                product?.OnlineSelingPrice);
                        }
                        return total;
                      })(),
                      currentCurrency
                    )}
                  </span>
                  <div className={styles.oldPriceContainer}>
                    <div className={styles.oldPriceBlock}>
                      <span className={styles.oldPrice}>
                        {UseCalcPrice(
                          (() => {
                            let total = 0;

                            if (product?.installing) {
                              total +=
                                (product.count - product.installing) *
                                (product?.sellingPrice -
                                  product?.installingPrice);
                              total +=
                                product.installing * product?.sellingPrice;
                            } else {
                              total +=
                                product?.haveInstalling && !product.installing
                                  ? product?.sellingPrice -
                                    product.installingPrice
                                  : product?.sellingPrice;

                              total = product.count * total;
                            }
                            return total;
                          })(),
                          currentCurrency
                        )}
                      </span>
                      <span className={styles.line} />
                    </div>
                  </div>
                </>
              ) : (
                <span className={styles.price}>
                  {UseCalcPrice(
                    (() => {
                      let total = 0;
                      if (product?.installing) {
                        total +=
                          (product.count - +product.installing) *
                          (product?.sellingPrice - product?.installingPrice);

                        total += +product.installing * product?.sellingPrice;
                      } else {
                        total +=
                          product?.haveInstalling && !product.installing
                            ? product?.sellingPrice - product.installingPrice
                            : product?.sellingPrice;
                        total = product.count * total;
                      }
                      return total;
                    })(),
                    currentCurrency
                  )}
                </span>
              )}
              {product.haveInstalling && product.count > 1 && (
                <div>
                  <div className={styles.installationText}>
                    {+product.installing +
                      " " +
                      lang[currentLanguage].with_installation}
                  </div>
                  <br />
                  {!!(product.count - product.installing) && (
                    <div className={styles.installationText}>
                      {product.count -
                        product.installing +
                        " " +
                        lang[currentLanguage].without_installation}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>

        <div className={styles.price_favorite_block}>
          {window.innerWidth > 992 && (
            <div className={styles.info_prise_block}>
              {product?.promoPrice || product?.OnlineSelingPrice ? (
                <>
                  <span className={styles.newPrice}>
                    {UseCalcPrice(
                      (() => {
                        let total = 0;

                        if (product?.installing) {
                          total +=
                            (product.count - +product.installing) *
                            ((product?.promoPrice ||
                              product?.OnlineSelingPrice) -
                              product?.installingPrice);
                          total +=
                            +product.installing *
                            (product?.promoPrice || product?.OnlineSelingPrice);
                        } else {
                          total +=
                            product.count *
                            (product?.haveInstalling && !product.installing
                              ? (product?.promoPrice ||
                                  product?.OnlineSelingPrice) -
                                product.installingPrice
                              : product?.promoPrice ||
                                product?.OnlineSelingPrice);
                        }
                        return total;
                      })(),
                      currentCurrency
                    )}
                  </span>
                  <div className={styles.oldPriceContainer}>
                    <div className={styles.oldPriceBlock}>
                      <span className={styles.oldPrice}>
                        {UseCalcPrice(
                          (() => {
                            let total = 0;

                            if (product?.installing) {
                              total +=
                                (product.count - product.installing) *
                                (product?.sellingPrice -
                                  product?.installingPrice);
                              total +=
                                product.installing * product?.sellingPrice;
                            } else {
                              total +=
                                product?.haveInstalling && !product.installing
                                  ? product?.sellingPrice -
                                    product.installingPrice
                                  : product?.sellingPrice;

                              total = product.count * total;
                            }
                            return total;
                          })(),
                          currentCurrency
                        )}
                      </span>
                      <span className={styles.line} />
                    </div>
                  </div>
                </>
              ) : (
                <span className={styles.price}>
                  {UseCalcPrice(
                    (() => {
                      let total = 0;
                      if (product?.installing) {
                        total +=
                          (product.count - +product.installing) *
                          (product?.sellingPrice - product?.installingPrice);

                        total += +product.installing * product?.sellingPrice;
                      } else {
                        total +=
                          product?.haveInstalling && !product.installing
                            ? product?.sellingPrice - product.installingPrice
                            : product?.sellingPrice;
                        total = product.count * total;
                      }
                      return total;
                    })(),
                    currentCurrency
                  )}
                </span>
              )}
              {product.haveInstalling && product.count > 1 && (
                <div>
                  <div className={styles.installationText}>
                    {+product.installing +
                      " " +
                      lang[currentLanguage].with_installation}
                  </div>
                  <br />
                  {!!(product.count - product.installing) && (
                    <div className={styles.installationText}>
                      {product.count -
                        product.installing +
                        " " +
                        lang[currentLanguage].without_installation}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          {product?.haveInstalling && product.count === 1 ? (
            <div className={styles.installingPriceBlock}>
              <div
                onClick={() => {
                  setProduct({
                    ...product,
                    installing: !product?.installing,
                  });
                  dispatch(
                    onClickInstallingBtn({
                      id: product.cartId,
                      installement: !product?.installing,
                    })
                  );
                }}
                className={`${styles.installingBtnBlock} ${
                  product?.installing && styles.installingBtnBlockActive
                }`}
              >
                <div className={styles.installingBtn}></div>
              </div>
            </div>
          ) : null}

          <div className={styles.item_favorite_block}>
            <div className={styles.favorite_heart}>
              <HeartSvg
                // className={styles.heartSvg}
                onClick={() => {
                  if (
                    (product?.product?.gallary_images_api?.[0] ||
                      product?.product?.thumbnail_image_source) &&
                    !favorites.includes(product.id)
                  ) {
                    // const { top, left } =
                    //   productImageRef.current?.getBoundingClientRect();
                    // dispatch(
                    //   setHeartProductAnimation({
                    //     image:
                    //       product?.product?.gallary_images_api?.[0]
                    //         ?.images_source ||
                    //       product?.product?.thumbnail_image_source,
                    //     position: {
                    //       top,
                    //       left,
                    //     },
                    //   })
                    // );
                  }
                  if (!favorites.includes(product.id)) {
                    dispatch(addFavorites(product.id));
                  } else {
                    dispatch(removeFavorites(product.id));
                  }
                  dispatch(
                    addWishList({
                      seller_product_id: product.id,
                    })
                  );
                }}
                active={favorites.includes(product?.id)}
              />
            </div>
            <div className={styles.favorite_right_bord}></div>
            <div className={styles.favorite_delete} onClick={deleteProduct}>
              <svg
                width="14"
                height="15"
                viewBox="0 0 14 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.26278 11.4414C5.26278 11.6952 5.04564 11.8927 4.82849 11.8927C4.58421 11.8927 4.39421 11.6952 4.39421 11.4414V5.57516C4.39421 5.34953 4.58421 5.12391 4.82849 5.12391C5.04564 5.12391 5.26278 5.34953 5.26278 5.57516V11.4414ZM7.43421 11.4414C7.43421 11.6952 7.21706 11.8927 6.99992 11.8927C6.75564 11.8927 6.56564 11.6952 6.56564 11.4414V5.57516C6.56564 5.34953 6.75564 5.12391 6.99992 5.12391C7.21706 5.12391 7.43421 5.34953 7.43421 5.57516V11.4414ZM9.60564 11.4414C9.60564 11.6952 9.38849 11.8927 9.17135 11.8927C8.92706 11.8927 8.73706 11.6952 8.73706 11.4414V5.57516C8.73706 5.34953 8.92706 5.12391 9.17135 5.12391C9.38849 5.12391 9.60564 5.34953 9.60564 5.57516V11.4414ZM9.52421 0.865234L10.5285 2.41641H12.4285C12.7814 2.41641 13.0799 2.72664 13.0799 3.09328C13.0799 3.48813 12.7814 3.77016 12.4285 3.77016H12.2114V12.3439C12.2114 13.613 11.2342 14.6002 10.0399 14.6002H3.95992C2.73849 14.6002 1.78849 13.613 1.78849 12.3439V3.77016H1.57135C1.19135 3.77016 0.919922 3.48813 0.919922 3.09328C0.919922 2.72664 1.19135 2.41641 1.57135 2.41641H3.44421L4.44849 0.865234C4.71992 0.442188 5.20849 0.160156 5.72421 0.160156H8.24849C8.76421 0.160156 9.25278 0.442188 9.52421 0.865234ZM5.01849 2.41641H8.95421L8.43849 1.62672C8.41135 1.57031 8.32992 1.51391 8.24849 1.51391H5.72421C5.64278 1.51391 5.56135 1.57031 5.53421 1.62672L5.01849 2.41641ZM3.09135 12.3439C3.09135 12.8516 3.47135 13.2464 3.95992 13.2464H10.0399C10.5014 13.2464 10.9085 12.8516 10.9085 12.3439V3.77016H3.09135V12.3439Z"
                  fill="#323232"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
