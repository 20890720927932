import React from "react";

export default function ShareSvg() {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7392 0.0684608C9.93854 0.309321 9.41519 0.749245 9.06572 1.47496C8.73148 2.16923 8.68172 2.81606 8.89685 3.66909C8.91106 3.72544 8.09748 4.26172 6.80681 5.04685L4.6935 6.33246L4.31822 5.97605C3.82878 5.51131 3.32589 5.31061 2.63305 5.30354C0.684284 5.28379 -0.586749 7.39668 0.274129 9.22485C1.03513 10.8409 3.15166 11.2259 4.41425 9.97802L4.70552 9.69016L6.81285 10.9648C8.08183 11.7323 8.91085 12.2755 8.89685 12.3302C8.68124 13.1718 8.7319 13.8296 9.06572 14.5229C10.1925 16.8632 13.5574 16.299 13.9665 13.7012C14.2478 11.9149 12.7145 10.3283 10.9821 10.613C10.4178 10.7057 10.0484 10.8908 9.6457 11.282L9.30275 11.6153L7.18937 10.3364C5.7702 9.47747 5.08409 9.02407 5.10064 8.9561C5.27342 8.24571 5.27321 7.78218 5.09974 7.04177C5.08389 6.97416 5.7772 6.51612 7.18937 5.66152L9.30275 4.38254L9.6457 4.71584C11.136 6.16421 13.5933 5.27159 13.9595 3.14893C14.2288 1.58765 13.0865 0.0983454 11.5488 0.00562473C11.2521 -0.0122062 10.9223 0.0133277 10.7392 0.0684608ZM12.1974 0.917212C12.8422 1.20393 13.2962 1.94927 13.2962 2.72099C13.2962 4.51299 11.2136 5.39848 10.0039 4.12078C9.28861 3.36518 9.26479 2.20824 9.94829 1.41833C10.5581 0.713512 11.3451 0.538198 12.1974 0.917212ZM3.41334 6.19894C4.11819 6.5067 4.62733 7.40759 4.5383 8.18908C4.41542 9.26693 3.63019 9.99778 2.60477 9.98865C2.02884 9.98351 1.7257 9.8607 1.31246 9.46485C0.368448 8.56075 0.535393 6.95825 1.64387 6.28332C2.18981 5.95088 2.78084 5.9227 3.41334 6.19894ZM12.2276 11.5034C13.4562 12.106 13.6737 13.8154 12.6441 14.7772C11.5975 15.7549 9.93923 15.265 9.53752 13.8593C9.33975 13.1675 9.47127 12.5343 9.92351 12.0005C10.5377 11.2755 11.3887 11.092 12.2276 11.5034Z"
        fill="#282828"
      ></path>
    </svg>
  );
}
