import React from 'react';
import style from "./style.module.css"
const BrandBanner = ({img,href}) => {

    return <div className={style.brand_banner_item}>
        <a href={href}>
            <img src={process.env.REACT_APP_STORAGE_URL + img} alt=""/>
        </a>
    </div>


};

export default BrandBanner;