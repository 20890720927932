import React from "react";
import style from "./style.module.css";
import langData from "../../../../lang/data";
import { useSelector } from "react-redux";

const QuestionsList = ({ questionsList, handleClick, activeId, setSearch }) => {
  const { currentLanguage } = useSelector(({ main }) => main);

  return (
    <div className={style.questions_list}>
      <form className={style.input_filter_search}>
        <input
          onChange={(e) => setSearch(e.target.value)}
          type="text"
          placeholder={langData.header.searchPlaceholder[currentLanguage]}
        />
        <button className={style.search_filter_btn}>
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.8416 17.0508L12.8446 12.0938C13.9707 10.793 14.6041 9.14062 14.6041 7.3125C14.6041 3.30469 11.2962 0 7.28446 0C3.23754 0 0 3.30469 0 7.3125C0 11.3555 3.27273 14.625 7.28446 14.625C9.07918 14.625 10.7331 13.9922 12.0352
                        12.8672L16.9971 17.8594C17.1378 17.9648 17.2786 18 17.4545 18C17.5953 18 17.7361 17.9648 17.8416 17.8594C18.0528 17.6484 18.0528 17.2617 17.8416 17.0508ZM7.31965 13.5C3.87097 13.5 1.1261 10.7227 1.1261 7.3125C1.1261 3.90234 3.87097 1.125 7.31965 1.125C10.7331 1.125 13.5132 3.90234 13.5132 7.3125C13.5132 10.7578 10.7331 13.5 7.31965 13.5Z"
              fill="#282828"
              fillOpacity="0.4"
            ></path>
          </svg>
        </button>
      </form>

      {questionsList.map((item, index) => {
        return (
          <div
            onClick={() => handleClick(item.faqs[0])}
            className={
              activeId?.faq_category_id !== item?.faqs[0]?.faq_category_id
                ? style.questions_item
                : style.questions_item_active
            }
          >
            {item["name_" + currentLanguage]}
            <span>
              <svg
                width="14"
                height="8"
                viewBox="0 0 14 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13 0.5L7 6.5L0.999999 0.500001"
                  stroke="#282828"
                ></path>
              </svg>
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default QuestionsList;
