import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import PhoneSvg from "../../assets/SVG/PhoneSvg";
import Currency from "./components/Currency";
import CatalogSvg from "../../assets/SVG/CatalogSvg";
import HeaderCategorys from "./components/HeaderCategorys";
import Catalog from "./components/Catalog";
import MobileMenu from "./components/MobileMenu";
import CatalogMobile from "./components/CatalogMobile";
import SearchModal from "./components/SearchModal/newIndex";
import {
  getSearchInfo,
  setSearchInfo,
  setSearchWs,
} from "../../store/MainSlice";
import { useDispatch, useSelector } from "react-redux";
import langData from "../../lang/data";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { searchWsPage } from "store/WsSearchSlice";
import Loader from "../Loader/newIndex";
export default function MobileHeader() {
  const [headLineWidth, setHeadLineWidth] = useState(0);
  const [openCatalog, setOpenCatalog] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [requestToken, setRequestToken] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const [closeSearchModal, setCloseSearchModal] = useState(false);
  // const [fixedHeader, setFixHeader] = useState(false);
  const [loading, setLoading] = useState(false);
  const { currentLanguage, searchInfo, WSsearchInfo } = useSelector(
    ({ main }) => main
  );

  document.onscroll = function () {
    // setFixHeader(document.body?.parentNode.scrollTop >= 105);
    var pos = getVerticalScrollPercentage(document.body);
    setHeadLineWidth(Math.round(pos));
  };

  function getVerticalScrollPercentage(elm) {
    var p = elm.parentNode;
    return (
      ((elm.scrollTop || p.scrollTop) / (p.scrollHeight - p.clientHeight)) * 100
    );
  }
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (redirect && Object.keys(searchInfo || {}).length) {
      if (
        Object.keys(searchInfo?.category || {}).length &&
        Object.keys(searchInfo?.brand || {}).length
      ) {
        window.location.href = `https://vlv.am/category/${searchInfo?.category?.slug}?item=category&b=${searchInfo?.brand?.id}&t=1&lang=${currentLanguage}`;
      } else if (Object.keys(searchInfo?.brand || {}).length) {
        window.location.href = `https://vlv.am/brand/${searchInfo?.brand?.slug}?item=brand&lang=${currentLanguage}`;
      } else if (Object.keys(searchInfo?.category || {}).length) {
        window.location.href = `https://vlv.am/category/${searchInfo?.category?.slug}?item=category&lang=${currentLanguage}`;
      } else {
        window.location.href = `https://vlv.am/category/${searchText}?item=search&lang=${currentLanguage}`;
      }
    }
  }, [redirect, searchInfo]);
  useEffect(() => {
    if (loading && searchInfo) {
      handleSearch();
    }
  }, [searchInfo, loading]);
  useEffect(() => {
    setSearchText("");
    dispatch(setSearchInfo(null));
  }, [location]);
  
  const handleSearch = async () => {
    if (Object.keys(WSsearchInfo)?.length) {
      setCloseSearchModal(false);
      await dispatch(searchWsPage(WSsearchInfo, navigate, currentLanguage));
      setLoading(false);
    }
  };

  const onSearchChange = async (e) => {
    setSearchText(e.target.value);
    setLoading(false);

    if (e.target.value.length) {
      await dispatch(
        getSearchInfo(e.target.value, requestToken, setRequestToken)
      );
    } else {
      dispatch(setSearchInfo(null));
      dispatch(setSearchWs([]));
    }
  };

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      if (searchText.length) {
        setLoading(true); 
        if (searchInfo && Object.keys(searchInfo).length) {
          setCloseSearchModal(false);
          handleSearch();
        }
      }
    }
  };
  return (
    <>
      {loading && <Loader isLoading={loading} />}
      {window.innerWidth > 1024 ? (
        <Catalog openCatalog={openCatalog} setOpenCatalog={setOpenCatalog} />
      ) : (
        <CatalogMobile
          openCatalog={openCatalog}
          setOpenCatalog={setOpenCatalog}
        />
      )}
      <MobileMenu openMenu={openMenu} setOpenMenu={setOpenMenu} />

      <header
        className={styles.container}
        // style={
        //   fixedHeader
        //     ? {
        //         // paddingTop: "290px",
        //       }
        //     : null
        // }
      >
        <div className={styles.wrapper}>
          <div
            className={styles.headLine}
            style={{ width: headLineWidth + "%" }}
          />
          <div className={styles.headerThreeBlocks}>
            <div className={styles.headerTop}>
              <div className={styles.leftContent}>
                <Link to="/">
                  <img
                    className={styles.logo}
                    src={
                      currentLanguage === "hy"
                        ? require("../../assets/logo.png")
                        : "https://vlv.am/public/logos/header_ru.svg"
                    }
                    alt="VLV"
                  />
                </Link>
              </div>
              <div className={styles.rightContent}>
                <Currency shortBtn />
                <a href="tel:010349944">
                  <PhoneSvg />
                </a>
                <CatalogSvg
                  onClick={() => setOpenMenu(true)}
                  style={{ cursor: "pointer" }}
                  width="28"
                  height="19"
                  fill="#282828"
                />
              </div>
            </div>
            <div className={styles.headerBottom}>
              <div
                className={styles.catalogBtn}
                onClick={() => setOpenCatalog(true)}
              >
                <CatalogSvg />
                <span>{langData.buttons.catalog[currentLanguage]}</span>
              </div>
              <div className={styles.searchContainer}>
                <input
                  placeholder={
                    langData.header.searchPlaceholder[currentLanguage]
                  }
                  className={styles.searchInput}
                  onFocus={(e) => {
                    if (e.target.value.length) {
                      setCloseSearchModal(false);
                    }
                  }}
                  value={searchText}
                  onChange={onSearchChange}
                  onKeyDown={onKeyDown}
                />
                <span
                  className={styles.searchBtn}
                  onClick={() => {
                    if (searchText.length) {
                      setLoading(true); 
                      handleSearch();
                    }
                  }}
                >
                  {langData.buttons.search[currentLanguage]}
                </span>
                <SearchModal
                  closeSearchModal={closeSearchModal}
                  setCloseSearchModal={setCloseSearchModal}
                />
              </div>
            </div>
          </div>
        </div>
        <HeaderCategorys />
      </header>
    </>
  );
}
