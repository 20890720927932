import React, { memo } from "react";
import styles from "./style.module.css";
import menu_data from "./user_menu_data";

import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Langdata from "lang/data";

export default memo(function UserMenu({ containerStyle, setShowUserMenu }) {
  const { currentLanguage } = useSelector(({ main }) => main);
  const { pathname } = useLocation();

  return (
    <div
      className={
        [`/profile/dashboard`].includes(pathname)
          ? `${styles.container} ${styles.containerActive}`
          : styles.container
      }
      style={{
        "max-width": containerStyle ? "338px" : "280px",
        position: !containerStyle && "absolute",
      }}>
      {menu_data.map((data) => (
        <Link
          className={
            // [`/${currentLanguage}/${data.link}`, `/${data.link}`].includes(
            //   pathname
            // )
            //   ? `${styles.btn} ${styles.active}`
            //   :
              styles.btn
          }
          to={`${currentLanguage !== "hy" ? currentLanguage : ""}/${data.link}`}
          aria-label="heart"
          onClick={() => {
            if (data?.keyL) {
              localStorage.removeItem("access_token");
              setShowUserMenu(false);
            }
          }}>
          {data.icon}
          <span>
            {/* {
              Langdata?.profile?.menu_list?.[data?.label]?.[
                currentLanguage || "hy"
              ]
            } */}
            <span>{data.label}</span>
          </span>
        </Link>
        // <a
        //   href={data.link}
        //   className={styles.btn}
        //   onClick={() => {
        //     if (data?.keyL) {
        //       localStorage.removeItem("access_token");
        //       setShowUserMenu(false);
        //     }
        //   }}>
        //   {data.icon}
        //   <span>{data.label}</span>
        // </a>
      ))}
    </div>
  );
});
