import React from "react";

export default function HeartSvg({ active = false, ...props }) {
  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: "pointer" }}
      {...props}
    >
      <path
        d="M16.0352 0.840576C15.0034 0.856221 13.9942 1.13699 13.1093 1.65454C12.2244 2.17208 11.4953 2.90808 10.9956 3.78819C10.4959 2.90808 9.76679 2.17208 8.88193 1.65454C7.99706 1.13699 6.98777 0.856221 5.95597 0.840576C4.31116 0.910239 2.76149 1.61165 1.64551 2.79157C0.529532 3.97149 -0.0620383 5.534 4.13572e-05 7.13775C4.13572e-05 13.1893 10.039 20.1787 10.466 20.4753L10.9956 20.8406L11.5252 20.4753C11.9522 20.1805 21.9912 13.1893 21.9912 7.13775C22.0532 5.534 21.4617 3.97149 20.3457 2.79157C19.2297 1.61165 17.68 0.910239 16.0352 0.840576ZM10.9956 18.6415C8.01489 16.471 1.83264 11.1385 1.83264 7.13775C1.77 6.00758 2.16834 4.89911 2.94077 4.0541C3.71321 3.20909 4.79707 2.6961 5.95597 2.62701C7.11487 2.6961 8.19874 3.20909 8.97117 4.0541C9.74361 4.89911 10.1419 6.00758 10.0793 7.13775H11.9119C11.8493 6.00758 12.2476 4.89911 13.02 4.0541C13.7925 3.20909 14.8763 2.6961 16.0352 2.62701C17.1941 2.6961 18.278 3.20909 19.0504 4.0541C19.8229 4.89911 20.2212 6.00758 20.1586 7.13775C20.1586 11.1403 13.9763 16.471 10.9956 18.6415Z"
        fill={active ? "#E31335" : "#282828"}
        style={{ transition: "all 150ms" }}
      ></path>
      <path
        d="M10.9956 18.6415C8.01489 16.471 1.83264 11.1385 1.83264 7.13775C1.77 6.00758 2.16834 4.89911 2.94077 4.0541C3.71321 3.20909 4.79707 2.6961 5.95597 2.62701C7.11487 2.6961 8.19874 3.20909 8.97117 4.0541C9.74361 4.89911 10.1419 6.00758 10.0793 7.13775H11.9119C11.8493 6.00758 12.2476 4.89911 13.02 4.0541C13.7925 3.20909 14.8763 2.6961 16.0352 2.62701C17.1941 2.6961 18.278 3.20909 19.0504 4.0541C19.8229 4.89911 20.2212 6.00758 20.1586 7.13775C20.1586 11.1403 13.9763 16.471 10.9956 18.6415Z"
        fill={active ? "#E31335" : "transparent"}
        style={{ transition: "all 150ms" }}
      ></path>
    </svg>
  );
}
