import Slider from "rc-slider";
import { memo, useEffect, useState, useCallback } from "react";
import {
    longestDigitsPrefix,
    getSubarrayByIndexValue,
    sortArrayByValueEn,
    setIndex,
} from "./helpers";
import styles from "./styles.module.css";
import langData from "lang/data";
import { selectMain } from "store/selector";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

let timeOutId;

const DiagonalAttributeRange = ({
    attribute,
    activeAttributes,
    handleSelectAttribute,
    handleDeleteAttribute,
}) => {
    const [sliderValue, setSliderValue] = useState([0, 0]);
    const [filteredValues, setFilteredValues] = useState([]);
    const [diagonalValues, setDiagonalValues] = useState([]);
    const { currentLanguage } = useSelector(selectMain);
    const navigate = useNavigate();

    const handleChangeDiagonal = useCallback(
        ({ min, max }) => {
            const minIndex = min / 10;
            const maxIndex = max / 10;
            setSliderValue([min, max]); // Set new slider value

            if (timeOutId) {
                clearTimeout(timeOutId);
            }

            timeOutId = setTimeout(() => {
                // URL Management: Remove attribute range info from URL if slider is at max
                const url = new URL(window.location);
                const params = new URLSearchParams(url.search);

                if (min === 0 && max === (diagonalValues.length - 1) * 10) {
                    params.delete('attributeRange');
                    navigate(`${url.pathname}?${params.toString()}`, { replace: true });
                    handleDeleteAttribute(attribute);
                } else {
                    const selectedDiagonalAttributes = getSubarrayByIndexValue(
                        diagonalValues,
                        diagonalValues[minIndex],
                        diagonalValues[maxIndex]
                    );

                    selectedDiagonalAttributes.forEach((selectedAttribute) => {
                        handleSelectAttribute(selectedAttribute, "screenSize");
                    });
                }
            }, 500);
        },
        [diagonalValues, handleSelectAttribute, handleDeleteAttribute, attribute, navigate]
    );

    useEffect(() => {
        if (activeAttributes.length && diagonalValues.length) {
            const activeAttributesIds = activeAttributes.map(
                (item) => item.split("_")[1]
            );
            setFilteredValues(
                diagonalValues.filter((item) =>
                    activeAttributesIds.includes(item.id.toString())
                )
            );
        }
    }, [activeAttributes, diagonalValues]);

    useEffect(() => {
        const arr = setIndex(sortArrayByValueEn(attribute.values));
        setDiagonalValues(arr);
        setSliderValue([0, (arr.length - 1) * 10]);
    }, [attribute.values]);

    useEffect(() => {
        if (filteredValues.length) {
            setSliderValue([
                filteredValues[0].index * 10,
                filteredValues[filteredValues.length - 1].index * 10,
            ]);
        }
    }, [filteredValues]);

    return (
        <div className={styles.container} style={{ padding: 0 , margin:0 }}>

            {diagonalValues?.length && (
                <div className={styles.sliderContainer}>
                    <Slider
                        className={styles.sliderStyle}
                        trackStyle={{ backgroundColor: "#d10000" }}
                        onChange={(newSliderValue) => {
                            setSliderValue(newSliderValue);
                        }}
                        onAfterChange={(newSliderValue) => {
                            handleChangeDiagonal({ min: newSliderValue[0], max: newSliderValue[1] });
                        }}
                        value={sliderValue}
                        step={10}
                        range
                        min={0}
                        max={Number(diagonalValues?.length - 1) * 10}
                    />
                    <div className={styles.currentValues}>
                        <span>{longestDigitsPrefix(diagonalValues[sliderValue[0] / 10]?.value_en)}</span>
                        <span>{longestDigitsPrefix(diagonalValues[sliderValue[1] / 10]?.value_en)}</span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default memo(DiagonalAttributeRange);
