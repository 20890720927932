import React, { memo, useEffect, useState } from "react";
import styles from "../style.module.css";
import langData from "../../../../../lang/data";
import { useSelector } from "react-redux";

export default memo(function Timer({ endDate }) {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const currentLanguage = useSelector(({ main }) => main.currentLanguage);

  const getDifferenceDate = () => {
    var delta = Math.abs(new Date() - new Date(endDate)) / 1000;
    var days = Math.floor(delta / 86400);
    delta -= days * 86400;
    var hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;
    var minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;
    var seconds = Math.floor(delta % 60);
    setDays(days);
    setHours(hours);
    setMinutes(minutes);
    setSeconds(seconds);
  };
  useEffect(() => {
    if (endDate) getDifferenceDate();
  }, [endDate]);
  useEffect(() => {
    let intervalId;
    if (days > 0 || hours > 0 || minutes > 0 || seconds > 0) {
      intervalId = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        } else if (minutes > 0) {
          setSeconds(60);
          setMinutes(minutes - 1);
        } else if (hours > 0) {
          setMinutes(60);
          setHours(hours - 1);
        } else if (days > 0) {
          setHours(24);
          setDays(days - 1);
        }
      }, 1000);
    } else {
      clearInterval(intervalId);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [days, hours, minutes, seconds]);
  return (
    <div className={styles.timer}>
      <div className={styles.column}>
        <div className={styles.countdown}>{days}</div>
        <div className={styles.nameof_count}>
          {langData.SpecialOffer.days[currentLanguage]}
        </div>
      </div>
      <span className={styles.colon}>:</span>
      <div className={styles.column}>
        <div className={styles.countdown}>{hours}</div>
        <div className={styles.nameof_count}>
          {langData.SpecialOffer.hours[currentLanguage]}
        </div>
      </div>
      <span className={styles.colon}>:</span>
      <div className={styles.column}>
        <div className={styles.countdown}>{minutes}</div>
        <div className={styles.nameof_count}>
          {langData.SpecialOffer.minutes[currentLanguage]}
        </div>
      </div>
      <span className={styles.colon}>:</span>
      <div className={styles.column}>
        <div className={styles.countdown}>{seconds}</div>
        <div className={styles.nameof_count}>
          {langData.SpecialOffer.seconds[currentLanguage]}
        </div>
      </div>
    </div>
  );
});
