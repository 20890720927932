import { createSlice } from "@reduxjs/toolkit";
import { brandThunk, brandCategoryThunk } from "./thunk";
import { initialState } from "./initialState";

export const brandSlice = createSlice({
  name: "brand",
  initialState,
  reducers: {
    clearBrand: (state) => {
      state.brand = [];
    },
    clearBrandCategory: (state) => {
      state.brandCategoy = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(brandThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(brandThunk.fulfilled, (state, action) => {
      state.brand = action.payload;
      state.loading = false;
    });
    builder.addCase(brandThunk.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(brandCategoryThunk.pending, (state) => {
      state.categoryLoading = true;
    });
    builder.addCase(brandCategoryThunk.fulfilled, (state, action) => {
      state.brandCategoy = action.payload;
      state.categoryLoading = false;
    });
    builder.addCase(brandCategoryThunk.rejected, (state) => {
      state.categoryLoading = false;
    });
  },
});

export const { clearBrand, clearBrandCategory } = brandSlice.actions;

export default brandSlice.reducer;
