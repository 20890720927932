import React, { useState } from "react";
import styles from "../style.module.css";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../../../store/MainSlice";
import ArrowSvg from "../assets/ArrowSvg";
import langData from "../../../lang/data";
import lang from "../../../lang/lang";
import { ChakraProvider, Box, PinInput, PinInputField, HStack } from '@chakra-ui/react';
import axios from "axios";

export default function ForgotPassword({ setShowLogin }) {
  const [login, setLogin] = useState("");
  const [loginError, setLoginError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showResetCode, setShowResetCode] = useState(false);
  const [resetCodeError, setResetCodeError] = useState(false);
  const [resetCode, setResetCode] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordMismatchError, setPasswordMismatchError] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [resetFailure, setResetFailure] = useState(false);
  const [showPasswordResetFields, setShowPasswordResetFields] = useState(false);
  const currentLanguage = useSelector(({ main }) => main.currentLanguage);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (!login) {
      setLoginError(true);
    } else {
      setLoginError(false);
      dispatch(forgotPassword(login, () => setSuccess(true)))
        .then(() => {
          // Success handling if needed
        })
        .catch((error) => {
          setLoginError(true);
          
        });
    }
  };

  const handleNextClick = () => {
    setShowResetCode(true);
  };

  const checkCode = () => {
    let payload; 

    if (login.includes('@')) {
      payload = {
        email: login,
        reset_code: resetCode
      };
    } else { 
        payload = {
        email_phone: login,
        reset_code: resetCode
      };
    }
    axios
      .post(process.env.REACT_APP_SERVER_URL + 'api/user/check-reset-code', {
        login: process.env.REACT_APP_LOGIN,
        password: process.env.REACT_APP_PASSWORD,
        token: process.env.REACT_APP_TOKEN,
        ...payload,
      })
      .then((response) => {
       
        setResetCodeError(false);
        setShowPasswordResetFields(true);
      })
      .catch((error) => {
        setResetCodeError(true);
      });
     
  };

  const handleResetCodeChange = (value) => {
    setResetCode(value);
    setResetCodeError(false);
  };

  const handlePasswordReset = () => {
    if (password !== passwordConfirmation) {
      setPasswordMismatchError(true);
      return;
    }
    let payload; 

    if (login.includes('@')) {
      payload = {
        email: login,
        reset_code: resetCode
      };
    } else { 
        payload = {
        email_phone: login,
        reset_code: resetCode
      };
    }
    axios
      .post(process.env.REACT_APP_SERVER_URL + 'password/reset', {
       
        ...payload,
        password: password,
        password_confirmation: passwordConfirmation
      })
      .then((response) => {
        
        setPasswordError(false);
        setPasswordMismatchError(false);
        setResetSuccess(true);
        setResetFailure(false);
        setShowPasswordResetFields(false);
      })
      .catch((error) => {
       
        setPasswordError(true);
        setResetSuccess(false);
        setResetFailure(true);
      });
  };

  const renderPasswordResetFields = () => {
    return (
      <>
        <input
          type="password"
          placeholder={langData.loginModal.password[currentLanguage]}
          className={`${styles.input} ${passwordError && styles.inputError}`}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          type="password"
          placeholder={langData.loginModal.RepeatPassword[currentLanguage]}
          className={`${styles.input} ${passwordError && styles.inputError}`}
          value={passwordConfirmation}
          onChange={(e) => setPasswordConfirmation(e.target.value)}
        />
        {passwordMismatchError && (
          <div style={{ color: 'red', textAlign: 'center' }}>
            {langData.loginModal.password_mismatch_error[currentLanguage]}
          </div>
        )}
        {/* {resetFailure && (
          <div style={{ color: 'red', textAlign: 'center' }}>
            {langData.loginModal.reset_failure[currentLanguage]}
          </div>
        )} */}
        <div className={styles.btn} onClick={handlePasswordReset}>
          <span>{langData.product.save[currentLanguage]}</span>
          <span className={styles.btnArrow} style={{ top: "auto" }}>
            <ArrowSvg />
          </span>
        </div>
      </>
    );
  };

  const renderResetCode = () => {
    return (
      <>
        <div className={styles.we_send}>{langData.loginModal.forgotPasswordText[currentLanguage]}</div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <ChakraProvider>
            <Box p={4}>
              <HStack>
              <PinInput size="lg" otp onChange={handleResetCodeChange}>
                {Array.from({ length: 6 }).map((_, index) => (
                  <PinInputField
                    key={index}
                    style={{
                      width: window.innerWidth > 400 ? "var(--chakra-sizes-12)" : "var(--chakra-sizes-10)",
                      height: window.innerWidth > 400 ? "var(--chakra-sizes-12)" : "var(--chakra-sizes-10)",
                      borderColor: resetCodeError ? 'red' : 'inherit'
                    }}
                  />
                ))}
              </PinInput>
              </HStack>
            </Box>
          </ChakraProvider>
        </div>
        <div className={styles.btn} onClick={checkCode}>
          <span>{langData.loginModal.next[currentLanguage]}</span>
          <span className={styles.btnArrow} style={{ top: "auto" }}>
            <ArrowSvg />
          </span>
        </div>
        {password && passwordConfirmation && renderPasswordResetFields()}
      </>
    );
  };
  if (success && showPasswordResetFields) {
    return renderPasswordResetFields();
  }

  if (resetSuccess) {
    return (
      <div style={{ textAlign: 'center' }}>
        <img
              style={{
                width: window.innerWidth > 400 ? "350px" : "250px",
                height: "auto",
                objectFit: "contain", 
              }}
              src="https://vlv.am/public/password.png"
              alt=""
            />
          <p style={{ color: 'green', marginTop: "30px" }}>{langData.loginModal.password_reset_success[currentLanguage]}</p>
      </div>
    );
  }

  if (success && showResetCode) {
    return renderResetCode();
  }

  if (success) {
    return (
      <>
        <div className={styles.email_icons}>
          <img src="https://vlv.am/public/img/email.png" alt="img" />
          <div className={styles.top_triangle}>
            <svg
              width="57"
              height="58"
              viewBox="0 0 57 58"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.36466 46.5481C0.393336 45.0417 -1.70802 37.6361 2.58222 33.2179L31.7754 3.15454C36.0657 -1.26358 43.5298 0.619414 45.2109 6.54393L56.65 46.8577C58.3311 52.7822 52.9683 58.3049 46.9969 56.7985L6.36466 46.5481Z"
                fill="url(#paint0_radial_1658_50985)"
              ></path>
              <defs>
                <radialGradient
                  id="paint0_radial_1658_50985"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(9.33961 51.7022) rotate(-64.9479) scale(12.2852 22.3958)"
                >
                  <stop stopColor="#404040"></stop>
                  <stop offset="1" stopColor="#212121"></stop>
                </radialGradient>
              </defs>
            </svg>
          </div>
          <div className={styles.bottom_triangle}>
            <svg
              width="49"
              height="49"
              viewBox="0 0 49 49"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_f_1658_50986)">
                <path
                  d="M38.5953 11.9138C44.4663 13.7733 46.1226 21.291 41.5768 25.4456L23.4266 42.0337C18.8808 46.1883 11.5421 43.8639 10.217 37.8498L4.92636 13.8373C3.60128 7.8231 9.28361 2.6298 15.1546 4.48932L38.5953 11.9138Z"
                  fill="url(#paint0_radial_1658_50986)"
                ></path>
              </g>
              <defs>
                <filter
                  id="filter0_f_1658_50986"
                  x="0.732178"
                  y="0.105957"
                  width="47.4512"
                  height="48.0288"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  ></feBlend>
                  <feGaussianBlur
                    stdDeviation="2"
                    result="effect1_foregroundBlur_1658_50986"
                  ></feGaussianBlur>
                </filter>
                <radialGradient
                  id="paint0_radial_1658_50986"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(39.8808 8.95611) rotate(118.468) scale(9.22937 16.8251)"
                >
                  <stop stopColor="#404040"></stop>
                  <stop offset="1" stopColor="#212121"></stop>
                </radialGradient>
              </defs>
            </svg>
          </div>
        </div>
      <div className={styles.we_send}>{langData.loginModal.forgotPasswordText[currentLanguage]}</div>
        <div className={styles.btn} onClick={handleNextClick}>
          <span>{langData.loginModal.next[currentLanguage]}</span>
          <span className={styles.btnArrow} style={{ top: "auto" }}>
            <ArrowSvg />
          </span>
        </div>
      </>
    );
  }

  return (
    <>
      <input
        type="text"
        placeholder={langData.loginModal.email[currentLanguage]}
        className={`${styles.input} ${loginError && styles.inputError}`}
        value={login}
        onChange={(e) => setLogin(e.target.value)}
      />
      {loginError && (
        <div style={{ color: 'red', textAlign: 'center' }}>
          {langData.loginModal.email_error[currentLanguage]}
        </div>
      )}
      <div className={styles.btn} onClick={handleSubmit}>
        <span>{langData.loginModal.next[currentLanguage]}</span>
        <span className={styles.btnArrow} style={{ top: "auto" }}>
          <ArrowSvg />
        </span>
      </div>
    </>
  );
}

                 
