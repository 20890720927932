import React from "react";
import styles from "./style.module.css";
import lang from "../../../../lang/lang";
import { useSelector } from "react-redux";

export default function FavoriteNull() {
  const { currentLanguage } = useSelector(({ main }) => main);
  return (
    <div className={styles.container}>
      <img src="https://vlv.am/public/img/novu.svg" alt="Favorites not found" />
      <span>{lang[currentLanguage].not_favs}</span>
    </div>
  );
}
