import React, { useEffect, useState } from "react";
import style from "./style..module.css";
import { NavLink } from "react-router-dom";
import HomeSvg from "../../assets/SVG/homeSvg";
import LineSvg from "../../assets/SVG/LineSvg";
import langData from "../../lang/data";
import { useDispatch, useSelector } from "react-redux";
import JobsItem from "./components/JobsItem";
import { getJobsPageInfo } from "../../store/jobPage/JobPageFetch";
import LoadContent from "../../components/LoadContent";
import FromToPage from "../../components/FromToPage";
const Jobs = () => {
  const { currentLanguage } = useSelector(({ main }) => main);
  const { isLoadingJobPage, errorJobPage, jobPage } = useSelector(
    (state) => state.JobPageSlice
  );
  const [activeIndex, setActiveIndex] = useState(-1);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getJobsPageInfo());
  }, []);

  const openSubList = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const bannerImg = {
    hy: "https://vlv.am/public/uploads/images/pages/1669032058.png",
    ru: "https://vlv.am/public/uploads/images/pages/1669032140.png",
    en: "https://vlv.am/public//uploads/images/pages/1669032149.png",
  };

  return (
    <div className="wrapper">
      <FromToPage
        pages={[
          {
            label: langData.titles.jobs[currentLanguage],
            value: "/jobs",
            active: true,
          },
        ]}
      />
      {isLoadingJobPage ? (
        <>
          <LoadContent containerClassName={style.loadContent_banner} />
        </>
      ) : (
        <div className={style.about_us_block}>
          <div className={style.about_us_banner}>
            {jobPage.imagesPath ? (
              <img src={bannerImg[currentLanguage]} />
            ) : (
              <img src={`${jobPage.imagesPath}`} />
            )}
          </div>
        </div>
      )}

      <div className={style.about_acardion_list}>
        {isLoadingJobPage ? (
          <>
            {Array.from({ length: 10 }).map((_, index) => {
              return (
                <LoadContent
                  key={index}
                  containerClassName={style.loadContent_jobs}
                />
              );
            })}
          </>
        ) : (
          <>
            {jobPage.jobs && jobPage.jobs.length ? (
              <>
                {jobPage.jobs.map((item, index) => {
                  return (
                    <JobsItem
                      activeIndex={activeIndex}
                      openSubList={openSubList}
                      index={index}
                      item={item}
                      key={index}
                    />
                  );
                })}
              </>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

export default Jobs;
