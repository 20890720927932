import React, { useEffect, useMemo, useRef, useState } from "react";
import FromToPage from "../../components/FromToPage";
import { useDispatch, useSelector } from "react-redux";
import langData from "../../lang/data";
import style from "./style.module.css";
import styles from "../../components/Header/style.module.css";
import { getSeeAllBrands } from "../../store/brandSeeAll/brandSeeAllFetch";
import SeeAllItems from "./SeeAllItems";
import LoadContent from "../../components/LoadContent";
import { useNavigate } from "react-router-dom";
import Pagination from "../../components/Pageination";
const BrandSeeAll = () => {
  const navigate = useNavigate();
  const currentLanguage = useSelector(({ main }) => main.currentLanguage);
  //   const [paginationCount, setPaginationCount] = useState(0);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const { isLoading, error, brands } = useSelector(
    (state) => state.BrandSeeAllSlice
  );
  const urlSearchParams = new URLSearchParams(window.location.search);
  const currentPageFromURL = parseInt(urlSearchParams.get("page"), 10) || 1;
  const [currentPage, setCurrentPage] = useState(currentPageFromURL);
  const [nPages, setNPages] = useState();

  const mainRef = useRef();

  useEffect(() => {
    dispatch(getSeeAllBrands());
  }, []);

  useEffect(() => {
    const updatedSearchParams = new URLSearchParams(window.location.search);
    updatedSearchParams.set("page", currentPage.toString());
    navigate(`?${updatedSearchParams.toString()}`);
  }, [currentPage, navigate]);

  //   const handlePrevPage = () => {
  //     if (currentPage > 1) {
  //       setCurrentPage((prevPage) => prevPage - 1);
  //     }
  //   };
  //   const handlePageClick = (pageNumber) => {
  //     setCurrentPage(pageNumber);
  //   };
  //   const handleNextPage = () => {
  //     if (currentPage < 10) {
  //       setCurrentPage((prevPage) => prevPage + 1);
  //     }
  //   };

  //   useEffect(() => {
  //     setPaginationCount(brands?.Brand?.last_page);
  //   }, [isLoading, brands]);

  const handleChange = (value) => {
    setSearch(value);
  };

  const filterBrands = useMemo(() => {
    if (brands.Brand && brands.Brand && !isLoading) {
      if (search) {
        return brands.Brand.filter((item) =>
          item.name.toLowerCase().includes(search.toLowerCase())
        );
      }
      return brands.Brand;
    }
    return [];
  }, [brands, search, isLoading]);

  useEffect(() => {
    setCurrentPage(1);
    setNPages(Math.ceil(filterBrands.length / 30));
  }, [filterBrands]);
  useEffect(() => {
    window.scrollTo(0, mainRef.current?.offsetTop);
  }, [currentPage]);

  return (
    <div className="wrapper">
      <FromToPage
        pages={[
          {
            label: `${langData.titles.Manufactures[currentLanguage]}`,
            value: "/job",
            active: true,
          },
        ]}
      />
      <div className={style.brand_see_all_title}>
        <h1>{langData.titles.Manufactures[currentLanguage]}</h1>
      </div>
      <div className={style.see_all_search} ref={mainRef}>
        <input
          onChange={(e) => handleChange(e.target.value)}
          placeholder={langData.header.searchPlaceholder[currentLanguage]}
          className={styles.searchInput}
        />
        <span className={styles.searchBtn}>
          {langData.buttons.search[currentLanguage]}
        </span>
      </div>
      {!error ? (
        <>
          {filterBrands && !isLoading ? (
            <>
              <div className={style.see_all_brands_list}>
                {filterBrands.map((item, index) => {
                  let start = currentPage > 1 ? (currentPage - 1) * 30 : 0;
                  let end = start + 30;
                  if (index < start || index >= end) return null;
                  return <SeeAllItems item={item} />;
                })}
              </div>
            </>
          ) : (
            <div className={style.brand_loadContent_list}>
              {Array.from({ length: 30 }).map((_, index) => (
                <div className={style.loadContent_item}>
                  <LoadContent
                    containerClassName={style.loadContent_animation}
                  />
                </div>
              ))}
            </div>
          )}
        </>
      ) : (
        <p>ERROR</p>
      )}

      {!!filterBrands?.length && (
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
      {/*<div className={style.pagination}>*/}
      {/*    <a onClick={handlePrevPage} className={style.prev_next_page}>*/}
      {/*        <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
      {/*            <path d="M9.31369 1.41443L1.76807 8.96006L9.31369 16.5057" stroke="#282828" strokeWidth="2"></path>*/}
      {/*        </svg>*/}
      {/*    </a>*/}
      {/*    {generateVisiblePageNumbers().map((pageNumber, index) => (*/}
      {/*        <React.Fragment key={index}>*/}
      {/*            {pageNumber === null ? (*/}
      {/*                <span key={`ellipsis-${index}`} className={style.pagination_sp}>*/}
      {/*    ....*/}
      {/*  </span>*/}
      {/*            ) : (*/}
      {/*                <a*/}
      {/*                    key={pageNumber}*/}
      {/*                    onClick={() => handlePageClick(pageNumber)}*/}
      {/*                    className={`${style.pagination_sp} ${pageNumber === currentPage ? style.pagination_sp_active : ''}`}*/}
      {/*                >*/}
      {/*                    {pageNumber}*/}
      {/*                </a>*/}
      {/*            )}*/}
      {/*        </React.Fragment>*/}
      {/*    ))}*/}

      {/*    <a onClick={handleNextPage} className={style.prev_next_page}>*/}
      {/*        <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
      {/*            <path d="M1.68631 16.5057L9.23193 8.96011L1.68631 1.41448" stroke="#282828" strokeWidth="2"></path>*/}
      {/*        </svg>*/}
      {/*    </a>*/}
      {/*</div>*/}
    </div>
  );
};
export default BrandSeeAll;
