import React, { useEffect, useState } from "react";
import FromToPage from "../../components/FromToPage";
import langData from "../../lang/data";
import { useDispatch, useSelector } from "react-redux";
import style from "./style..module.css";
import { getPrivacyPolicy } from "../../store/privacyPolicy/privacyPolicyFetch";

const PrivacyPolicy = () => {
  const { currentLanguage } = useSelector(({ main }) => main);
  const { isLoading, error, policyList } = useSelector(
    (state) => state.PrivacyPolicySlice
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPrivacyPolicy());
  }, []);

  return (
    <div className="wrapper">
      <FromToPage
        pages={[
          {
            label: langData.titles.PrivacyPolicy[currentLanguage],
            value: "/privacy",
            active: true,
          },
        ]}
      />
      <div className={style.privacy_policy_body}>
        <h1>{langData.titles.PrivacyPolicy[currentLanguage]}</h1>
        <div className={style.about_mini_description}>
          {!isLoading && policyList ? (
            <>
              <p
                className={style.privacy_policy_description}
                dangerouslySetInnerHTML={{
                  __html: policyList?.["description_" + currentLanguage],
                }}
              />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
