import React, { memo, useRef } from "react";
import styles from "./style.module.css";
import { useSelector, useDispatch } from "react-redux";
import { setCompareSearch } from "../../../../../../store/MainSlice";
import UseOutSideClick from "../../../../../../helpers/UseOutSideClick";
import UseCalcPrice from "../../../../../../helpers/UseCalcPrice";

export default memo(function ComapreSearchModal({
  compareProducts,
  setCompareProducts,
}) {
  const { compareSearch, currentCurrency } = useSelector(({ main }) => main);
  const containerRef = useRef();
  const dispatch = useDispatch();

  UseOutSideClick(containerRef, () => dispatch(setCompareSearch(null)));
  if (!Object.keys(compareSearch || {}).length || compareSearch.length == 2)
    return null;
  return (
    <div ref={containerRef} className={styles.container}>
      <div className={styles.main}>
        {compareSearch?.products?.map((product) => (
          <div
            className={styles.searchedProduct}
            onClick={() => {
              dispatch(setCompareSearch(null));
              if (compareProducts.length < 2) {
                setCompareProducts([...compareProducts, product]);
              }
            }}
          >
            <div className={styles.searchedProdName}>
              <img
                className={styles.img}
                src={
                  process.env.REACT_APP_STORAGE_URL +
                  (product?.product?.gallary_images_api?.[0]?.images_source ||
                    product?.product?.thumbnail_image_source)
                }
                alt={product?.product?.product_name}
              />
              <div className={styles.nameCodeProds}>
                <span className={styles.name}>
                  {product?.product?.brand?.name}{" "}
                  {product?.product?.product_name}
                </span>
                <span className={styles.codeOfProd}>
                  00{product?.product?.li_product_id}
                </span>
              </div>
            </div>
            <div className={styles.searchedProdName}>
              {!product?.promo_price ? (
                <p className={styles.price}>
                  {UseCalcPrice(
                    product?.product?.average_price ||
                      product?.skus[0]?.selling_price,
                    currentCurrency
                  )}
                </p>
              ) : (
                <div className={styles.promoPriceContainer}>
                  <p className={styles.newPrice}>
                    {UseCalcPrice(product?.promo_price, currentCurrency)}
                  </p>
                  <div className={styles.oldPriceContainer}>
                    <p className={styles.oldPrice}>
                      {UseCalcPrice(
                        product?.skus[0]?.selling_price,
                        currentCurrency
                      )}
                    </p>

                    <span className={styles.line} />
                  </div>
                </div>
              )}
              {product?.product?.cashback ? (
                <div className={styles.cashbackContainer}>
                  <span className={styles.cashbackText}>Cashback</span>
                  <span className={styles.cashbackPrice}>
                    {UseCalcPrice(product?.product?.cashback, currentCurrency)}
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});
