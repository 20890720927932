import React from "react";

export default function MessageSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="168"
      height="119"
      viewBox="0 0 168 119"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.5586 79.7812C78.8652 84.6953 69.8438 84.6953 63.1504 79.7812L0 32.375V92.5C0 102.906 8.14844 111 18.625 111H130.375C140.561 111 149 102.906 149 92.5V32.375L85.5586 79.7812Z"
        fill="url(#paint0_radial_4315_58935)"
      />
      <path
        d="M135.031 0C142.598 0 149 6.35938 149 13.875C149 18.5 146.672 22.5469 143.18 25.1484L80.0293 72.2656C76.5371 74.8672 72.1719 74.8672 68.6797 72.2656L5.5293 25.1484C2.03711 22.5469 0 18.5 0 13.875C0 6.35938 6.11133 0 13.9688 0H135.031Z"
        fill="url(#paint1_radial_4315_58935)"
      />
      <circle
        cx="135.86"
        cy="86.8602"
        r="31.8602"
        fill="url(#paint2_radial_4315_58935)"
      />
      <path
        d="M124 86.9674L131.474 94.4661L146.89 79"
        stroke="white"
        strokeWidth="6.18644"
      />
      <defs>
        <radialGradient
          id="paint0_radial_4315_58935"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(98.6966 30.8333) rotate(119.641) scale(50.2119 60.0647)"
        >
          <stop stop-color="#FF616A" />
          <stop offset="1" stop-color="#E31335" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_4315_58935"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(98.6966 30.8333) rotate(119.641) scale(50.2119 60.0647)"
        >
          <stop stop-color="#FF616A" />
          <stop offset="1" stop-color="#E31335" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_4315_58935"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(124.237 69.2203) rotate(45) scale(12.0208 22.1409)"
        >
          <stop stop-color="#404040" />
          <stop offset="1" stop-color="#212121" />
        </radialGradient>
      </defs>
    </svg>
  );
}
