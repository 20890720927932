import React from "react";
import style from "../ModalJobs/style.module.css";
import lang from "../../../../lang/lang";
import { useSelector } from "react-redux";

const ModalError = ({ onClose }) => {
  const currentLanguage = useSelector(({ main }) => main.currentLanguage);
  return (
    <div className={style.modal_jobs_content_title}>
      <div onClick={() => onClose(false)} className={style.modal_jobs_close}>
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 1L16 16M16 1L1 16" stroke="#282828"></path>
        </svg>
      </div>
      <span>Տեղի ունեցավ սխալ , խնդրում ենք փորձեք մի փոքր ուշ ։</span>
      <div
        onClick={() => onClose(false)}
        className={style.modal_jobs_content_button}
      >
        {lang[currentLanguage].to_close}
      </div>
    </div>
  );
};

export default ModalError;
