import React from "react";
import CircleLoader from "../../../../components/CircleLoader";
export default function CartLoader() {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: "#fff",
        zIndex: 99999999999999,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <img
        src={require("../../../../assets/logo.png")}
        style={{
          width: "170px",
          height: "100px",
          objectFit: "contain",
        }}
      />
      <CircleLoader containerStyle={{ height: "100px" }} />
    </div>
  );
}
