import React from "react";
import { useSelector } from "react-redux";
import LoadContent from "../../../../components/LoadContent";

export default function FooterBanner() {
  const { footerBaner } = useSelector(({ home }) => home);
  const { currentLanguage } = useSelector(({ main }) => main);

  return (
    <div className="wrapper">
      {!footerBaner?.["image_" + currentLanguage] ? (
        <LoadContent
          containerStyle={{
            width: "100%",
            height: "auto",
            aspectRatio: "10 / 3.4",
          }}
        />
      ) : (
        <a
          href={
            process.env.REACT_APP_SERVER_URL +
            footerBaner?.href.slice(1, footerBaner?.href.length)
          }
        >
          <img
            style={{
              width: "100%",
              maxWidth: "100%",
              objectFit: "contain",
            }}
            src={
              process.env.REACT_APP_STORAGE_URL +
              footerBaner?.["image_" + currentLanguage]
            }
            alt="VLV"
          />
        </a>
      )}
    </div>
  );
}
