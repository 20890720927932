import React from "react";
import styles from "./style.module.css";
import LoadContent from "../../../../components/LoadContent";

const BankItems = ({ item }) => {
  return (
    <div className={styles.bankItems} key={item.id}>
      {!item ? (
        <LoadContent />
      ) : (
        <>
          <a href={item.creditPage} target="_blank">
            <img className={styles.bankItemsImage} src={item.img} alt="img" />
          </a>
          <span className={styles.bankItemsLink}>
            {item.phoneNumber} |
            <a href={item.href} target="_blank">
              {item.bankName}
            </a>
          </span>
        </>
      )}
    </div>
  );
};

export default BankItems;
