import React from "react";

export default function RuFlagSvg(props) {
  return (
    <svg
      width="18"
      height="11"
      viewBox="0 0 18 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0 0H18V11H0V0Z" fill="#FF4B55" />
      <path d="M0 0H18V7.33334H0V0Z" fill="#41479B" />
      <path d="M0 0H18V3.66666H0V0Z" fill="#F5F5F5" />
    </svg>
  );
}
