import React from "react";

export default function UserSvg(props) {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.85303 9.41174C9.78376 9.41174 10.6936 9.13575 11.4675 8.61866C12.2413 8.10157 12.8445 7.36662 13.2007 6.50673C13.5569 5.64685 13.6501 4.70065 13.4685 3.7878C13.2869 2.87495 12.8387 2.03645 12.1806 1.37832C11.5225 0.720192 10.6839 0.272001 9.7711 0.0904241C8.85825 -0.0911529 7.91205 0.00203899 7.05217 0.358215C6.19228 0.714391 5.45733 1.31755 4.94024 2.09143C4.42315 2.86531 4.14716 3.77514 4.14716 4.70587C4.1484 5.95356 4.6446 7.1498 5.52685 8.03205C6.4091 8.9143 7.60533 9.4105 8.85303 9.41174ZM8.85303 1.56863C9.47351 1.56863 10.0801 1.75262 10.596 2.09735C11.1119 2.44207 11.514 2.93204 11.7515 3.5053C11.9889 4.07856 12.051 4.70935 11.93 5.31792C11.8089 5.92648 11.5101 6.48549 11.0714 6.92424C10.6326 7.36299 10.0736 7.66179 9.46507 7.78284C8.85651 7.90389 8.22571 7.84176 7.65245 7.60431C7.0792 7.36686 6.58923 6.96475 6.2445 6.44883C5.89978 5.93292 5.71578 5.32636 5.71578 4.70587C5.71578 3.87382 6.04631 3.07585 6.63466 2.4875C7.22301 1.89916 8.02098 1.56863 8.85303 1.56863Z"
        fill="#282828"
      ></path>
      <path
        d="M8.85301 11.7646C6.66962 11.7668 4.57636 12.5484 3.03247 13.9379C1.48858 15.3274 0.620159 17.2113 0.617737 19.1764C0.617737 19.3948 0.714141 19.6043 0.885743 19.7587C1.05734 19.9132 1.29009 19.9999 1.53277 19.9999C1.77545 19.9999 2.00819 19.9132 2.17979 19.7587C2.35139 19.6043 2.4478 19.3948 2.4478 19.1764C2.4478 17.6475 3.12263 16.1812 4.32384 15.1001C5.52505 14.0191 7.15424 13.4117 8.85301 13.4117C10.5518 13.4117 12.181 14.0191 13.3822 15.1001C14.5834 16.1812 15.2582 17.6475 15.2582 19.1764C15.2582 19.3948 15.3546 19.6043 15.5262 19.7587C15.6978 19.9132 15.9306 19.9999 16.1733 19.9999C16.4159 19.9999 16.6487 19.9132 16.8203 19.7587C16.9919 19.6043 17.0883 19.3948 17.0883 19.1764C17.0859 17.2113 16.2174 15.3274 14.6736 13.9379C13.1297 12.5484 11.0364 11.7668 8.85301 11.7646Z"
        fill="#282828"
      ></path>
    </svg>
  );
}
