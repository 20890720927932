
import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from "../request";

export const salesDetailsFetch = createAsyncThunk('categories/fetchBrands',
    async (id, { rejectWithValue }) => {

        try {
            const _token = document.querySelector('meta[name="_token"]')?.content;
            const response = await axiosInstance.post( `sale/${id}`, {
                login: 'VlvShopA',
                password: 'oL0rG6vQ6k',
                token: 'quHRhfW9R7SbNFUCEBEBmzCRbIa5Z3',
                _token,
            });
            return  response.data
        } catch (error) {
            return rejectWithValue(error.message,'error postHeaderCategories');
        }
    }
);
