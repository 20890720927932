import React from "react";
import style from "./style.module.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const BrandCategoriesItem = ({ list, onClick }) => {
  const currentLanguage = useSelector(({ main }) => main.currentLanguage);

  return (
    <div className={style.brand_categories_item}>
      {list.map((item, index) => {
        return (
          <Link
            to={`${
              currentLanguage !== "hy" ? "/" + currentLanguage : ""
            }/category/filter/${item.slug}`}
            key={index}
            className={style.brands_simple}
          >
            {item["name_" + currentLanguage]}
          </Link>
        );
      })}
    </div>
  );
};

export default BrandCategoriesItem;
