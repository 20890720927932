import * as React from "react";

const StoryMute = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    fill="none">
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.5}
      d="M16.958 7.708 9.25 13.875H3.083v9.25H9.25l7.708 6.167V7.708ZM35.458 13.875l-9.25 9.25M26.208 13.875l9.25 9.25"
    />
  </svg>
);
export default StoryMute;
