import React from "react";

export default function ArmFlagSvg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="11"
      viewBox="0 0 18 11"
      fill="none"
    >
      <path d="M0 0H18V11H0V0Z" fill="#FFBD00" />
      <path d="M0 0H18V7.33334H0V0Z" fill="#1700B3" />
      <path d="M0 0H18V3.66666H0V0Z" fill="#FF1E00" />
    </svg>
  );
}
