import React, { useLayoutEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShopCartProductAnimation } from "../../store/MainSlice";

export default function AddToCartAnimation() {
  const { shopCartPositions, shopCartProductAnimation } = useSelector(
    ({ main }) => main
  );
  const imgRef = useRef();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    if (window.innerWidth < 993) {
      if (imgRef.current) {
        setTimeout(() => {
          imgRef.current.style.top = "calc(100vh - 120px)";
          imgRef.current.style.left = "calc(50vw - 82px)";
          imgRef.current.style.opacity = 0.5;
          imgRef.current.style.transform = "scale(0.5)";
          setTimeout(() => {
            dispatch(setShopCartProductAnimation(null));
          }, 800);
        }, 0);
      }
    } else {
      if (imgRef.current && Object.keys(shopCartPositions || {}).length) {
        setTimeout(() => {
          imgRef.current.style.top = shopCartPositions?.top - 30 + "px";
          imgRef.current.style.left = shopCartPositions?.left - 20 + "px";
          imgRef.current.style.opacity = 0.5;
          imgRef.current.style.transform = "scale(0.5)";
          setTimeout(() => {
            dispatch(setShopCartProductAnimation(null));
          }, 800);
        }, 0);
      }
    }
  }, [dispatch, imgRef, shopCartPositions, shopCartProductAnimation]);

  if (!Object.keys(shopCartProductAnimation || {}).length) return null;
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 999999,
        pointerEvents: "none",
      }}>
      <div
        ref={imgRef}
        style={{
          position: "absolute",
          top: shopCartProductAnimation?.position?.top,
          left: shopCartProductAnimation.position?.left,
          transition: "all 800ms ease-out",
          opacity: 1,
          transfrom: "scale(1)",
          width: "164px",
          height: "auto",
        }}>
        <img
          src={
            process.env.REACT_APP_STORAGE_URL + shopCartProductAnimation.image
          }
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
          }}
          alt={""}
        />
      </div>
    </div>
  );
}
