import React, { useLayoutEffect, useState } from "react";
import styles from "./style.module.css";
import lang from "../../../../../../lang/lang";
import { filterProducts } from "../../../../../../store/SearchPageSlice";
import { useDispatch } from "react-redux";

export default function FilterItemColor({
  title,
  data,
  currentLanguage,
  removeAttribute,
  addAttribute,
  activeAttributes,
}) {
  const [opened, setOpened] = useState(false);
  const [seeMore, setSeeMore] = useState(false);
  useLayoutEffect(() => {
    if (activeAttributes.length) {
      setOpened(true);
    }
  }, []);
  const dispatch = useDispatch();
  if (data?.length <= 1) return null;
  return (
    <div className={styles.container}>
      <div className={styles.titleBlock} onClick={() => setOpened(!opened)}>
        <span className={styles.title}>{title}</span>
        <span
          className={styles.arrow}
          style={{
            transform: opened ? "rotate(0deg)" : "rotate(180deg)",
          }}
        >
          <svg
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.0293 5L4.99991 0.97083L9.02908 4.94145"
              stroke="#282828"
            ></path>
          </svg>
        </span>
      </div>
      {opened && (
        <div className={styles.dropDownBlock}>
          {!!data?.length &&
            data?.map((item, index) => {
              const include = activeAttributes?.includes(item.id);
              return (
                <div
                  key={index}
                  className={styles.eachItem}
                  onClick={() => {
                    if (include) {
                      removeAttribute(item.id);
                      dispatch(filterProducts(item.attribute_id));
                    } else {
                      addAttribute(item.id, item.attribute_id);
                      dispatch(filterProducts(item.attribute_id));
                    }
                  }}
                >
                  <div
                    className={styles.checkBox}
                    style={{
                      backgroundColor: item.value_en.toLowerCase(),
                    }}
                  >
                    <span>
                      {include ? (
                        <svg
                          width="9"
                          height="6"
                          viewBox="0 1 9 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 2.5L3.5 5L8 0.5"
                            stroke={
                              item.value_en.toLowerCase() == "white"
                                ? "#000"
                                : "#fff"
                            }
                          ></path>
                        </svg>
                      ) : null}
                    </span>
                  </div>

                  <span className={styles.label}>
                    {item?.["value_" + currentLanguage]}
                  </span>
                </div>
              );
            })}
          {data?.length > 3 && (
            <span
              className={styles.seeMore}
              onClick={() => setSeeMore(!seeMore)}
            >
              {lang[currentLanguage][seeMore ? "to_close" : "see_more"]}
            </span>
          )}
        </div>
      )}
    </div>
  );
}
