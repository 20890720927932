import React from "react";
import {useSelector} from "react-redux";
import style from "./style..module.css"
import lang from "../../../../lang/lang";

const UserEditInfo = () => {
    const {currentLanguage} = useSelector(({main}) => main);

    const handleChange = (e) => {

    }
    const handleClick = (e) => {
        e.preventDefault()

    }

    return <form className={style.personal_info_form} action="">
        <span>{lang[currentLanguage].personal_info}</span>

        <div className={style.add_personal_inputs}>

            <input
                type="text"
                name={'firstName'}
                onChange={handleChange}
                placeholder={lang[currentLanguage].first_name }
            />

        </div>

        <div className={style.add_personal_inputs}>

            <input
                type="text"
                name={'lastName'}
                onChange={handleChange}
                placeholder={lang[currentLanguage].last_name}
            />

        </div>

        <div className={style.add_personal_inputs}>
            <input
                type="text"
                name={'email'}
                onChange={handleChange}
                placeholder={lang[currentLanguage].email }
            />
        </div>

        <div className={style.add_personal_inputs}>

            <input
                type="number"
                name={'number'}
                onChange={handleChange}
                placeholder={lang[currentLanguage].tel_time }

            />

        </div>




        <button onClick={handleClick} className={style.order_submit}>{lang[currentLanguage].save}</button>
    </form>

};

export default UserEditInfo;
