import React from "react";
import style from "./style.module.css";
import checkBase64 from "../../../helpers/checkBase64";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const SeeAllItems = ({ item }) => {
  const currentLanguage = useSelector(({ main }) => main.currentLanguage);
  return (
    <div className={style.brand_see_all_item}>
      <Link
        to={`${
          currentLanguage !== "hy" ? "/" + currentLanguage : ""
        }/brand/${item.slug}/category`}
        className={style.brands_img_item}
      >
        <img
          src={
            item?.logo
              ? checkBase64(item?.logo)
                ? "data:image/png;base64," + item.logo
                : process.env.REACT_APP_STORAGE_URL + item.logo
              : "https://vlv.am/public/frontend/default/img/brand_image.png"
          }
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src =
              "https://vlv.am/public/frontend/default/img/brand_image.png";
          }}
          alt={item.name + " " + item.name}
        />
      </Link>
      <a
        href="https://vlv.am/brand/hisense?item=brand"
        className={style.nameof_brand_item}
      >
        {" "}
        {item.name}
      </a>
    </div>
  );
};

export default SeeAllItems;
