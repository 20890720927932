import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";  

export const categoryThunk = createAsyncThunk(
  "colors",
  async ({ categoryId, productsParams, isDynamic, searchPage }, thunkAPI) => {
    const filteredObject = Object.fromEntries(
      Object.entries(productsParams).filter(([key, value]) => !!value)
    );

    try {
      const response = axios.post(
          `https://v1.vlv.am/api/${isDynamic ? "page/search" : "category-test"}/${categoryId}`,
        {
          login: process.env.REACT_APP_LOGIN,
          password: process.env.REACT_APP_PASSWORD,
          token: process.env.REACT_APP_TOKEN,
        },
        {
          params: { 
            slug:categoryId,
            ...filteredObject,
          },
        }
      );

      const oldState = await thunkAPI.getState();
      const data = { ...response.data.data };
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
