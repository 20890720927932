import axiosInstance from "../request";

import { createAsyncThunk } from '@reduxjs/toolkit';

export const getJobsPageInfo = createAsyncThunk(
    'jobs/fetchJobs',
    async (_, thunkAPI) => {
        try {
            const response = await axiosInstance.post("/job", {
                login: process.env.REACT_APP_LOGIN,
                password: process.env.REACT_APP_PASSWORD,
                token: process.env.REACT_APP_TOKEN,
            });
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

