import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const getFeaturedFetch = createAsyncThunk(
  "featured/fetchFeatured",
  async (_, thunkAPI) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL + "api/search/top-products",
        {
          login: process.env.REACT_APP_LOGIN,
          password: process.env.REACT_APP_PASSWORD,
          token: process.env.REACT_APP_TOKEN,
        }
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getCategoriesFetch = createAsyncThunk(
  "categories/fetchCategories",
  async (_, thunkAPI) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL + "api/search/categories",
        {
          login: process.env.REACT_APP_LOGIN,
          password: process.env.REACT_APP_PASSWORD,
          token: process.env.REACT_APP_TOKEN,
        }
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
