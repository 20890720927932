import React, { useEffect } from "react";
import styles from "./style.module.css";
import FromToPage from "../../components/FromToPage";
import { useDispatch, useSelector } from "react-redux";
import lang from "../../lang/lang";
import { getFavoritesPageproducts } from "../../store/MainSlice";
import CircleLoader from "../../components/CircleLoader";
import FavoriteNull from "./components/FavoriteNull";
import ProductCard from "../../components/ProductCard/index";

export default function FavoritePage() {
  const { currentLanguage, favoritesPageproducts, favorites } = useSelector(
    ({ main }) => main
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFavoritesPageproducts());
  }, []);

  return (
    <div className="wrapper">
      <FromToPage
        pages={[
          {
            value: "",
            label: lang[currentLanguage].Favorites,
            active: true,
          },
        ]}
      />
      <h1 className={styles.title}>{lang[currentLanguage].Favorites}</h1>
      {!Object.keys(favoritesPageproducts || {}).length ? (
        <div>
          <CircleLoader />
        </div>
      ) : favoritesPageproducts?.error || !favorites?.length ? (
        <FavoriteNull />
      ) : (
        <div className={styles.prodoctsList}>
          {favoritesPageproducts?.products.map((product) => {
            if (favorites.includes(product.id)) {
              return (
                <ProductCard
                  product={product}
                  flexContainer
                />
              );
            }
          })}
        </div>
      )}
    </div>
  );
}
