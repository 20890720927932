import React, { useRef } from "react";
import styles from "./style.module.css";
import UseOutSideClick from "../../../../helpers/UseOutSideClick";
import lang from "../../../../lang/lang";
import { useSelector } from "react-redux";

export default function InfoModal({ modalType, closeModal }) {
  const currentLanguage = useSelector(({ main }) => main.currentLanguage);
  const mainRef = useRef();
  UseOutSideClick(mainRef, closeModal);
  if (!modalType) return null;
  return (
    <div className={styles.background}>
      <div className={styles.main} ref={mainRef}>
        <span className={styles.closeBtn} onClick={closeModal}>
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 1L16 16M16 1L1 16" stroke="#282828"></path>
          </svg>
        </span>
        <span className={styles.title}>
          {modalType === "online"
            ? lang[currentLanguage].online_price_info
            : lang[currentLanguage].credit_info_product}
        </span>
        <span className={styles.description}>
          {modalType === "online"
            ? lang[currentLanguage].online_price_prod_popup
            : lang[currentLanguage].cred_info_prod_popup}
        </span>
        <div className={styles.btn} onClick={closeModal}>
          <span>{lang[currentLanguage].to_close}</span>
        </div>
      </div>
    </div>
  );
}
