import {createSlice} from "@reduxjs/toolkit";
import { salesDetailsFetch} from "./salesDetailsFetch";

const initialState = {
    isLoading:false,
    error:'',
    detailsList: [],

}
const SalesDetailsSlice = createSlice({
    name: 'detailsList',
    initialState,
    reducers:{},
    extraReducers:{
        [salesDetailsFetch.pending.type]: (state) => {
            state.isLoading = true
        },
        [salesDetailsFetch.fulfilled.type]:(state, action)=>{
            state.isLoading = false
            state.error = ''
            state.detailsList = action.payload
        },
        [salesDetailsFetch.rejected.type]:(state, action)=>{
            state.isLoading = false
            state.error = action.payload
        }

    }
})
export const {} = SalesDetailsSlice.actions

export default SalesDetailsSlice.reducer
