import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import style from "./style..module.css";
import lang from "../../lang/lang";
import useOrderValidate from "./orderValidate";
import axios from "axios";
import Modal from "../SearchNull/components/Modal";
import MyModal from "../../components/MyModal";
import ModalError from "../Jobs/components/ModalError";

const Order = () => {
  const { currentLanguage } = useSelector(({ main }) => main);
  const [openModal, setOpenModal] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);

  const { validation, personInfo, errorText, setPersonInfo, setErrorText } =
    useOrderValidate();
  const handleChange = (e) => {
    setPersonInfo({ ...personInfo, [e.target.name]: e.target.value });
    setErrorText({ ...errorText, [e.target.name]: "" });
  };
  const postData = async () => {
    let formData = new FormData();
    try {
      const _token = document.querySelector('meta[name="_token"]').content;
      formData.append("login", "VlvShopA");
      formData.append("password", "oL0rG6vQ6k");
      formData.append("token", "quHRhfW9R7SbNFUCEBEBmzCRbIa5Z3");
      formData.append("name", personInfo.firstName);
      formData.append("category", personInfo.category);
      formData.append("brand", personInfo.manufacturer);
      formData.append("number", personInfo.number);
      formData.append("email", personInfo.email);
      if (personInfo.comment) {
        formData.append("search", personInfo.comment);
      }

      const response = await axios.post(
        "https://vlv.am/api/order-not-found-product/store",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-CSRF-Token": _token,
          },
        }
      );
      if (response) {
        setOpenModal(true);
      }
    } catch (error) {
      setOpenModalError(true);
   
    }
  };

  const handleClick = async (e) => {
    e.preventDefault();
    if (validation()) {
      await postData();
    }
  };

  return (
    <>
      <form className={style.order_form} action="">
        <span>{lang[currentLanguage].order}</span>
        <div className={style.add_personal_inputs}>
          <div className={style.add_personal_inputs}>
            <input
              type="text"
              value={personInfo.firstName}
              name={"firstName"}
              onChange={handleChange}
              className={` ${errorText.firstName && style.inputError}`}
              placeholder={lang[currentLanguage].first_name + " *"}
            />
          </div>
          <div className={style.order_inputs_name}>
            <input
              type="text"
              name={"lastName"}
              value={personInfo.lastName}
              onChange={handleChange}
              placeholder={lang[currentLanguage].last_name}
            />
          </div>
        </div>

        <div className={style.add_personal_inputs}>
          <input
            type="text"
            name={"email"}
            value={personInfo.email}
            onChange={handleChange}
            className={` ${errorText.email && style.inputError}`}
            placeholder={lang[currentLanguage].email + " *"}
          />
        </div>

        <div className={style.add_personal_inputs}>
          <input
            type="number"
            value={personInfo.number}
            name={"number"}
            onChange={handleChange}
            className={` ${errorText.number && style.inputError}`}
            placeholder={"Բջջային հեռ. " + " *"}
          />
        </div>

        <div className={style.add_personal_inputs}>
          <input
            type="text"
            name={"manufacturer"}
            value={personInfo.manufacturer}
            onChange={handleChange}
            className={` ${errorText.manufacturer && style.inputError}`}
            placeholder={lang[currentLanguage].brand + " *"}
          />
        </div>

        <div className={style.add_personal_inputs}>
          <input
            type="text"
            name={"category"}
            value={personInfo.category}
            onChange={handleChange}
            className={` ${errorText.category && style.inputError}`}
            placeholder={lang[currentLanguage].Category + " *"}
          />
        </div>

        <div className={style.add_personal_inputs}>
          <textarea
            value={personInfo.comment}
            onChange={handleChange}
            name="comment"
            className={style.textarea_comment_order}
            cols="30"
            rows="5"
            placeholder={lang[currentLanguage].comment_order}
          ></textarea>
        </div>

        <button onClick={handleClick} className={style.order_submit}>
          {lang[currentLanguage].order_state + " *"}
        </button>
      </form>

      {openModal && (
        <MyModal onClose={setOpenModal}>
          <div
            onClick={() => setOpenModal(false)}
            className={style.close_thnx_attent}
          >
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 1L16 16M16 1L1 16" stroke="#282828"></path>
            </svg>
          </div>
          <div className={style.success_image}>
            <img
              alt=""
              src={process.env.REACT_APP_STORAGE_URL + "img/mail_accept.svg"}
            />
          </div>
          <div className={style.success_text}>
            {lang[currentLanguage].thanks_for_order_wait_for_call}
          </div>
          <div
            onClick={() => setOpenModal(false)}
            className={style.success_btn}
          >
            {lang[currentLanguage].to_close}
          </div>
        </MyModal>
      )}
      {openModalError && (
        <MyModal onClose={setOpenModalError}>
          <ModalError
            closeText={lang[currentLanguage].to_close}
            title={lang[currentLanguage].an_error_occurred}
            onClose={setOpenModalError}
          />
        </MyModal>
      )}
    </>
  );
};

export default Order;
