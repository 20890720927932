import axiosInstance from "../request";
import {setJobPage} from "../MainSlice";

import { createAsyncThunk } from '@reduxjs/toolkit';

export const getContactInfo = createAsyncThunk(
    'contact/fetchContact',
    async (_, thunkAPI) => {
        try {
            const response = await axiosInstance.post("/contact-us", {
                login: process.env.REACT_APP_LOGIN,
                password: process.env.REACT_APP_PASSWORD,
                token: process.env.REACT_APP_TOKEN,
            });

            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

