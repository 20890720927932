import React, { useEffect, useState } from "react";
import style from "./style..module.css";
import FromToPage from "../../../components/FromToPage";
import lang from "../../../lang/lang";
import { useSelector } from "react-redux";
import UserMenu from "../../../components/Header/components/UserMenu";
import Map from "../../../components/Map";
import BackRoute from "../components/BackRoute";
import useAddressValidate from "./addressValidate";
import UserAddressItems from "../components/userAddressItems";
import DeleteModalInfo from "../components/deleteModalInfo";
import MyModal from "../../../components/MyModal";

const MyAddresses = () => {
  const { currentLanguage, profilePageInfo } = useSelector(({ main }) => main);
  const { validation, personInfo, errorText, setPersonInfo, setErrorText } =
    useAddressValidate();
  const [active, setActive] = useState(false);
  const [editActive, setEditActive] = useState(false);
  const [removeActive, setRemoveActive] = useState(false);

  const [userAddress, setUserAddress] = useState([]);

  const handleChange = (e) => {
    setPersonInfo({ ...personInfo, [e.target.name]: e.target.value });
    setErrorText({ ...errorText, [e.target.name]: "" });
  };
  const createAddresses = () => {
    setActive(!active);
  };

  const handleClick = (e) => {
    e.preventDefault();
    if (validation()) {
      let user = {
        address: personInfo.province,
      };
      setUserAddress([...userAddress, user]);
      setPersonInfo({
        ...personInfo,
        province: "",
        number: "",
        lastName: "",
        email: "",
        firstName: "",
      });
    }
  };

  const editHandler = () => {
    setEditActive(!editActive);
  };
  const removeHandler = () => {
    setRemoveActive(!removeActive);
  };

  return (
    <div className={style.user_page}>
      <div className="wrapper">
        <FromToPage
          pages={[
            {
              label: lang[currentLanguage].Personal_information,
              value: "",
              active: true,
            },
          ]}
        />

        <div className={style.user_page_body}>
          <UserMenu containerStyle={true} />
          <div className={style.addresses_content}>
            <div className={style.addresses_title}>
              <BackRoute />
              <span className={style.user_second_title}>
                {lang[currentLanguage].my_addresses}
              </span>
            </div>

            <div className={style.user_loc_block}>
              <div
                className={
                  !active
                    ? style.if_user_has_address
                    : style.if_user_has_address_disabled
                }
              >
                <button
                  onClick={createAddresses}
                  className={style.add_another_addr}
                >
                  {lang[currentLanguage].add_address}
                </button>
              </div>
              {userAddress?.length ? (
                <UserAddressItems
                  removeHandler={removeHandler}
                  editHandler={editHandler}
                  addressList={userAddress}
                />
              ) : null}
              {editActive && (
                <div>
                  <button
                    onClick={editHandler}
                    className={style.edit_fills_btn}
                  >
                    {lang[currentLanguage].add_address}
                  </button>
                </div>
              )}
              <div
                className={
                  !active ? style.for_delivery : style.for_delivery_active
                }
              >
                <span className={style.fill_show}>
                  Լրացրեք ներքևում նշված դաշտը կամ նշեք այն տեղաշարժելով կարմիր
                  կետը
                </span>

                <form className={style.addresses_form} action="">
                  <div className={style.add_personal_inputs_block}>
                    <div className={style.add_personal_inputs_name}>
                      <div className={style.add_personal_inputs}>
                        <input
                          type="text"
                          name={"firstName"}
                          value={personInfo.firstName}
                          onChange={handleChange}
                          className={` ${
                            errorText.firstName && style.inputError
                          }`}
                          placeholder={lang[currentLanguage].first_name + " *"}
                        />
                      </div>
                      <div className={style.add_personal_inputs}>
                        <input
                          name={"lastName"}
                          type="text"
                          value={personInfo.lastName}
                          onChange={handleChange}
                          placeholder={lang[currentLanguage].last_name}
                        />
                      </div>
                    </div>

                    <div className={style.add_personal_inputs}>
                      <input
                        type="text"
                        name={"email"}
                        value={personInfo.email}
                        onChange={handleChange}
                        className={` ${errorText.email && style.inputError}`}
                        placeholder={lang[currentLanguage].email + " *"}
                      />
                    </div>

                    <div className={style.add_personal_inputs}>
                      <input
                        type="number"
                        name={"number"}
                        value={personInfo.number}
                        onChange={handleChange}
                        className={` ${errorText.number && style.inputError}`}
                        placeholder={lang[currentLanguage].tel_time + " *"}
                      />
                    </div>
                    <div className={style.add_personal_inputs}>
                      <input
                        type="text"
                        value={personInfo.province}
                        name="province"
                        onChange={handleChange}
                        className={` ${errorText.province && style.inputError}`}
                        placeholder={lang[currentLanguage].State}
                      />
                    </div>
                  </div>
                </form>

                <div className={style.addresses_form_maps}>
                  <Map
                    height="388px"
                    input={<div className={style.add_personal_inputs}></div>}
                  ></Map>
                </div>

                <div className={style.add_personal_inputs}>
                  <input
                    type="number"
                    name={"number"}
                    placeholder={lang[currentLanguage].flat_room}
                  />
                </div>
                <button onClick={handleClick} className={style.save_fills_btn}>
                  {" "}
                  {!editActive
                    ? lang[currentLanguage].save
                    : lang[currentLanguage].refresh}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {removeActive ? (
        <MyModal onClose={setRemoveActive} openModal={removeActive}>
          <DeleteModalInfo
            onClose={setRemoveActive}
            currentLanguage={currentLanguage}
          />
        </MyModal>
      ) : null}
    </div>
  );
};
export default MyAddresses;
