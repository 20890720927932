import React, { useState } from 'react'
import styles from './style.module.css'
import FacebookSvg from 'assets/SVG/FacebookSvg'
import {ReactComponent as GoogleSvg} from  'assets/SVG/googleSvg.svg'
import { useDispatch, useSelector } from 'react-redux'
import { selectMain } from 'store/selector'
import langData from 'lang/data'
import EyeSvg from "assets/SVG/EyeSvg";
import CloseEyeSvg from "assets/SVG/CloseEyeSvg";
import { UserLogin, setShowLoginModal } from 'store/MainSlice'
import { useNavigate } from 'react-router-dom'

const LoginCart = () => {

  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [showPass, setShowPass] = useState(false);

  
  const {currentLanguage} = useSelector(selectMain);
  const dispatch = useDispatch();
  
  const handleSubmit = (event) => {
    event.preventDefault()
    setLoginError(!login);
    setPasswordError(!password);
    if (login && password) dispatch(UserLogin({ login, password }));
  };

  return (
    <div className={styles.loginCart}>
      <div className={styles.loginCartContainer}>
         <div className={styles.loginCartTitle}>
          <h3>{langData?.loginModal?.loginPage?.[currentLanguage]}</h3>
         </div>
         <div className={styles.loginCartform}>
           <form>
             <p>{langData?.loginModal?.enterLogin?.[currentLanguage]}</p>
             <div className={styles.loginCartInputs}>
               <div className={styles.loginCartLogin}>
                 <input
                    type="email"
                    placeholder={langData.loginModal.email?.[currentLanguage]}
                    className={`${styles.input} ${loginError && styles.inputError}`}
                    value={login}
                    onChange={(e) => setLogin(e.target.value)}
                  />
               </div>
               <div className={styles.loginCartPassword}>
               <input
                    type={showPass ? "text" : "password"}
                    placeholder={langData.loginModal.password[currentLanguage]}
                    className={`${styles.input} ${passwordError && styles.inputError}`}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <span className={styles.eye} onClick={() => setShowPass(!showPass)}>
                    {!showPass ? <CloseEyeSvg /> : <EyeSvg />}
                  </span>
               </div>
             </div>
             <div className={styles.loginCartButtons}>
                <button   onClick={handleSubmit} className={styles.loginCartSignIn}>{langData.loginModal.login[currentLanguage]}</button>
                <button type="button" onClick={() => {
                   dispatch(setShowLoginModal(true))
                }} className={styles.loginCartSignUp}>{langData.loginModal.signup[currentLanguage]}</button>
             </div>
           </form>
         </div>
         {/* <div className={styles.loginCartFooter}>
             <p>{langData?.loginModal?.enterLoginSocial?.[currentLanguage]}</p>
             <div className={styles.loginCartIcons}>
                <span>
                  <FacebookSvg/>
                </span>
               <span><GoogleSvg/></span>
             </div>
         </div> */}
      </div>
    </div>
  )
}

export default LoginCart