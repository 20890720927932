import React from "react";
import style from "../../style..module.css";
import LoadContent from "../../../../components/LoadContent";
import ProductCard from "../../../../components/ProductCard";
import ProductsWithSlide from "../../../../components/ProductsWithSlide";

import DetailsBigBanner from "../../components/DetailsBigBanner";
import DescriptionTitle from "../../components/DescriptionTitle";
import BanerSlider from "../../../../components/BanerSlider";
import FromToPage from "../../../../components/FromToPage";
import langData from "../../../../lang/data";
import styles from "../../../../components/BanerSlider/style.module.css";

const DetailsVue2 = ({ detailsList, isLoading, error, currentLanguage }) => {
  return (
    <div className="wrapper">
      <div className="style_viue_2">
        <FromToPage
          pages={[
            {
              label: langData.titles.Sales[currentLanguage],
              value: "",
              active: false,
            },
            {
              label: detailsList?.sale?.["name_" + currentLanguage],
              value: "",
              active: true,
            },
          ]}
        />

        <DetailsBigBanner
          isLoading={isLoading}
          currentLanguage={currentLanguage}
          detailsList={detailsList}
        />

        <div className={style.details_description}>
          {!isLoading && detailsList.sale ? (
            <>
              <p
                dangerouslySetInnerHTML={{
                  __html: detailsList.sale?.["description_" + currentLanguage],
                }}
              />
            </>
          ) : (
            <>
              {Array.from({ length: 3 }).map((_, index) => {
                return (
                  <div className={style.details_description_loader_item}>
                    <LoadContent
                      key={index}
                      containerClassName={style.details_description_loader}
                    />
                  </div>
                );
              })}
            </>
          )}
        </div>

        {!isLoading && detailsList?.sale ? (
          <DescriptionTitle
            currentLanguage={currentLanguage}
            detailsList={detailsList}
          />
        ) : null}

        <div className={style.details_all_prods_catalog}>
          {!isLoading && detailsList?.products_first_slider?.length ? (
            <>
              {detailsList?.products_first_slider.map((item, index) => {
                return (
                  <ProductCard changeWidth={true} key={index} product={item} />
                );
              })}
            </>
          ) : (
            <>
              {Array.from({ length: 20 }).map((_, index) => {
                return (
                  <LoadContent
                    key={index}
                    containerClassName={style.details_all_prods_catalog_loader}
                  />
                );
              })}
            </>
          )}
        </div>
        
        {!isLoading && detailsList?.sliders?.length ? (
          <div className={style.details_banner_slider}>
            <BanerSlider
              bannerSliders={detailsList.sliders}
              currentLanguage={currentLanguage}
            />
          </div>
        ) : isLoading ? (
          <LoadContent containerClassName={styles.loadContent} />
        ) : null}

        {detailsList?.products_second_sliderh ? (
          <>
            <ProductsWithSlide
              productsData={detailsList?.products_second_slider}
            />
          </>
        ) : null}
        <div className={style.details_footer_banners}>
          {!isLoading && detailsList.sale ? (
            <>
              <div className={style.details_footer_banners_item}>
                <img
                  src={
                    process.env.REACT_APP_STORAGE_URL +
                    detailsList.sale?.["slider_image1_" + currentLanguage]
                  }
                  alt=""
                />
              </div>
              <div className={style.details_footer_banners_item}>
                <img
                  src={
                    process.env.REACT_APP_STORAGE_URL +
                    detailsList.sale?.["slider_image2_" + currentLanguage]
                  }
                  alt=""
                />
              </div>
            </>
          ) : (
            <>
              <LoadContent
                containerClassName={style.details_footer_banners_loader}
              />
              <LoadContent
                containerClassName={style.details_footer_banners_loader}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DetailsVue2;
