import React, { useEffect, useRef, useState } from "react";
import style from "./style..module.css";
import FromToPage from "../../components/FromToPage";
import langData from "../../lang/data";
import { useDispatch, useSelector } from "react-redux";
import lang from "../../lang/lang";
import { getServiceFetch } from "../../store/service/serviceFetch";
import LoadContent from "../../components/LoadContent";

const Service = () => {
  const { currentLanguage } = useSelector(({ main }) => main);
  const infoOfBanksBlockRef = useRef(null);

  const { isLoading, error, serviceList } = useSelector(
    (state) => state.ServiceSlice
  );

  const dispatch = useDispatch();
  const [servicesType, setServicesType] = useState([]);

  useEffect(() => {
    dispatch(getServiceFetch());
  }, []);

  const clickHandler = (item) => {
    infoOfBanksBlockRef.current?.scrollIntoView({
      block: "start",
    });
    if (item.service_type) {
      setServicesType(item.service_type);
    }
  };

  return (
    <div className="wrapper">
      <FromToPage
        pages={[
          {
            label: langData.footer.To_the_buyer.menu.Service[currentLanguage],
            value: "/service",
            active: true,
          },
        ]}
      />

      {!isLoading && serviceList.mainPhoto ? (
        <div className={style.general_credit_banner}>
          <img
            alt="img"
            src={
              process.env.REACT_APP_STORAGE_URL +
              serviceList?.mainPhoto?.["image_" + currentLanguage]
            }
          />
        </div>
      ) : (
        <LoadContent containerClassName={style.services_banner} />
      )}

      {!isLoading && serviceList.service_page ? (
        <div className={style.agreement_block_top}>
          <p
            dangerouslySetInnerHTML={{
              __html: serviceList.service_page?.["text_" + currentLanguage],
            }}
          ></p>
        </div>
      ) : (
        <>
          <div className={style.agreement_block_loader}>
            {Array.from({ length: 5 }).map((_, index) => {
              return (
                <LoadContent
                  key={index}
                  containerClassName={style.agreement_block_loader_item}
                />
              );
            })}
          </div>
        </>
      )}

      {!isLoading && serviceList.service_page ? (
        <div className={style.agreement_block}>
          <p
            dangerouslySetInnerHTML={{
              __html:
                serviceList.service_page?.["banners_" + currentLanguage][0]
                  .title,
            }}
          ></p>
        </div>
      ) : (
        <>
          <div className={style.agreement_block_loader}>
            {Array.from({ length: 5 }).map((_, index) => {
              return (
                <LoadContent
                  key={index}
                  containerClassName={style.agreement_block_loader_item}
                />
              );
            })}
          </div>
        </>
      )}

      <div className={style.service_list_title}>
        <h1>{lang[currentLanguage].services_title}</h1>

        <div className={style.not_free_service}>
          {!isLoading && serviceList?.service ? (
            serviceList?.service.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => clickHandler(item)}
                  className={style.service_item}
                >
                  <div className={style.service_square}>
                    <img
                      alt="img"
                      src={
                        process.env.REACT_APP_STORAGE_URL +
                        item?.["icon_" + currentLanguage]
                      }
                    />
                  </div>
                  {item?.["name_" + currentLanguage]}
                </div>
              );
            })
          ) : (
            <>
              {Array.from({ length: 10 }).map((_, index) => {
                return (
                  <LoadContent
                    key={index}
                    containerClassName={style.not_free_service_loading_item}
                  />
                );
              })}
            </>
          )}
        </div>
      </div>
      {!!servicesType?.length && (
        <div ref={infoOfBanksBlockRef} className={style.info_of_banks_block}>
          <div className={style.info_tables}>
            <div className={style.bank_info_title}>
              {lang[currentLanguage].service_type_repair}
            </div>

            <>
              {servicesType.map((item, index) => {
                return (
                  <div key={index} className={style.term_title_inf}>
                    {item?.["type_" + currentLanguage]}
                  </div>
                );
              })}
            </>
          </div>

          <div className={style.service_tables_price}>
            <div className={style.bank_info_title}>
              {lang[currentLanguage].service_type_repair_price}
            </div>
            {servicesType && servicesType.length ? (
              <>
                {servicesType.map((item, index) => {
                  return (
                    <div key={index} className={style.term_title_inf}>
                      <span>{item?.["value_" + currentLanguage]}</span>
                    </div>
                  );
                })}
              </>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default Service;
