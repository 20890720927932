import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../request";

export const getUserThunk = createAsyncThunk("user", async (_, thunkAPI) => {
  try {
    const _token = document.querySelector('meta[name="_token"]')?.content; //
    const access_token = localStorage.getItem("access_token");
    const response = await axiosInstance.post(`/profile/dashboard`, {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
      _token: _token,
      access_token: access_token,
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getUserUpdateThunk = createAsyncThunk(
  "updateuser",
  async (updateUser, thunkAPI) => {
    try {
      const response = await axiosInstance.post(`/create-update-user-address`, {
        login: process.env.REACT_APP_LOGIN,
        password: process.env.REACT_APP_PASSWORD,
        token: process.env.REACT_APP_TOKEN,
        name: updateUser?.name,
        surname: updateUser?.surname,
        email: updateUser?.email,
        phone: updateUser?.phone,
        map_address: updateUser?.map_address,
        addres_type: updateUser?.addres_type,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
