import React, { useEffect } from "react";
import styles from "./style.module.css";
import { useDispatch, useSelector } from "react-redux";
import FilterItem from "./components/FilterItem";
import lang from "../../../../lang/lang";
import FilterItemColor from "./components/FilterItemColor";
import FilterItemPrice from "./components/FilterItemPrice";
import {
  addAttribute,
  addBrand,
  addCategory,
  addColor,
  filterProducts,
  removeAttribute,
  removeBrand,
  removeCategory,
  removeColor,
  setAttributes,
  setBrands,
  setCategories,
  setDefaultFilteres,
  setDiscount,
  setMaxPrice,
  setMinPrice,
  setSearch,
} from "../../../../store/SearchPageSlice";

export default function Filter() {
  const { currentLanguage } = useSelector(({ main }) => main);
  const {
    searchPageData,
    brands,
    categories,
    colors,
    attributes,
    discount,
    search,
    filterData,
  } = useSelector(({ searchPage }) => searchPage);
  const dispatch = useDispatch();

  const attributeVales = {};
  filterData?.attributeVales?.forEach((item) => {
    if (Array.isArray(attributeVales[item.attribute_id])) {
      attributeVales[item.attribute_id].push(item);
    } else {
      attributeVales[item.attribute_id] = [item];
    }
  });
  let newCategories = new Set(),
    newBrands = new Set(),
    newDiscount = false,
    newPrice = {
      min: 0,
      max: 0,
    };
  const filteredProductsForCategories = searchPageData.products?.filter(
    (product) => {
      if (search) {
        let returnNull = false;
        let keywordsArray = search.toLowerCase().split(" ");

        keywordsArray?.forEach((keyword) => {
          if (
            !product?.product?.brand?.name?.toLowerCase()?.includes(keyword) &&
            !product?.product?.product_name?.toLowerCase()?.includes(keyword) &&
            !product?.product?.li_product_id?.toString()?.includes(keyword)
          ) {
            returnNull = true;
          }
        });
        if (returnNull) return null;
      }

      if (brands.length && !brands?.includes(product.product.brand.id))
        return null;

      if (attributes.length) {
        let returnNull = true;
        attributes?.forEach?.((attrId) => {
          product.product?.variations?.forEach(({ attribute_value_id }) => {
            if (attribute_value_id == attrId) {
              returnNull = false;
            }
          });
        });
        if (returnNull) return null;
      }

      if (!!discount) {
        if (
          !(
            (product.product.online_price &&
              product.product.online_selling_price) ||
            product.promo_price ||
            product.recommended_retail_price
          )
        )
          return null;
      }
      const price =
        (product.product.online_price &&
          product.product.online_selling_price) ||
        product.promo_price ||
        product.recommended_retail_price ||
        product.skus[0].selling_price;

      let min, max;

      if (price < min || !min) {
        min = price;
      }
      if (price > max || !max) {
        max = price;
      }
      if (price < min || price > max) {
        return null;
      }

      if (colors.length) {
        let returnNull = true;
        colors.forEach((attrId) => {
          product.product?.variations?.forEach((color) => {
            if (color.attribute_value_id == attrId) {
              returnNull = false;
            }
          });
        });
        if (returnNull) return null;
      }
      return product;
    }
  );
  const filteredProductsForBrands = searchPageData.products?.filter(
    (product) => {
      if (search) {
        let returnNull = false;
        let keywordsArray = search.toLowerCase().split(" ");

        keywordsArray?.forEach((keyword) => {
          if (
            !product?.product?.brand?.name?.toLowerCase()?.includes(keyword) &&
            !product?.product?.product_name?.toLowerCase()?.includes(keyword) &&
            !product?.product?.li_product_id?.toString()?.includes(keyword)
          ) {
            returnNull = true;
          }
        });
        if (returnNull) return null;
      }

      if (
        categories?.length &&
        !categories?.includes(product?.categories[0]?.id)
      )
        return null;

      if (attributes.length) {
        let returnNull = true;
        attributes?.forEach?.((attrId) => {
          product.product?.variations?.forEach(({ attribute_value_id }) => {
            if (attribute_value_id == attrId) {
              returnNull = false;
            }
          });
        });
        if (returnNull) return null;
      }

      if (!!discount) {
        if (
          !(
            (product.product.online_price &&
              product.product.online_selling_price) ||
            product.promo_price ||
            product.recommended_retail_price
          )
        )
          return null;
      }
      const price =
        (product.product.online_price &&
          product.product.online_selling_price) ||
        product.promo_price ||
        product.recommended_retail_price ||
        product.skus[0].selling_price;

      let min, max;

      if (price < min || !min) {
        min = price;
      }
      if (price > max || !max) {
        max = price;
      }
      if (price < min || price > max) {
        return null;
      }

      if (colors.length) {
        let returnNull = true;
        colors.forEach((attrId) => {
          product.product?.variations?.forEach((color) => {
            if (color.attribute_value_id == attrId) {
              returnNull = false;
            }
          });
        });
        if (returnNull) return null;
      }
      return product;
    }
  );
  const filteredProductsForDiscount = searchPageData.products?.filter(
    (product) => {
      if (search) {
        let returnNull = false;
        let keywordsArray = search.toLowerCase().split(" ");

        keywordsArray?.forEach((keyword) => {
          if (
            !product?.product?.brand?.name?.toLowerCase()?.includes(keyword) &&
            !product?.product?.product_name?.toLowerCase()?.includes(keyword) &&
            !product?.product?.li_product_id?.toString()?.includes(keyword)
          ) {
            returnNull = true;
          }
        });
        if (returnNull) return null;
      }

      if (brands.length && !brands?.includes(product.product.brand.id))
        return null;
      if (
        categories?.length &&
        !categories?.includes(product?.categories[0]?.id)
      )
        return null;

      if (attributes.length) {
        let returnNull = true;
        attributes?.forEach?.((attrId) => {
          product.product?.variations?.forEach(({ attribute_value_id }) => {
            if (attribute_value_id == attrId) {
              returnNull = false;
            }
          });
        });
        if (returnNull) return null;
      }
      const price =
        (product.product.online_price &&
          product.product.online_selling_price) ||
        product.promo_price ||
        product.recommended_retail_price ||
        product.skus[0].selling_price;

      let min, max;

      if (price < min || !min) {
        min = price;
      }
      if (price > max || !max) {
        max = price;
      }
      if (price < min || price > max) {
        return null;
      }

      if (colors.length) {
        let returnNull = true;
        colors.forEach((attrId) => {
          product.product?.variations?.forEach((color) => {
            if (color.attribute_value_id == attrId) {
              returnNull = false;
            }
          });
        });
        if (returnNull) return null;
      }
      return product;
    }
  );
  const filteredProductsForPrice = searchPageData.products?.filter(
    (product) => {
      if (search) {
        let returnNull = false;
        let keywordsArray = search.toLowerCase().split(" ");

        keywordsArray?.forEach((keyword) => {
          if (
            !product?.product?.brand?.name?.toLowerCase()?.includes(keyword) &&
            !product?.product?.product_name?.toLowerCase()?.includes(keyword) &&
            !product?.product?.li_product_id?.toString()?.includes(keyword)
          ) {
            returnNull = true;
          }
        });
        if (returnNull) return null;
      }

      if (brands.length && !brands?.includes(product.product.brand.id))
        return null;
      if (
        categories?.length &&
        !categories?.includes(product?.categories[0]?.id)
      )
        return null;

      if (attributes.length) {
        let returnNull = true;
        attributes?.forEach?.((attrId) => {
          product.product?.variations?.forEach(({ attribute_value_id }) => {
            if (attribute_value_id == attrId) {
              returnNull = false;
            }
          });
        });
        if (returnNull) return null;
      }

      if (!!discount) {
        if (
          !(
            (product.product.online_price &&
              product.product.online_selling_price) ||
            product.promo_price ||
            product.recommended_retail_price
          )
        )
          return null;
      }

      if (colors.length) {
        let returnNull = true;
        colors?.forEach((attrId) => {
          product.product?.variations?.forEach((color) => {
            if (color.attribute_value_id == attrId) {
              returnNull = false;
            }
          });
        });
        if (returnNull) return null;
      }
      return product;
    }
  );
  filteredProductsForCategories?.forEach((product) => {
    let category = {
      id: product?.categories[0]?.id,
      name_en: product?.categories[0]?.name_en,
      name_hy: product?.categories[0]?.name_hy,
      name_ru: product?.categories[0]?.name_ru,
    };
    newCategories.add(JSON.stringify(category));
  });
  filteredProductsForBrands?.forEach((product) => {
    newBrands.add(JSON.stringify(product.product.brand));
  });
  for (let i = 0; i <= filteredProductsForDiscount?.length; i++) {
    if (
      (filteredProductsForDiscount[i]?.product?.online_price &&
        filteredProductsForDiscount[i]?.product?.online_selling_price) ||
      filteredProductsForDiscount[i]?.promo_price ||
      filteredProductsForDiscount[i]?.recommended_retail_price
    ) {
      newDiscount = true;
      break;
    }
  }
  filteredProductsForPrice?.forEach((product) => {
    const price =
      (product.product.online_price && product.product.online_selling_price) ||
      product.promo_price ||
      product.recommended_retail_price ||
      product.skus[0].selling_price;

    if (+price < newPrice?.min || !newPrice?.min) {
      newPrice.min = +price;
    }
    if (+price > newPrice?.max || !newPrice?.max) {
      newPrice.max = +price;
    }
  });

  newBrands = Array.from(newBrands || [])?.map(JSON.parse);

  let filteredBrands = brands?.filter((id) => {
    return newBrands?.find((item) => item.id == id);
  });
  if (filteredBrands.length !== brands.length)
    dispatch(setBrands(filteredBrands));

  newCategories = Array.from(newCategories || [])?.map(JSON.parse);

  let filteredCategories = categories?.filter((id) => {
    return newCategories?.find((item) => item.id == id);
  });
  if (filteredCategories.length !== categories.length)
    dispatch(setCategories(filteredCategories));

  if (
    !Object.keys(searchPageData || {})?.length ||
    !Object.keys(filterData || {}).length
  )
    return null;

  return (
    <div className={styles.container}>
      {!searchPageData?.keyword && (
        <div className={styles.searchContainer}>
          <input
            type="text"
            className={styles.search}
            placeholder={lang[currentLanguage].srch_plach}
            value={search}
            onChange={(e) => {
              dispatch(setSearch(e.target.value));
              dispatch(filterProducts());
            }}
          />
          <div className={styles.searchBtn}>
            <svg
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.91202 9.47266L7.13587 6.71875C7.76149 5.99609 8.11339 5.07812 8.11339 4.0625C8.11339 1.83594 6.27566 0 4.04692 0C1.79863 0 0 1.83594 0 4.0625C0 6.30859 1.81818 8.125 4.04692 8.125C5.04399 8.125 5.96285 7.77344 6.68622 7.14844L9.44281 9.92188C9.52102 9.98047 9.59922 10 9.69697 10C9.77517 10 9.85337 9.98047 9.91202 9.92188C10.0293 9.80469 10.0293 9.58984 9.91202 9.47266ZM4.06647 7.5C2.15054 7.5 0.625611 5.95703 0.625611 4.0625C0.625611 2.16797 2.15054 0.625 4.06647 0.625C5.96285 0.625 7.50733 2.16797 7.50733 4.0625C7.50733 5.97656 5.96285 7.5 4.06647 7.5Z"
                fill="#282828"
                fillOpacity="0.4"
              ></path>
            </svg>
          </div>
        </div>
      )}
      {filterData.allBrands?.length > 1 && (
        <FilterItem
          title={lang[currentLanguage].brands}
          keyName={"name"}
          data={newBrands}
          currentLanguage={currentLanguage}
          addAttribute={(val) => dispatch(addBrand(val))}
          filterName="brand"
          removeAttribute={(val) => {
            dispatch(setMinPrice(filterData?.defaultPrices?.min));
            dispatch(setMaxPrice(filterData?.defaultPrices?.max));
            dispatch(removeBrand(val));
          }}
          activeAttributes={brands}
        />
      )}
      {filterData.allCategories?.length > 1 && (
        <FilterItem
          title={lang[currentLanguage].Category}
          keyName={"name_"}
          withLanguage={true}
          data={newCategories}
          filterName="category"
          currentLanguage={currentLanguage}
          addAttribute={(val) => dispatch(addCategory(val))}
          removeAttribute={(val) => {
            dispatch(setMinPrice(filterData?.defaultPrices?.min));
            dispatch(setMaxPrice(filterData?.defaultPrices?.max));
            dispatch(removeCategory(val));
          }}
          activeAttributes={categories}
        />
      )}

      {newDiscount && (
        <div className={styles.discountedContainer}>
          <span>{lang[currentLanguage].discounted_products}</span>
          <div
            onClick={() => {
              if (discount) {
                dispatch(setMinPrice(filterData?.defaultPrices?.min));
                dispatch(setMaxPrice(filterData?.defaultPrices?.max));
              }
              dispatch(setDiscount(!discount));
              dispatch(filterProducts("discount"));
            }}
            className={`${styles.discountedBtnBlock} ${
              discount && styles.discountedBtnBlockActive
            }`}
          >
            <div className={styles.discountedBtn}></div>
          </div>
        </div>
      )}

      <FilterItemPrice
        title={lang[currentLanguage].price}
        min={newPrice?.min}
        max={newPrice?.max}
        defaultPrices={newPrice}
      />
      {[...(filterData?.attributes || [])]
        ?.sort(function (a, b) {
          return a?.name_en?.localeCompare(b?.name_en);
        })
        .map((attribute, index) => {
          let currentAtributValues = attributeVales?.[attribute.id]?.map(
            (item) => item.id
          );

          let attributeValuesWithoutCurrent = attributes?.filter(
            (id) => !currentAtributValues?.includes(id)
          );

          let filteredProducts = searchPageData.products?.filter((product) => {
            if (search) {
              let returnNull = false;
              let keywordsArray = search.toLowerCase().split(" ");

              keywordsArray?.forEach((keyword) => {
                if (
                  !product?.product?.brand?.name
                    ?.toLowerCase()
                    ?.includes(keyword) &&
                  !product?.product?.product_name
                    ?.toLowerCase()
                    ?.includes(keyword) &&
                  !product?.product?.li_product_id
                    ?.toString()
                    ?.includes(keyword)
                ) {
                  returnNull = true;
                }
              });
              if (returnNull) return null;
            }

            if (brands.length && !brands?.includes(product.product.brand.id))
              return null;
            if (
              categories?.length &&
              !categories?.includes(product?.categories[0]?.id)
            )
              return null;

            if (attributeValuesWithoutCurrent.length) {
              let returnNull = true;
              attributeValuesWithoutCurrent?.forEach?.((attrId) => {
                product.product?.variations?.forEach(
                  ({ attribute_value_id }) => {
                    if (attribute_value_id == attrId) {
                      returnNull = false;
                    }
                  }
                );
              });
              if (returnNull) return null;
            }

            if (!!discount) {
              if (
                !(
                  (product.product.online_price &&
                    product.product.online_selling_price) ||
                  product.promo_price ||
                  product.recommended_retail_price
                )
              )
                return null;
            }
            const price =
              (product.product.online_price &&
                product.product.online_selling_price) ||
              product.promo_price ||
              product.recommended_retail_price ||
              product.skus[0].selling_price;

            let min, max;

            if (price < min || !min) {
              min = price;
            }
            if (price > max || !max) {
              max = price;
            }
            if (price < min || price > max) {
              return null;
            }
            return product;
          });
          let newAttributes = new Set();

          filteredProducts?.forEach((product) => {
            product?.product?.variations?.forEach((item) => {
              if (
                Object.keys(item.attribute_value || {}).length &&
                item.attribute_id === attribute.id
              )
                newAttributes.add(
                  JSON.stringify({
                    attribute_id: item.attribute_value?.attribute_id,
                    id: item.attribute_value?.id,
                    value_en: item.attribute_value?.value_en,
                    value_ru: item.attribute_value?.value_ru,
                    value_hy: item.attribute_value?.value_hy,
                  })
                );
            });
          });
          newAttributes = Array.from(newAttributes).map(JSON.parse);

          // let filteredAttributes = attributes.filter((id) => {
          //   if (currentAtributValues?.includes(id)) {
          //     return true;
          //   } else if (newAttributes.find((item) => item.id == id)) {
          //     return true;
          //   }
          //   return false;
          // });
          // if (attributes.length !== filteredAttributes.length) {
          //   // dispatch(setAttributes(filteredAttributes));
          // }

          if (("attribute", attribute.id == 3)) {
            return (
              <FilterItemColor
                key={index}
                title={lang[currentLanguage].color}
                data={newAttributes}
                currentLanguage={currentLanguage}
                addAttribute={(val, attribute_id) => {
                  dispatch(addColor(val));
                }}
                removeAttribute={(val) => {
                  dispatch(setMinPrice(filterData?.defaultPrices?.min));
                  dispatch(setMaxPrice(filterData?.defaultPrices?.max));
                  dispatch(removeColor(val));
                }}
                activeAttributes={colors}
              />
            );
          }

          return (
            <FilterItem
              key={index}
              title={attribute?.["name_" + currentLanguage]}
              keyName={"value_"}
              withLanguage={true}
              data={newAttributes}
              currentLanguage={currentLanguage}
              addAttribute={(val) => {
                dispatch(addAttribute(val));
              }}
              removeAttribute={(val) => {
                dispatch(setMinPrice(filterData?.defaultPrices?.min));
                dispatch(setMaxPrice(filterData?.defaultPrices?.max));
                dispatch(removeAttribute(val));
              }}
              activeAttributes={attributes}
            />
          );
        })}
      <div
        className={styles.setDefaultFilterBtn}
        onClick={() => {
          dispatch(setDefaultFilteres());
          dispatch(filterProducts());
        }}
      >
        <span>Delete</span>
      </div>
    </div>
  );
}
